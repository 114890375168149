import React, { useEffect, useRef, useState } from 'react'
import StickyBanner from '../../StickyBanner/StickyBanner'
import Footer from '../../Footer/Footer'
import './SupplyChainManagement.scss'
import StepsSlider from '../Elements/StepProcess/StepProcess';
import QuestionSlider from '../Elements/QuestionSlider';
import HeroSection from '../Elements/Sections/HeroSection/HeroSection';
import SectionTabs from '../Elements/Sections/SectionTabs/SectionTabs';
import OverviewSection from '../Elements/Sections/OverviewSection';
import Challenges from '../Elements/Sections/Challenges';
import Dynamics from '../Elements/Sections/Dynamics';
import EfficiencyBoost from '../Elements/Sections/EfficiencyBoost';
import BenefitsSection from '../Elements/Sections/Benefits';
import BlueFluidBanner from '../Elements/Sections/BlueFluidBanner';
import HowHelpSection from '../Elements/Sections/HowHelpSection';
import SuccessStory from '../Elements/Sections/SuccessStory';
import TransformationSection from '../Elements/Sections/TransformationSection';
import ContactSection from '../Elements/Sections/ContactSection';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import MIRRoutes from '../../../lib/routes';
import CaseStudyUrls from '../../../lib/singleCaseStudyUrls';
import SuccessStoriesDynamic from '../../CommonElements/SuccessStoriesDynamic';

export default function SupplyChainManagement() {
    const [tabs, setTabs] = useState([
        { name: 'Overview', id: 'section-1' },
        { name: 'Introducing', id: 'section-2' },
        // {name: 'Service Bundles', id: 'section-3'},
        { name: 'Benefits', id: 'section-4' },
        { name: 'How can we help you', id: 'section-5' },
        { name: 'Success Story', id: 'section-6' },
        { name: 'Contact Us', id: 'section-7' },
    ]);
    const questions = [
        'How much visibility do you have into logistics, inventory, and factory operations in real time?',
        'How has the pandemic affected your supply chain, and how are you ensuring business continuity post-pandemic?',
        'Are data integrity issues arising from using disparate systems for decision-making?'
    ];

    const successStoriesContent = [
        {
            image: "/success-stories-custom/9.png",
            timeToRead: "5",
            desc: "MIR finds nearly a 50% saving in Microsoft D365 Licensing Costs for a Top-tier Organization",
            linkToRead: "/about-us/case-studies/saving-in-microsoft-d365-licensing-costs"
        },
        {
            image: "/success-stories-custom/1.png",
            timeToRead: "5",
            desc: "Construction and mining giant drives operational efficiency with Dynamics 365 Inventory and Warehouse Management",
            linkToRead: "/about-us/case-studies/construction-mining-inventory-warehouse-management"
        },
        {
            image: "/success-stories-custom/5.png",
            timeToRead: "10",
            desc: "Construction and mining giant achieves procurement automation and vendor management excellence with Microsoft Dynamics 365 F&O",
            linkToRead: "/about-us/case-studies/dynamics-365-procurement-automation"
        }
    ];
    const childRef = useRef();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Microsoft Dynamics 365 Supply Chain Management Solution | MIR Digital Solutions</title>
                <meta name="description" content="Streamline your supply chain process and improve visbility with Microsoft Dynamics 365 Supply Chain Management. Contact MIR expert team to get started today!" />
                <link rel="canonical" href={`${window.location.origin}/cloud-solutions/dynamics-365-supply-chain-management-solution`} />
            </Helmet>
            <main style={{ position: 'relative' }} className='supply-chain-main-wrapper'>

                <StickyBanner />
                {/* hero */}
                <HeroSection
                    icon={'/supply-chain/hero-icon.png'}
                    overline={'Streamline your supply chain processes and drive growth'}
                    heading={<span>Microsoft Dynamics 365 Supply Chain Management</span>}
                />
                {/* section tabs */}
                <SectionTabs tabs={tabs} />

                {/* overview */}
                <div data-section id='section-1' className='!tw-p-0'>
                    <OverviewSection
                        overline={'OVERVIEW'}
                        heading={'Navigating global economic shifts: Supply chain resilience'}
                        bg={'url(/supply-chain/overview-bg.png)'}
                        desc={<><a target='_blank' className='mir-p-md-semibold' href="https://www.worldbank.org/en/news/press-release/2023/03/27/global-economy-s-speed-limit-set-to-fall-to-three-decade-low#:~:text=As%20a%20result%2C%20between%202022,%E2%80%94to%202.2%25%20a%20year.">The economic forces that have driven progress for the past thirty years are now fading, leading to a projected one-third drop in global potential GDP growth from 2022 to 2030.</a>
                            This shift will significantly impact exports and investments in economies like China. To navigate this change successfully, organizations must build resilience in their supply chains. A key strategy, as seen in the top 25 Supply Chain Excellence companies, involves creating a center of excellence teams for supply planning, procurement, and logistics, with 90% of them investing in planning and visibility solutions.</>}
                    />
                </div>

                <QuestionSlider questions={questions} />

                {/* challenges */}
                {/* <Challenges 
                    overline={'CHALLENGES'}
                    heading={<>Over the past decade, <br /> organizations gained efficiency <br /> but lost resilience</>} 
                    challenges={[
                        'Insufficient visibility, stemming from a lack of real-time data, is a key vulnerability in the supply chain.',
                        'Slow digitization of supply chains hampers agility, hindering response to customer demand and supply changes.',
                        'Struggles with disrupted suppliers affect production continuity. Alternate sourcing is often slow.'
                    ]}
                    sideImage={'/supply-chain/challenges-bg.png'}
                /> */}

                <section className='tw-py-[96px]'>
                    <div className="container mx-auto text-center">
                        <h4 className='tw-uppercase mir-p-overline-lg mx-auto mir-text-primary-500 tw-mb-[16px]'>challenges</h4>
                        <h2 className='tw-max-w-full md:tw-max-w-[592px] mx-auto mir-h3-semibold mir-text-neutral-800 tw-mb-[52px]'>Over the past decade, organizations gained efficiency but lost resilience</h2>
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[32px] text-start">
                            {
                                [
                                    'Insufficient visibility, stemming from a lack of real-time data, is a key vulnerability in the supply chain.',
                                    'Slow digitization of supply chains hampers agility, hindering response to customer demand and supply changes.',
                                    'Struggles with disrupted suppliers affect production continuity. Alternate sourcing is often slow.',
                                ].map((item, index) => {
                                    return <div key={`challenge-item-${index}`} className='tw-w-full tw-h-full tw-rounded-[8px] mir-bg-neutral-100 tw-p-[32px]' style={{ border: '1px solid var(--neutral-200, #EEEEF2)' }}>
                                        <svg className='tw-mb-[24px]' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="32" height="32" rx="16" fill="#D1E5FF" />
                                            <path d="M10.6006 12.2196C10.6006 11.8376 10.7349 11.5102 11.0035 11.2373C11.2722 10.9645 11.5996 10.8301 11.9858 10.8343C12.3636 10.8301 12.6869 10.9645 12.9555 11.2373C13.2242 11.5102 13.3585 11.8376 13.3585 12.2196C13.3585 12.589 13.2242 12.9101 12.9555 13.183C12.6869 13.4601 12.3636 13.6007 11.9858 13.6049C11.7298 13.6007 11.4968 13.5335 11.2869 13.4034C11.077 13.2775 10.9091 13.1095 10.7832 12.8997C10.6614 12.694 10.6006 12.4673 10.6006 12.2196ZM17.5018 12.2196C17.5018 11.8376 17.6361 11.5102 17.9048 11.2373C18.1776 10.9645 18.5051 10.8301 18.8871 10.8343C19.2649 10.8301 19.5881 10.9645 19.8567 11.2373C20.1296 11.5102 20.266 11.8376 20.266 12.2196C20.266 12.589 20.1296 12.9101 19.8567 13.183C19.5881 13.4601 19.2649 13.6007 18.8871 13.6049C18.6352 13.6007 18.4043 13.5335 18.1944 13.4034C17.9845 13.2775 17.8166 13.1095 17.6907 12.8997C17.5647 12.694 17.5018 12.4673 17.5018 12.2196Z" fill="#1D68F0" />
                                            <path d="M15.9969 16.525C17.571 16.525 19.0172 16.7328 20.3353 17.1484C21.6576 17.5682 22.8792 18.1915 24 19.0185L24 21.1657C23.5844 20.8467 23.0744 20.5486 22.4699 20.2716C21.8696 19.9945 21.2105 19.7531 20.4927 19.5474C19.7791 19.3459 19.0382 19.1864 18.27 19.0689C17.5018 18.9555 16.7441 18.8989 15.9969 18.8989C15.002 18.8989 13.9945 18.9975 12.9744 19.1948C11.9543 19.3963 11.0098 19.6671 10.1409 20.0071C9.26774 20.3513 8.55411 20.7375 8 21.1657L8 19.0185C9.12082 18.1915 10.3403 17.5682 11.6584 17.1484C12.9807 16.7328 14.4269 16.525 15.9969 16.525Z" fill="#1D68F0" />
                                        </svg>
                                        <p className='mir-text-neutral-700 mir-p-md-regular'>{item}</p>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </section>

                {/* dynamics */}
                <div data-section id={'section-2'}>
                    <Dynamics
                        icon={'/cloud-solutions/supply/dynamics-img.svg'}
                        overline={'Introduction'}
                        heading={<>What is Microsoft Dynamics 365 <br /> Supply Chain Management? </>}
                        desc={'Microsoft Dynamics 365 for Supply Chain Management empowers you with improved visibility, agile planning, and a skilled workforce for seamless business operations. Applications integrate effortlessly, consolidating data in the azure data lake. AI-driven insights anticipate opportunities and risks throughout your journey, from planning to delivery.  '}
                        videoPlaceholder={"/supply-chain/video-placeholder.png"}
                        iframe={<iframe width="100%" height="541px" src="https://www.youtube.com/embed/R3Ol_-prHeI?si=rFpiA2NRwbKJMGjO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>}
                    />
                </div>

                {/* efficiency boost */}
                <div data-section id='section-3'></div>
                <EfficiencyBoost
                    heading={<>Efficiency boost with<br />Dynamics 365 Supply Chain Management</>}
                    desc={'Optimize your supply chain with Microsoft Dynamics 365 for Supply Chain Management. Gain insights, detect opportunities, and streamline operations while addressing product quality, customer demand, inventory, logistics, and asset lifespan. Achieve supply chain excellence with one powerful solution.'}
                    image={'/supply-chain/efficiency-boost-bg.png'}
                />

                <div data-section id='section-4' style={{ background: '#FAFAFC' }}>
                    <BenefitsSection
                        overline={'BENEFITS'}
                        heading={<>Why should you choose <span style={{ color: '#1D68F0' }}>Microsoft Dynamics 365</span> <br /> to manage your supply chain?</>}
                        desc={'Building supply chain resilience requires comprehensive operational insight spanning from planning to delivery. It necessitates unified real-time data integration from disparate sources like Excel files and ERPs. Microsoft Dynamics 365 for Supply Chain Management offers you'}
                        items={[
                            {
                                icon: <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_5205_44902)">
                                        <path d="M10.875 36H4.125C3.504 36 3 35.496 3 34.875V28.125C3 27.504 3.504 27 4.125 27H10.875C11.496 27 12 27.504 12 28.125V34.875C12 35.496 11.496 36 10.875 36Z" fill="#1D68F0" />
                                        <path d="M22.875 36.0002H16.125C15.504 36.0002 15 35.4962 15 34.8752V20.626C15 20.005 15.504 19.501 16.125 19.501H22.875C23.496 19.501 24 20.005 24 20.626V34.8752C24 35.4962 23.496 36.0002 22.875 36.0002Z" fill="#8EB8F7" />
                                        <path d="M34.875 35.9998H28.125C27.504 35.9998 27 35.4958 27 34.8748V13.124C27 12.503 27.504 11.999 28.125 11.999H34.875C35.496 11.999 36 12.503 36 13.124V34.8748C36 35.4958 35.496 35.9998 34.875 35.9998Z" fill="#1D68F0" />
                                        <path d="M22.2809 0.22088C22.1369 0.0768803 21.9232 -0.00636875 21.7364 0.000381248C17.4952 0.0791312 12.5497 2.55413 9.40645 6.14963C6.00895 6.27113 3.17395 7.29488 1.4032 9.06338C1.21195 9.25463 1.1377 9.53588 1.21195 9.79688C1.2862 10.0579 1.49545 10.2581 1.7587 10.3234L4.9537 11.0884L4.93345 11.1109C4.66795 11.4079 4.6792 11.8601 4.96045 12.1436L10.3559 17.5391C10.5022 17.6854 10.6934 17.7596 10.8869 17.7596C11.0669 17.7596 11.2469 17.6966 11.3887 17.5661L11.4112 17.5459L12.1762 20.7409C12.2392 21.0064 12.4417 21.2156 12.7027 21.2876C12.7702 21.3056 12.8377 21.3169 12.9052 21.3169C13.1009 21.3169 13.2922 21.2404 13.4362 21.0964C15.2047 19.3279 16.2284 16.4906 16.3499 13.0931C19.9499 9.94088 22.4204 5.00213 22.4992 0.765382C22.5059 0.560632 22.4249 0.36488 22.2809 0.22088Z" fill="#1D68F0" />
                                        <path d="M2.57385 15.5206C1.56585 16.5286 0.278853 20.7069 0.0313525 21.5349C-0.0473975 21.8004 0.0246017 22.0861 0.220352 22.2796C0.362102 22.4236 0.553353 22.5001 0.749103 22.5001C0.821103 22.5001 0.893103 22.4889 0.965103 22.4686C1.7931 22.2211 5.9736 20.9341 6.97935 19.9261C8.19435 18.7111 8.19435 16.7356 6.97935 15.5206C5.7621 14.3056 3.7866 14.3056 2.57385 15.5206Z" fill="#8EB8F7" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5205_44902">
                                            <rect width="36" height="36" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>,
                                heading: 'Accelerate Go-to-market', desc: 'Accelerate go-to-market period with centralized product information management '
                            },
                            {
                                icon: <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_5647_30286)">
                                        <path d="M15.001 18.7503C14.38 18.7503 13.876 18.2463 13.876 17.6253V12.7495C13.876 12.1285 14.38 11.6245 15.001 11.6245C15.622 11.6245 16.126 12.1285 16.126 12.7495V17.6253C16.126 18.2463 15.622 18.7503 15.001 18.7503Z" fill="#1D68F0" />
                                        <path d="M23.6248 21.0005C20.909 21.0005 18.6455 19.0655 18.119 16.5005H7.12327C4.84402 16.5005 2.99902 18.3455 2.99902 20.6247V31.8747C2.99902 34.154 4.84402 35.999 7.12327 35.999H22.8733C25.1548 36.0012 26.9998 34.1562 26.9998 31.877V21.0027L23.6248 21.0005ZM7.49902 24.002C7.49902 22.3527 8.84902 21.0027 10.4983 21.0027C12.1475 21.0027 13.4975 22.3527 13.4975 24.002C13.4998 25.6512 12.1498 27.0012 10.5005 27.0012C8.84902 27.0012 7.49902 25.6512 7.49902 24.002ZM18.3755 31.5012H11.6255C11.0113 31.5012 10.5005 30.9905 10.5005 30.3762C10.5005 29.762 11.0113 29.2512 11.6255 29.2512H18.3755C18.9898 29.2512 19.5005 29.762 19.5005 30.3762C19.5005 30.9905 18.9898 31.5012 18.3755 31.5012ZM19.5005 27.0012C17.8513 27.0012 16.5013 25.6512 16.5013 24.002C16.5013 22.3527 17.8513 21.0027 19.5005 21.0027C21.1498 21.0027 22.4998 22.3505 22.4998 24.002C22.4998 25.6512 21.1498 27.0012 19.5005 27.0012Z" fill="#1D68F0" />
                                        <path d="M15.0012 13.5009C13.3475 13.5009 12.002 12.1554 12.002 10.5017C12.002 8.84794 13.3475 7.50244 15.0012 7.50244C16.655 7.50244 18.0005 8.84794 18.0005 10.5017C18.0005 12.1554 16.655 13.5009 15.0012 13.5009Z" fill="#1D68F0" />
                                        <path d="M28.8743 27.3765H25.875C25.254 27.3765 24.75 26.8725 24.75 26.2515C24.75 25.6305 25.254 25.1265 25.875 25.1265H28.8743C29.4953 25.1265 29.9993 25.6305 29.9993 26.2515C29.9993 26.8725 29.4953 27.3765 28.8743 27.3765Z" fill="#1D68F0" />
                                        <path d="M28.874 29.9997C28.253 29.9997 27.749 29.4957 27.749 28.8747V23.6255C27.749 23.0045 28.253 22.5005 28.874 22.5005C29.495 22.5005 29.999 23.0045 29.999 23.6255V28.8747C29.999 29.4957 29.495 29.9997 28.874 29.9997Z" fill="#1D68F0" />
                                        <path d="M4.12425 27.3765H1.125C0.504 27.3765 0 26.8725 0 26.2515C0 25.6305 0.504 25.1265 1.125 25.1265H4.12425C4.74525 25.1265 5.24925 25.6305 5.24925 26.2515C5.24925 26.8725 4.74525 27.3765 4.12425 27.3765Z" fill="#1D68F0" />
                                        <path d="M1.125 29.9997C0.504 29.9997 0 29.4957 0 28.8747V23.6255C0 23.0045 0.504 22.5005 1.125 22.5005C1.746 22.5005 2.25 23.0045 2.25 23.6255V28.8747C2.25 29.4957 1.746 29.9997 1.125 29.9997Z" fill="#1D68F0" />
                                        <path d="M33.3744 1.50056H32.2494V1.12486C32.2494 0.503937 31.7455 0 31.1245 0C30.5035 0 29.9995 0.503937 29.9995 1.12486V1.50056H27.0004V1.12486C27.0004 0.503937 26.4965 0 25.8755 0C25.2545 0 24.7506 0.503937 24.7506 1.12486V1.50056H23.6256C22.1789 1.50056 21 2.67716 21 4.12598V15.3746C21 16.8211 22.1767 18 23.6256 18H33.3744C34.8233 17.9977 36 16.8211 36 15.3723V4.12373C36 2.67717 34.8233 1.50056 33.3744 1.50056ZM30.751 13.4983H26.2512C25.6303 13.4983 25.1263 12.9944 25.1263 12.3735C25.1263 11.7525 25.6303 11.2486 26.2512 11.2486H30.751C31.372 11.2486 31.876 11.7525 31.876 12.3735C31.876 12.9944 31.372 13.4983 30.751 13.4983ZM30.751 9.74803H26.2512C25.6303 9.74803 25.1263 9.2441 25.1263 8.62317C25.1263 8.00225 25.6303 7.49831 26.2512 7.49831H30.751C31.372 7.49831 31.876 8.00225 31.876 8.62317C31.876 9.2441 31.372 9.74803 30.751 9.74803Z" fill="#8EB8F7" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5647_30286">
                                            <rect width="36" height="36" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>,
                                heading: 'Predict demand and make inventory decisions', desc: 'Anticipate potential problems and demand with Artificial Intelligence (AI)'
                            },
                            {
                                icon: <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_5647_30297)">
                                        <path d="M7.72749 25.75H3.19859L3.01861 26.6657C2.95786 26.9965 3.04785 27.3407 3.25934 27.595C3.46857 27.8492 3.7993 28 4.13002 28H7.56551C7.52051 27.7593 7.50476 27.505 7.50476 27.2507C7.50251 26.7265 7.579 26.2315 7.72749 25.75ZM23.7463 10.4208C23.537 10.1508 23.2221 10 22.8756 10H7.12679C6.58683 10 6.12112 10.3892 6.01763 10.9157L5.91413 11.5007H9.37887C10.8188 11.5007 12.0044 12.6865 12.0044 14.1265C12.0044 15.0872 11.4802 15.9107 10.7153 16.3765C11.4802 16.8422 12.0044 17.6657 12.0044 18.6265C12.0044 19.5872 11.4802 20.4107 10.7153 20.8765C11.181 21.1622 11.5544 21.5808 11.7794 22.0915C12.0944 22.0308 12.4251 22.0015 12.7536 22.0015C15.6469 22.0015 18.0025 24.355 18.0025 27.2507C18.0025 27.505 17.9867 27.7615 17.9417 28H19.5008C20.025 28 20.4908 27.6243 20.5965 27.1158L23.9712 11.3657C24.0477 11.035 23.9712 10.6908 23.7463 10.4208Z" fill="#1D68F0" />
                                        <path d="M28.8097 14H25.5699C25.025 14 24.5709 14.3897 24.4506 14.917L22.0281 26.5837C21.9531 26.9267 22.0281 27.2837 22.2551 27.5637C22.4662 27.8437 22.7841 28 23.1337 28H23.9511C23.9057 27.7503 23.8898 27.4867 23.8898 27.223C23.8898 24.22 26.2669 21.7793 29.1866 21.7793C32.1063 21.7793 34.4834 24.2223 34.4834 27.223C34.4834 27.3933 34.4834 27.5497 34.4539 27.72C34.6514 27.5637 34.7876 27.3467 34.833 27.083L35.9682 21.6393C35.9841 21.56 36 21.4667 36 21.3897C36 17.3133 32.7761 14 28.8097 14Z" fill="#1D68F0" />
                                        <path d="M9.48542 14.9424H4.17237C3.54409 14.9424 3.03418 14.4325 3.03418 13.8042C3.03418 13.1759 3.54409 12.666 4.17237 12.666H9.48314C10.1114 12.666 10.6213 13.1759 10.6213 13.8042C10.6213 14.4325 10.1137 14.9424 9.48542 14.9424Z" fill="#8EB8F7" />
                                        <path d="M9.48586 19.4951H2.65674C2.02846 19.4951 1.51855 18.9852 1.51855 18.3569C1.51855 17.7287 2.02846 17.2188 2.65674 17.2188H9.48586C10.1141 17.2188 10.624 17.7287 10.624 18.3569C10.624 18.9852 10.1141 19.4951 9.48586 19.4951Z" fill="#8EB8F7" />
                                        <path d="M9.48564 24.0479H1.13819C0.509907 24.0479 0 23.5379 0 22.9097C0 22.2814 0.509907 21.7715 1.13819 21.7715H9.48564C10.1139 21.7715 10.6238 22.2814 10.6238 22.9097C10.6238 23.5379 10.1139 24.0479 9.48564 24.0479Z" fill="#8EB8F7" />
                                        <path d="M29.5926 30.8765C27.5006 30.8765 25.7979 29.1761 25.7979 27.0818C25.7979 24.9876 27.5006 23.2871 29.5926 23.2871C31.6845 23.2871 33.3873 24.9876 33.3873 27.0818C33.3873 29.1761 31.6845 30.8765 29.5926 30.8765Z" fill="#8EB8F7" />
                                        <path d="M12.9002 30.8765C10.8082 30.8765 9.10547 29.1738 9.10547 27.0818C9.10547 24.9898 10.8082 23.2871 12.9002 23.2871C14.9922 23.2871 16.6949 24.9876 16.6949 27.0818C16.6949 29.1761 14.9922 30.8765 12.9002 30.8765Z" fill="#8EB8F7" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5647_30297">
                                            <rect width="36" height="36" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>,
                                heading: 'Expedite delivery times ', desc: 'Enhance inventory accuracy and expedite delivery times by optimizing inventory and warehousing operations '
                            },
                            {
                                icon: <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_5205_44944)">
                                        <path d="M34.4993 33.0007H2.99925V1.50075C2.99925 0.6705 2.32875 0 1.50075 0C0.67275 0 0 0.6705 0 1.50075V33.0007C0 34.6545 1.3455 36 2.99925 36H34.4993C35.3295 36 36 35.3295 36 34.4993C36 33.669 35.3295 33.0007 34.4993 33.0007Z" fill="#1D68F0" />
                                        <path d="M8.62184 28.5003H12.3726C13.8193 28.5003 14.9983 27.3235 14.9983 25.8745V17.6238C14.9983 16.177 13.8216 14.998 12.3726 14.998H8.62184C7.17509 14.998 5.99609 16.1748 5.99609 17.6238V25.8745C5.99834 27.3213 7.17509 28.5003 8.62184 28.5003Z" fill="#8EB8F7" />
                                        <path d="M19.1218 28.5003H22.8726C24.3193 28.5003 25.4983 27.3235 25.4983 25.8745V13.1238C25.4983 11.677 24.3216 10.498 22.8726 10.498H19.1218C17.6751 10.498 16.4961 11.6748 16.4961 13.1238V25.8745C16.4961 27.3213 17.6751 28.5003 19.1218 28.5003Z" fill="#8EB8F7" />
                                        <path d="M33.3743 4.5H29.6235C28.1768 4.5 27 5.67675 27 7.12575V25.875C27 27.3218 28.1767 28.5007 29.6257 28.5007H33.3765C34.8232 28.5007 36.0022 27.324 36.0022 25.875V7.12575C36 5.67675 34.8233 4.5 33.3743 4.5Z" fill="#8EB8F7" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5205_44944">
                                            <rect width="36" height="36" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>,
                                heading: 'Reduce downtime and enhance inventory accuracy', desc: 'Reduce downtime and with automated cycle counts that can help businesses perform proactive equipment maintenance'
                            },
                            {
                                icon: <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_5205_44955)">
                                        <path d="M36.1224 0.518359C29.2883 0.402526 21.5078 3.97587 16.5816 9.51837C11.874 9.60579 7.28435 11.5312 3.90552 14.9144C3.71538 15.1046 3.64326 15.3953 3.73068 15.6575C3.8181 15.9198 4.03665 16.1099 4.31421 16.1536L9.93977 16.9557L9.2404 17.7425C8.97814 18.0201 8.99343 18.4725 9.26881 18.7348L15.738 25.2105C16.1751 19.1566 21.2171 14.3724 27.365 14.3724C29.1571 14.3724 30.8618 14.7811 32.3786 15.5111C35.2482 11.1357 36.9398 5.95596 36.8371 1.23084C36.8218 0.839631 36.5158 0.533658 36.1224 0.518359ZM29.4041 13.1223C28.6894 13.8217 27.7584 14.1867 26.8252 14.1867C25.892 14.1867 24.9609 13.8217 24.2463 13.1223C22.8322 11.693 22.8322 9.37413 24.2463 7.9579C25.6734 6.52857 27.9901 6.52857 29.4041 7.9579C30.8181 9.37194 30.8181 11.693 29.4041 13.1223Z" fill="#1D68F0" />
                                        <path d="M4.42044 25.1449C2.85997 26.7032 0.720337 33.7493 0.482114 34.5471C0.405621 34.8049 0.475557 35.0825 0.665698 35.2705C0.803387 35.4103 0.989157 35.4847 1.1793 35.4847C1.24924 35.4847 1.31917 35.4737 1.38911 35.4541C2.18683 35.2158 9.23079 33.074 10.7913 31.5157C12.5484 29.7586 12.5484 26.9021 10.7913 25.1471C9.03191 23.3877 6.17542 23.3899 4.42044 25.1449Z" fill="#8EB8F7" />
                                        <path d="M27.4059 16.5996C22.1847 16.5996 17.936 20.8352 17.936 26.0433C17.936 31.2514 22.1847 35.4848 27.4059 35.4848C32.6272 35.4848 36.8758 31.2492 36.8758 26.0411C36.8758 20.833 32.6272 16.5996 27.4059 16.5996ZM31.4863 24.2402L26.7503 29.3259C26.5361 29.5554 26.2432 29.6734 25.9482 29.6734C25.6969 29.6734 25.4433 29.586 25.2357 29.4112L22.6852 27.2322C22.2262 26.841 22.1738 26.1526 22.5672 25.6958C22.9606 25.239 23.649 25.1866 24.108 25.5778L25.8586 27.0749L29.88 22.7584C30.293 22.317 30.9837 22.2929 31.4251 22.7016C31.8688 23.1103 31.895 23.8009 31.4863 24.2402Z" fill="#8EB8F7" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5205_44955">
                                            <rect width="36" height="36" fill="white" transform="translate(0.666504)" />
                                        </clipPath>
                                    </defs>
                                </svg>,
                                heading: 'Streamline procurement and improve cost management ', desc: 'Make informed inventory decisions with priority-based supply planning, overstocking elimination'
                            },
                            {
                                icon: <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_5205_44965)">
                                        <path d="M33.707 6.00098H30.3321V9.00016H33.3313V25.4991H3.33268V9.00016H6.33187V6.00098H2.95694C1.51922 6.00098 0.333496 7.18445 0.333496 8.62442V28.874C0.333496 30.314 1.51922 31.4997 2.95919 31.4997H33.7093C35.147 31.4997 36.3327 30.314 36.3327 28.874V8.62442C36.3327 7.18445 35.147 6.00098 33.707 6.00098Z" fill="#1D68F0" />
                                        <path d="M24.111 34.7201C22.14 32.7491 22.0815 30.8412 22.0815 30.7467C22.0793 30.3349 21.744 30.002 21.3323 30.002H15.3024C14.8884 30.002 14.5532 30.3417 14.5532 30.7557C14.5532 30.8367 14.5262 32.7469 12.553 34.7201C12.3392 34.9338 12.274 35.2578 12.391 35.5368C12.5035 35.8181 12.778 36.0003 13.0817 36.0003H23.5823C23.886 36.0003 24.1605 35.8181 24.2752 35.5368C24.39 35.2578 24.3247 34.9338 24.111 34.7201Z" fill="#1D68F0" />
                                        <path d="M15.2476 1.44743L23.4104 5.63009L26.6571 4.03262L18.6382 0.0682095C18.4582 -0.0240386 18.2422 -0.0240386 18.0533 0.0682095L15.2476 1.44743Z" fill="#8EB8F7" />
                                        <path d="M10.0288 4.03302L18.3356 8.15043L21.4473 6.61596L21.8951 6.38647L13.7232 2.20605L13.2732 2.4333L10.0288 4.03302Z" fill="#8EB8F7" />
                                        <path d="M19.0015 9.33616L19.0105 18.0007L26.9663 13.8473C27.1913 13.7281 27.3353 13.4918 27.3353 13.2353V5.21875L23.9626 6.88146V9.80415C23.9626 10.1776 23.6566 10.4881 23.2876 10.4881C22.9186 10.4881 22.6126 10.1776 22.6126 9.80415V7.5587L22.1626 7.77695L19.0015 9.33616Z" fill="#8EB8F7" />
                                        <path d="M12.7039 6.88146V6.88596L17.6493 9.32941L17.6673 9.33841L17.6583 18.0007L9.70249 13.8473C9.47749 13.7281 9.3335 13.4896 9.3335 13.2353V5.21875L12.7039 6.88146Z" fill="#8EB8F7" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5205_44965">
                                            <rect width="36" height="36" fill="white" transform="translate(0.333496)" />
                                        </clipPath>
                                    </defs>
                                </svg>,
                                heading: 'Build agile manufacturing processes', desc: 'Streamline procurement processes and improve cost management by using vendor collaboration portals and linking to vendor catalogs '
                            }
                        ]}
                    />
                </div>

                <BlueFluidBanner
                    title={<>Need a way to streamline your supply chain process and increase system agility?</>}
                    button={<><span>Get Started Now</span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.3172 10.442L11.6922 16.067C11.5749 16.1843 11.4159 16.2502 11.25 16.2502C11.0841 16.2502 10.9251 16.1843 10.8078 16.067C10.6905 15.9498 10.6247 15.7907 10.6247 15.6249C10.6247 15.459 10.6905 15.2999 10.8078 15.1827L15.3664 10.6249H3.125C2.95924 10.6249 2.80027 10.559 2.68306 10.4418C2.56585 10.3246 2.5 10.1656 2.5 9.99986C2.5 9.8341 2.56585 9.67513 2.68306 9.55792C2.80027 9.44071 2.95924 9.37486 3.125 9.37486H15.3664L10.8078 4.81705C10.6905 4.69977 10.6247 4.54071 10.6247 4.37486C10.6247 4.20901 10.6905 4.04995 10.8078 3.93267C10.9251 3.8154 11.0841 3.74951 11.25 3.74951C11.4159 3.74951 11.5749 3.8154 11.6922 3.93267L17.3172 9.55767C17.3753 9.61572 17.4214 9.68465 17.4529 9.76052C17.4843 9.8364 17.5005 9.91772 17.5005 9.99986C17.5005 10.082 17.4843 10.1633 17.4529 10.2392C17.4214 10.3151 17.3753 10.384 17.3172 10.442Z" fill="#1D68F0" />
                        </svg></>}
                />

                <div data-section id='section-5'>
                    <HowHelpSection
                        overline={'HOW CAN WE HELP YOU'}
                        heading={<>Why should you choose <span style={{ color: '#1D68F0' }}>MIR Digital Solutions</span> to help manage your supply chain?</>}
                        desc={<>MIR Digital Solutions has a proven track record of successful implementations and a strong commitment to Microsoft technologies, resulting in unrivaled product knowledge and process improvement expertise. We help businesses digitize manufacturing, production, inventory and shipping operations with D365 Supply Chain Management tools. <br /><br />Our specialist team offers custom-tailored implementation of Microsoft Dynamics 365 Supply Chain Management for improved resource utilization, visibility, and data management. </>}
                        image={'url(/supply-chain/help-bg.png) no-repeat'}
                    />
                </div>

                <StepsSlider />

                <section className="process-slider-features">
                    <div className="container">
                        <div className="row features">
                            <div className="col-12 col-md-12 col-lg-4">
                                <div className="feature-item">
                                    <img src="/supply-chain/setting.svg" alt="feature-item-icon" className='mb-4' />
                                    <div>
                                        <h4 className='mir-h6-semibold'>An end-to-end implementation</h4>
                                        <p className='mir-p-rg-regular'>We provide full-length implementation framework, including assessment, strategy development, systems integration, data migration and training.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-4">
                                <div className="feature-item">
                                    <img src="/supply-chain/monitor.svg" alt="feature-item-icon" className='mb-4' />
                                    <div>
                                        <h4 className='mir-h6-semibold'>Industry-focused and technology best practices</h4>
                                        <p className='mir-p-rg-regular'>Our approach involves understanding specific needs of your organization's industry that can help solve the problem.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-4">
                                <div className="feature-item">
                                    <img src="/supply-chain/service.svg" alt="feature-item-icon" className='mb-4' />
                                    <div>
                                        <h4 className='mir-h6-semibold'>A dedicated 24x7 office support team</h4>
                                        <p className='mir-p-rg-regular'>In the Go-Live phase, our QA specialists will conduct in-depth training sessions for all users. We also provide 24x7 office support for post-implementation issues.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* success story */}
                <div data-section id='section-6'>
                    <SuccessStoriesDynamic
                        successStoriesContent={successStoriesContent}
                    />
                </div>

                <TransformationSection
                    heading={<>Maximize your digital transformation with <br /> Dynamics 365 next-generation application</>}
                    desc={<>Unlike traditional systems, Microsoft Dynamics 365 is an intelligent and scalable ERP platform of business applications that can work seamlessly together to modernize business operation. With our seasoned expert team, MIR can help you tailor and adapt any functionality to your business requirements. </>}
                    items={[
                        {
                            icon: '/supply-chain/transformation-icon-1.png',
                            heading: <>Dynamics Business <br /> Central</>,
                            link: <Link to={MIRRoutes.BusinessCentral}>
                                <span className='mir-p-sm-medium me-2'>Learn More</span>
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0" />
                                </svg>
                            </Link>
                        },
                        {
                            icon: '/supply-chain/transformation-icon-2.png',
                            heading: <>Dynamics Project <br />Operations</>,
                            link: <Link to={MIRRoutes.ProjectOperations}>
                                <span className='mir-p-sm-medium me-2'>Learn More</span>
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0" />
                                </svg>
                            </Link>
                        },
                        {
                            icon: '/supply-chain/transformation-icon-3.png',
                            heading: <>Dynamics Sales</>,
                            link: <Link to={MIRRoutes.Sales}>
                                <span className='mir-p-sm-medium me-2'>Learn More</span>
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0" />
                                </svg>
                            </Link>
                        }
                    ]}
                />

                <div data-section id='section-7'>
                    <ContactSection />
                </div>

                <Footer />
            </main>
        </>
    )
}

export const ChallengeIcon = () => (
    <svg width="35" height="35" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_5352_42016)">
            <path d="M22.75 28H5.25C2.35025 28 0 25.6497 0 22.75V5.25C0 2.35025 2.35025 0 5.25 0H22.75C25.6497 0 28 2.35025 28 5.25V22.75C28 25.6497 25.6497 28 22.75 28Z" fill="#FEEFEF" />
            <path d="M14 7C10.1386 7 7 10.1386 7 14C7 17.8614 10.1386 21 14 21C17.8614 21 21 17.8614 21 14C21 10.1386 17.8614 7 14 7ZM9.91638 12.2911C9.91638 11.6436 10.4414 11.1247 11.0828 11.1247C11.7241 11.1247 12.2491 11.6436 12.2491 12.2911C12.2491 12.9325 11.7241 13.4575 11.0828 13.4575C10.4414 13.4575 9.91638 12.9325 9.91638 12.2911ZM17.3294 17.9331C17.2156 18.0469 17.066 18.1038 16.9172 18.1038C16.7676 18.1038 16.6189 18.0469 16.5051 17.9331C15.8358 17.2638 14.9459 16.8962 14 16.8962C13.0541 16.8962 12.1642 17.2646 11.4957 17.934C11.2682 18.1615 10.899 18.1615 10.6706 17.934C10.4431 17.7065 10.4431 17.3372 10.6706 17.1089C11.5596 16.2199 12.7426 15.7299 14 15.7299C15.2574 15.7299 16.4395 16.2199 17.3294 17.1089C17.5569 17.3364 17.5569 17.7056 17.3294 17.9331ZM16.9164 13.4575C16.275 13.4575 15.75 12.9325 15.75 12.2911C15.75 11.6436 16.275 11.1247 16.9164 11.1247C17.5577 11.1247 18.0828 11.6436 18.0828 12.2911C18.0836 12.9325 17.5586 13.4575 16.9164 13.4575Z" fill="#E96767" />
        </g>
        <defs>
            <clipPath id="clip0_5352_42016">
                <rect width="28" height="28" fill="white" />
            </clipPath>
        </defs>
    </svg>
)