import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import Footer from '../Footer/Footer';
import StickyBanner from '../StickyBanner/StickyBanner';
import BookNowModal from '../CommonElements/BookNowModal';
import SectionTabs from '../CloudSolutions/Elements/Sections/SectionTabs/SectionTabs';
import OverviewSection from '../CloudSolutions/Elements/Sections/OverviewSection';
import BlueFluidBanner from '../CloudSolutions/Elements/Sections/BlueFluidBanner';
import HowHelpSection from '../CloudSolutions/Elements/Sections/HowHelpSection';
import StepsSlider from '../CloudSolutions/Elements/StepProcess/StepProcess';
import SuccessStories from '../CommonElements/SuccessStories';
import ContactSection from '../CloudSolutions/Elements/Sections/ContactSection';
import { Link } from 'react-router-dom';
import MIRRoutes from '../../lib/routes';
import SuccessStoriesDynamic from '../CommonElements/SuccessStoriesDynamic';

export default function LowCode() {

    const [tabs, setTabs] = useState([
        { name: 'Overview', id: 'section-1' },
        { name: 'Introduction', id: 'section-2' },
        { name: 'Benefits', id: 'section-3' },
        { name: 'How can we help you', id: 'section-4' },
        { name: 'Success Story', id: 'section-5' },
        { name: 'Contact Us', id: 'section-6' },
    ]);
    const successStoriesContent = [
        {
            image: "/success-stories-custom/12.png",
            timeToRead: "3",
            desc: "Boosting Internal Efficiency: Construction and Mining Leader Integrates Self-Serve Portal With Microsoft Dynamics 365",
            linkToRead: "/about-us/case-studies/self-serve-portal"
        },
        {
            image: "/success-stories-custom/8.png",
            timeToRead: "10",
            desc: " MIR and Compugen’s partnership transforms WoodGreen Tax Clinic services with Microsoft Power Platform",
            linkToRead: "/about-us/case-studies/woodgreen-tax-clinic-services-with-microsoft-power-platform"
        },
        {
            image: "/success-stories-custom/4.png",
            timeToRead: "5",
            desc: "National Telecom Giant - 65% time savings with Infrastructure Upgrade Automation",
            linkToRead: "/about-us/case-studies/national-telecom-giant-automated-infrastructure-upgrade"
        }
    ];
    const [showRegionPopup, setShowRegionPopup] = useState(false);

    return (

        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Low-Code Development & Acceleration | MIR Digital Solutions</title>
                <meta name="description" content="Embrace the future of development with Power Platform's low-code solutions. Experience MIR's accelerated innovation, unrivaled knowledge & process improvement." />
                <link rel="canonical" href={`${window.location.origin}/business-solutions/low-code-development`} />
            </Helmet>
            <main>

                <StickyBanner />

                <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />
                <section className='tw-pt-[120px] tw-pb-[160px] tw-bg-cover tw-bg-center tw-bg-no-repeat' style={{ backgroundImage: 'url(/supply-chain/bg.svg)' }}>
                    <div className="container mx-auto">
                        <h1 className='tw-mb-[16px] mir-display-semibold-sm mir-text-neutral-800 tw-max-w-full md:tw-max-w-[529px]'>Low Code Development</h1>
                        <h3 className='mir-text-neutral-600 tw-pb-[36px] mir-p-lg-regular m-0 tw-max-w-full md:tw-max-w-[576px]'>Accelerate innovation with Low Code Development</h3>
                        <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-lg mir-btn-primary mir-p-rg-medium'>
                            Contact us today
                        </button>
                    </div>
                </section>

                {/* section tabs */}
                <SectionTabs tabs={tabs} />

                <section className='!tw-p-0 tw-relative'>
                    <span className='tw-absolute tw-top-[-100px]' data-section id="section-1"></span>
                    <OverviewSection
                        overline={'OVERVIEW'}
                        heading={<>Seamless apps, scalable solutions</>}
                        desc={<>
                            <span className='tw-mb-[36px]'>In the next 5 years, the world will create 500 million applications, surpassing the total created in the past 40 years. This surge in demand for technical solutions is driven by the opportunity to automate tasks. However, a challenge arises as many companies struggle to hire and retain technical talent. The solution? <span className='mir-p-md-semibold'>Empower everyone with low-code technology platforms to develop applications.</span> <br /><br />
                                This is especially important as organizations face economic pressures, including app backlogs, budget constraints, and limited technical resources. <br /><br />
                                Of those surveyed in a recent Microsoft study, 82 percent of low- or no-code users agree that the technology helps provide an opportunity for software users to improve their development knowledge and technical skills. <a href="https://cloudblogs.microsoft.com/powerplatform/2022/05/24/low-code-trend-report-2022-building-a-learning-culture-on-a-low-code-platform/" target='_blank' className='mir-text-primary-500'>Learn More.</a></span> <br /><br />
                            <div className='tw-mt-[80px] tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[104px]'>
                                <div>
                                    <h6 className='tw-mb-[12px] mir-text-primary-500 mir-p-md-semibold !tw-text-[80px] !tw-leading-none'>83%</h6>
                                    <div className='mir-p-md-semibold mir-text-neutral-800'>Positive impact on work satisfaction and workload by users</div>
                                </div>
                                <div>
                                    <h6 className='tw-mb-[12px] mir-text-primary-500 mir-p-md-semibold !tw-text-[80px] !tw-leading-none'>80%</h6>
                                    <div className='mir-p-md-semibold mir-text-neutral-800'>Positive impact on morale by users</div>
                                </div>
                            </div>
                        </>}
                        bg={'url(/business/overview-bg.png)'}
                    />
                </section>

                <section className='mir-bg-neutral-100 tw-pt-[96px] tw-pb-[52px] tw-relative'>
                    <span className='tw-absolute tw-top-[-100px]' data-section id="section-2"></span>
                    <div className="container mx-auto">
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 md:tw-gap-x-[32px]">
                            <div>
                                <h4 className='mir-text-primary-500 mir-p-overline-lg tw-mb-[16px]'>INTRODUCTION</h4>
                                <h2 className='mir-h1-semibold mir-text-neutral-800 tw-mb-[32px]'>What is Low-code <br /> Development?</h2>
                                <p className='tw-max-w-full md:tw-max-w-[592px] tw-mb-[24px] mir-p-md-regular mir-text-neutral-700'>Low-code development simplifies the process of creating software applications by using visual tools and pre-built components. It reduces the need for manual coding, speeds up application development, and allows teams to collaborate more effectively. With low code, you can create applications faster and scale them as needed, making it ideal for various projects. <br /><br />
                                    No matter your role – whether you're an experienced developer, an IT administrator, a business analyst, or even if you don't have a technical background – low-code technology enables you to build solutions quickly, securely, and in alignment with compliance standards.</p>
                                <a href="https://powerapps.microsoft.com/en-us/low-code-no-code-development-platforms/" target='_blank' className='tw-py-[12px] tw-flex tw-items-center tw-justify-start'>
                                    <span className='tw-mr-[8px]'>Learn more</span>
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.1922 10.942L7.9422 17.192C7.88413 17.2501 7.81519 17.2962 7.73932 17.3276C7.66345 17.359 7.58213 17.3752 7.50001 17.3752C7.41789 17.3752 7.33657 17.359 7.2607 17.3276C7.18483 17.2962 7.11589 17.2501 7.05782 17.192C6.99976 17.134 6.95369 17.065 6.92227 16.9892C6.89084 16.9133 6.87466 16.832 6.87466 16.7499C6.87466 16.6677 6.89084 16.5864 6.92227 16.5105C6.95369 16.4347 6.99976 16.3657 7.05782 16.3077L12.8664 10.4999L7.05782 4.69205C6.94055 4.57477 6.87466 4.41571 6.87466 4.24986C6.87466 4.08401 6.94055 3.92495 7.05782 3.80767C7.1751 3.6904 7.33416 3.62451 7.50001 3.62451C7.66586 3.62451 7.82492 3.6904 7.9422 3.80767L14.1922 10.0577C14.2503 10.1157 14.2964 10.1846 14.3279 10.2605C14.3593 10.3364 14.3755 10.4177 14.3755 10.4999C14.3755 10.582 14.3593 10.6633 14.3279 10.7392C14.2964 10.8151 14.2503 10.884 14.1922 10.942Z" fill="#1D68F0" />
                                    </svg>
                                </a>
                            </div>
                            <div className='tw-flex tw-justify-center tw-items-center'>
                                <img className='tw-w-full' src="/low-code/intro-bg.png" alt="low code development introduction" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className='mir-bg-neutral-100 tw-pt-[96px] tw-pb-[52px]'>
                    <div className="container mx-auto">
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 md:tw-gap-x-[32px]">
                            <div className='tw-flex tw-justify-center tw-items-center'>
                                <img className='tw-w-full' src="/low-code/intro-bg-2.png" alt="low code development introduction" />
                            </div>
                            <div>
                                <h2 className='mir-h1-semibold mir-text-neutral-800 tw-mb-[32px]'>What is Low-code <br /> Development used for?</h2>
                                <p className='tw-max-w-full md:tw-max-w-[592px] tw-mb-[24px] mir-p-md-regular mir-text-neutral-700'>
                                    Low-code development serves as a versatile solution for organizations seeking to swiftly and effectively address business and market demands. It empowers non-technical individuals to tackle challenges and capitalize on emerging opportunities, relieving IT and development teams to focus on intricate projects. <br /><br />
                                    Additionally, professional developers can leverage low-code platforms to expedite application development without the need for laborious, line-by-line coding.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='mir-bg-neutral-100 tw-relative tw-pt-[52px] tw-pb-[96px]'>
                    <span className='tw-absolute tw-top-[-150px]' data-section id="section-3"></span>
                    <img src="/low-code/lines.png" className='tw-absolute tw-right-0 tw-bottom-0' alt='lines-bg-benefits' />
                    <div className="container mx-auto tw-relative tw-z-10">
                        <div className="text-center">
                            <h4 className='mir-text-primary-500 mir-p-overline-lg tw-mb-[16px]'>BENEFITS</h4>
                            <h2 className='mir-h2-semibold mir-text-neutral-800 mx-auto tw-mb-[24px] tw-max-w-full md:tw-max-w-[800px]'>Boost your business's innovation using MIR's low-code development solutions</h2>
                            <p className='tw-mb-[36px] mir-p-md-regular mir-text-neutral-700 mx-auto tw-max-w-full md:tw-max-w-[800px]'>Building supply chain resilience requires comprehensive operational insight spanning from planning to delivery. It necessitates unified real-time data integration from disparate sources like Excel files and ERPs. Microsoft Dynamics 365 for Supply Chain Management offers you</p>
                            <button onClick={() => { setShowRegionPopup(true) }} className='tw-mb-[80px] mir-btn-lg mir-btn-lg-primary'>Connect with an expert</button>
                        </div>
                        <div className='tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[32px]'>
                            {
                                [
                                    { image: '/low-code/benefits/1.svg', heading: 'Swift Application Development', desc: 'Low-code platforms expedite the creation and deployment of applications, enabling organizations to bring their ideas to life much faster compared to traditional coding approaches.' },
                                    { image: '/low-code/benefits/2.svg', heading: 'Cost-efficient Development', desc: 'By minimizing manual coding and shortening development cycles, low-code solutions often lead to cost savings in terms of both time and resources. ' },
                                    { image: '/low-code/benefits/3.svg', heading: 'Enhanced Productivity', desc: 'Enhance inventory accuracy and expedite delivery times by optimizing inventory and warehousing operations ' },
                                    { image: '/low-code/benefits/4.svg', heading: 'Accessibility for All', desc: 'Reduce downtime and with automated cycle counts that can help businesses perform proactive equipment maintenance ' },
                                    { image: '/low-code/benefits/5.svg', heading: 'Adaptability and Flexibility', desc: 'Make informed inventory decisions with priority-based supply planning, overstocking elimination' },
                                    { image: '/low-code/benefits/6.svg', heading: 'Seamless Integration', desc: 'Streamline procurement processes and improve cost management by using vendor collaboration portals and linking to vendor catalogs ' },
                                    { image: '/low-code/benefits/7.svg', heading: 'Scalability', desc: 'Reduce downtime and with automated cycle counts that can help businesses perform proactive equipment maintenance' },
                                ].map((item, index) => {
                                    return <div key={`benefit-item-${index}`} className='tw-p-[32px] tw-w-full tw-h-full tw-bg-white tw-rounded-[8px]' style={{ boxShadow: '0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px -1px rgba(16, 24, 40, 0.10)' }}>
                                        <img src={item.image} className='tw-mb-[24px]' alt={item.heading} />
                                        <h5 className='mir-h6-semibold mir-text-neutral-800 tw-mb-[12px]'>{item.heading}</h5>
                                        <p className='mir-p-rg-regular mir-text-neutral-600 !tw-m-0'>{item.desc}</p>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </section>

                <section className='tw-pt-[96px] tw-pb-[64px]'>
                    <div className="container mx-auto">
                        <div className="text-center">
                            <h2 className='mir-h1-semibold tw-mb-[24px] mir-text-neutral-800'>What is Microsoft Power Platform?</h2>
                            <h4 className='tw-hidden'>The Power Platform is a secure solution hosted on Microsoft's expansive and enterprise-grade cloud infrastructure, ensuring robust protection.</h4>
                            <p className='mir-p-md-regular tw-max-w-full md:tw-max-w-[800px] mx-auto'> <a href='https://powerplatformpartners.transform.microsoft.com/solution-plays/lowcode' target='_blank' className='mir-p-md-medium mir-text-primary-500'>The Power Platform is a secure solution hosted on Microsoft's expansive and enterprise-grade cloud infrastructure, ensuring robust protection.</a> It encompasses diverse capabilities, spanning application development, automation, chatbots, conversational agents, and website security. These functionalities cater to a wide spectrum of users within businesses, from seasoned developers to IT administrators, business leaders, and end-users.</p>
                        </div>
                    </div>
                </section>
                <h3 className='tw-hidden'>Microsoft Power Platform</h3>
                <div className="text-center tw-flex tw-justify-center">
                    <img src="/low-code/power-platform-galaxy.svg" className='tw-max-w-[120vw] md:tw-max-w-[1400px] mx-auto' alt="low-code-microsofot-power-platform" />
                </div>

                <section className='tw-pt-[120px] tw-pb-[96px] mir-bg-neutral-100'>
                    <div className="container mx-auto">
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2">
                            <div className='tw-max-w-full md:tw-max-w-[488px]'>
                                <h2 className='mir-h4-semibold tw-mb-[20px] mir-text-neutral-800'>Microsoft Power Platform comprises of several key elements</h2>
                                <p className='mir-text-neutral-800 mir-p-md-regular'>These elements work together smoothly, allowing businesses to automate processes, create custom apps, analyze data and use AI powered chatbots. By harnessing Power Platform, organizations can elevate productivity, streamline operations and propel towards digital transformation. </p>
                            </div>
                            <div className='tw-flex tw-justify-end tw-items-start'>
                                <div className='tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-[32px] tw-gap-y-[48px]'>
                                    {
                                        [
                                            { image: '/low-code/apps/1.svg', heading: 'Power Apps', desc: 'This is a user-friendly tool for creating custom business apps without needing to code. You can design web and mobile apps using a drag-and-drop interface.' },
                                            { image: '/low-code/apps/2.svg', heading: 'Power Automate', desc: "Formerly known as Microsoft Flow, this cloud service helps you automate tasks and workflows across different apps. It's like a virtual assistant that can handle repetitive tasks for you." },
                                            { image: '/low-code/apps/3.svg', heading: 'Power BI', desc: "This tool helps you make sense of data from different sources by creating interactive reports and dashboards. It's like a data visualization wizard." },
                                            { image: '/low-code/apps/4.svg', heading: 'Power Virtual Agents', desc: 'This platform lets you build chatbots without coding. These chatbots can interact with customers, answer questions, and provide support.' },
                                        ].map((item, index) => {
                                            return <div key={`app-item-${index}`} className='tw-w-full tw-h-full'>
                                                <img src={item.image} className='tw-mb-[24px]' alt={item.heading} />
                                                <h5 className='mir-h6-semibold mir-text-neutral-800 tw-mb-[12px]'>{item.heading}</h5>
                                                <p className='mir-p-rg-regular mir-text-neutral-600 !tw-m-0'>{item.desc}</p>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className='mir-bg-primary-500 tw-relative tw-overflow-hidden'>
                    <img src="/supply-chain/light-blue-banner-bg.png" alt="blue-banner-bg" className='tw-w-full tw-h-full tw-absolute tw-z-0 tw-inset-0' />
                    <div className={`tw-py-[36px] md:tw-py-[72px] container mx-auto`}>
                        <div className='tw-relative tw-z-10 tw-flex tw-justify-between tw-items-center tw-flex-wrap'>
                            <h3 className='tw-max-w-full md:tw-max-w-[640px] mir-h4-semibold text-left text-white'>Experience the future of development with Microsoft Power Platform's low-code solutions</h3>
                            <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-secondary mir-btn-md mir-text-primary-500 mir-bg-white'>
                                <span className='tw-mr-[12px] mir-p-rg-medium'>Begin your journey today</span>
                            </button>
                        </div>
                    </div>
                </div>

                <section className='tw-pt-[200px] tw-pb-[120px] tw-bg-[#000213] tw-relative tw-overflow-hidden'>
                    <img src="/sales/globe-vector.png" alt="globe-vector-banner-bg" className='tw-absolute tw-inset-0 tw-h-[13%] md:tw-h-auto tw-w-full' />
                    <div className="container mx-auto tw-relative tw-z-20">
                        <h2 className='mir-h1-semibold mir-text-neutral-100 tw-text-center md:tw-text-left tw-max-w-full md:tw-max-w-[720px] tw-mb-[24px]'>Why should you utilize Microsoft Power Platform for low-code development?</h2>
                        <p className='mir-p-md-regular mir-text-neutral-400 tw-text-center md:tw-text-left tw-max-w-full md:tw-max-w-[720px] tw-mb-[80px]'>Low-code development with Power Platform offers several key advantages for your business, and these benefits are further enhanced when integrated with Azure</p>
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-x-[32px] tw-gap-[32px]">
                            <div className='tw-max-w-full md:tw-max-w-[336px]'>
                                <h5 className='mir-text-neutral-100 mir-h6-medium tw-mb-[12px]'>Enhanced Employee Experience</h5>
                                <p className='mir-text-neutral-400 mir-p-rg-regular'>Low-code development accelerates the creation of applications and business processes, meeting demands more promptly, and ultimately improving the employee experience.</p>
                            </div>
                            <div className='tw-max-w-full md:tw-max-w-[336px]'>
                                <h5 className='mir-text-neutral-100 mir-h6-medium tw-mb-[12px]'>Cost Reduction</h5>
                                <p className='mir-text-neutral-400 mir-p-rg-regular'>By utilizing low-code solutions, businesses can achieve a significant 45% reduction in application development costs, contributing to cost-efficiency. </p>
                            </div>
                            <div className='tw-max-w-full md:tw-max-w-[336px]'>
                                <h5 className='mir-text-neutral-100 mir-h6-medium tw-mb-[12px]'>Robust and Scalable Development</h5>
                                <p className='mir-text-neutral-400 mir-p-rg-regular'>Low code empowers a wider range of individuals to tackle complex problems while simultaneously enhancing security measures and seamlessly managing the application lifecycle.</p>
                            </div>
                            <div className='tw-max-w-full md:tw-max-w-[336px]'>
                                <h5 className='mir-text-neutral-100 mir-h6-medium tw-mb-[12px]'>Effortless Integration and Extensibility</h5>
                                <p className='mir-text-neutral-400 mir-p-rg-regular'>Power Platform's integration capabilities, coupled with Azure services, provide unmatched flexibility to extend applications and meet evolving business needs.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='tw-py-[96px] mir-bg-neutral-100'>
                    <div className="container mx-auto">
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[32px]">
                            <div className='tw-flex tw-items-center tw-justify-start'>
                                <div className='tw-max-w-full md:tw-max-w-[591px]'>
                                    <h5 className='mir-h3-semibold mir-text-neutral-800 tw-mb-[24px]'>Power Platform offers unmatched governance and security control at scale</h5>
                                    <p className='mir-p-md-regular mir-text-neutral-800'>It's important to note that when selecting a suite of solutions, concerns may arise about trading best-in-class capabilities for suite benefits. However, with Power Platform, this is not the case. You receive best-in-class capabilities within an integrated platform. <br /><br />
                                        Across various categories, industry analysts consistently recognize Microsoft as a leader, not only in low-code development but also in cloud infrastructure, platform services, data management, productivity, security, and more. </p>
                                </div>
                            </div>
                            <div className='tw-flex tw-items-center tw-justify-end'>
                                <img className='tw-w-full' src="/low-code/power-platform.png" alt="Microsoft power platform compliance, governance, security capabilities chart" />
                            </div>
                        </div>
                    </div>
                </section>


                <div className='tw-relative' style={{ marginTop: '-24px' }}>
                    <span className='tw-absolute tw-top-[-150px]' data-section id="section-4"></span>
                    <HowHelpSection
                        overline={'HOW WE HELP YOU'}
                        heading={<>Why should you choose <span style={{ color: '#1D68F0' }}>MIR Digital Solutions</span> to build best low-code platform capabilities?</>}
                        desc={<>
                            MIR Digital Solutions has a proven track record of successful implementations and a strong commitment to Microsoft technologies, resulting in unrivaled product knowledge and process improvement expertise. <br /><br />
                            Our experience has enabled us to develop efficient processes and approaches for cloud solutions, enabling innovation in a way other competitors simply cannot.
                        </>}
                        image={'url(/low-code/how-help-bg.png) no-repeat'}
                    />
                </div>

                <div className="delivering-wrapper py-5 mb-5">
                    <div className="container">
                        <h3 className='mir-h5-semibold mb-4'>As a Microsoft Partner, we deliver</h3>
                        <div className="row">
                            {
                                [
                                    'An end-to-end digital transformation platform that drives project-based operations from prospect to payment to profit',
                                    'An extensive partner ecosystem and go-to-market channels, including AppSource Marketplace, partner-to partner network, and Microsoft sellers',
                                    'Dynamics 365 extensibility to unite Finance, Human Resources, Sales, Supply Chain Management, Customer Service, Field Service, and more',
                                    'Deep expertise and a rich history with CRM, ERP, HCM, collaboration, productivity, and project management applications',
                                    'Complete cloud vision that encompasses Azure, Office 365, and Dynamics 365',
                                    'Accelerated application development and flexibility with the Power Platform',
                                ].map((item) => {
                                    return <div className="col-12 col-md-4">
                                        <div className="deliver-item d-flex align-items-start justify-content-start">
                                            <img src="/project-operations/blue-check.svg" className='mt-3 me-3' alt="delivering-icon" />
                                            <h3 className='mir-p-rg-regular mt-3' style={{ maxWidth: '450px' }}>{item}</h3>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>

                <StepsSlider
                    subtitle={'Our specialist team offers custom-tailored implementation of Microsoft Power Apps for crafting personalized applications, bypassing intricate coding.'}
                />

                <section className='tw-py-[52px]'>
                    <div className="container mx-auto">
                        <div className="text-center">
                            <h3 className='mx-auto mir-h4-semibold mir-text-neutral-800 tw-max-w-full md:tw-max-w-[732px] tw-mb-[36px]'>Ready to kickstart your Low-code Development journey with Microsoft Power Platform?</h3>
                            <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-lg mir-btn-lg-primary'>Get Started Now</button>
                        </div>
                    </div>
                </section>

                <div className='tw-relative'>
                    <span className='tw-absolute tw-top-[-150px]' data-section id="section-5"></span>
                    <SuccessStoriesDynamic
                        successStoriesContent={successStoriesContent}
                    />
                    <div className="text-center tw-pb-[96px]">
                        <Link to={MIRRoutes.CaseStudies}>
                            <button className='mir-btn-md-tertiary'>View All</button>
                        </Link>
                    </div>
                </div>

                <div className='tw-relative'>
                    <span className='tw-absolute tw-top-[-150px]' data-section id="section-6"></span>
                    <ContactSection />
                </div>

                <Footer />

            </main>

        </>

    )
}
