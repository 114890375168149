import React, { useState } from 'react'
import StickyBanner from '../../StickyBanner/StickyBanner'
import Footer from '../../Footer/Footer'
import SectionTabs from '../Elements/Sections/SectionTabs/SectionTabs'
import OverviewSection from '../Elements/Sections/OverviewSection';
import QuestionSlider from '../Elements/QuestionSlider';
import Dynamics from '../Elements/Sections/Dynamics';
import BlueBanner from '../Elements/Sections/BlueBanner';
import HowHelpSection from '../Elements/Sections/HowHelpSection';
import StepsSlider from '../Elements/StepProcess/StepProcess';
import SuccessStory from '../Elements/Sections/SuccessStory';
import TransformationSection from '../Elements/Sections/TransformationSection';
import ContactSection from '../Elements/Sections/ContactSection';
import BookNowModal from '../../CommonElements/BookNowModal';
import { Helmet } from 'react-helmet';
import CaseStudyUrls from '../../../lib/singleCaseStudyUrls';
import { Link } from 'react-router-dom';
import MIRRoutes from '../../../lib/routes';
import SuccessStoriesDynamic from '../../CommonElements/SuccessStoriesDynamic';

export default function PowerBi() {

    const [tabs, setTabs] = useState([
        { name: 'Overview', id: 'section-1' },
        { name: 'Introduction', id: 'section-2' },
        { name: 'Benefits', id: 'section-3' },
        { name: 'How can we help you', id: 'section-4' },
        { name: 'Success Story', id: 'section-5' },
        { name: 'Contact Us', id: 'section-6' },
    ]);

    const [showRegionPopup, setShowRegionPopup] = useState(false);

    const questions = [
        'How satisfied are you with the adoption of analytics tools across the organization?',
        'How easy is it for non-analytic / non-data minded people to interact with today’s current reporting tools?',
        'How do you ensure that your business intelligence data is protected and only accessible to trusted parties?',
        'How easy is it for you to provide reporting in the places your users spend the most time?'
    ]

    const successStoriesContent = [
        {
            image: "/success-stories-custom/11.png",
            timeToRead: "3",
            desc: "MIR Enhances Leading Canadian Firm’s Critical Conflict Analysis & Client Intake Power BI Reporting",
            linkToRead: "/about-us/case-studies/power-bi-paw-firm"
        },
        {
            image: "/success-stories-custom/2.png",
            timeToRead: "5",
            desc: "A&A Pharmachem Inc Drives Operational Efficiencies With D365 Business Central and PowerBI",
            linkToRead: "/about-us/case-studies/aanda-pharmachem-business-central-power-bi"
        },
        {
            image: "/success-stories-custom/8.png",
            timeToRead: "10",
            desc: "Leading Law Firm Reduced 300+ Hours of Manual Work with MIR Interview Assessment Solution",
            linkToRead: "/about-us/case-studies/law-firm-azure-interview-assessment-tool"
        }
    ];

  return (
    <main>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Microsoft Power BI Intelligence Solution | MIR Digital Solutions</title>
            <meta name="description" content="Unleash the power of your data, transform it to actionable insights with PowerBI - the ultimate solution for data visualization, reporting and analysis." />
            <link rel="canonical" href={`${window.location.origin}/cloud-solutions/microoft-power-bi-intelligence-solution`} />
        </Helmet>
        <StickyBanner />
        <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />
        <section className='tw-pt-[120px] tw-pb-[160px] tw-bg-cover tw-bg-center tw-bg-no-repeat' style={{ backgroundImage: 'url(/supply-chain/bg.svg)'  }}>
            <div className="container mx-auto text-center">
                <img src="/power-bi/hero-icon.png" className='tw-mb-[42px]'
                    alt="hero-icon" 
                />
                <h4 className='tw-mb-[24px] mir-h6-medium mir-text-primary-500'>Your all-on-one business intelligence platform</h4>
                <h2 className='mir-display-semibold-sm mir-text-neutral-800 m-0'>Microsoft Power BI</h2>
            </div>
        </section>

        {/* section tabs */}
        <SectionTabs tabs={tabs} />

        <section data-section id="section-1" className='!tw-p-0'>
            <OverviewSection
                overline={'OVERVIEW'}
                heading={<>Embark on a path to enhanced data analysis with Microsoft Power BI</>}
                desc={<>
                    <span className='tw-mb-[36px]'>As organizations strive to build a better future, they're asking a vital question: 
                        <a target='_blank' href="https://www.mckinsey.com/capabilities/people-and-organizational-performance/our-insights/three-keys-to-faster-better-decisions" className='mir-p-rg-medium'>&nbsp;How do we improve decision-making?</a>&nbsp;
                        With tighter budgets and less room for error, gut-feel choices are riskier. The impact of decisions on the bottom line, at all levels, requires careful thought.</span> <br /><br />
                    <a className='tw-mb-[36px] mir-p-md-medium' href='https://digitalmarketinginstitute.com/blog/how-to-use-data-analytics-to-drive-better-business-insights'>Everyone wants to use data better, but challenges with tools and more data sources are holding back both IT and business users from getting useful insights.</a> <br /><br />
                    <span className='mir-h6-semibold'>Here are the top BI challenges businesses face:</span> <br /><br />
                    <div className='tw-mt-[36px]'>
                        {
                            [
                                'Integrating data from different source systems ',
                                'Data quality issues ',
                                'Data silos with inconsistent information ',
                                'Low adoption of BI tools ',
                                'Bad data visualization and dashboard design practices '
                            ].map((item, index) => {
                                return <div className='tw-flex tw-items-start tw-justify-start tw-mb-[32px]'>
                                    <img src="/power-apps/sad.png" alt="sadness-icon" className='tw-mr-[24px]' />
                                    <div className='tw-max-w-full md:tw-max-w-[583px]'>
                                        <h3 className='mir-p-rg-regular'>{item}</h3>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </>}
                bg={'url(/business/overview-bg.png)'}
            />
        </section>

        <QuestionSlider questions={questions} />

        <div data-section id={'section-2'}>
            <Dynamics
                icon={'/power-bi/hero-icon.png'}   
                overline={'INTRODUCTION'}
                heading={<>What is Microsoft Power BI?</>}
                desc={<>Microsoft Power BI is a user-friendly tool for analyzing business data, helping you make informed decisions quickly. It offers easy report creation and data visualization, shareable through tools like Teams and SharePoint. It suits both simple tasks and advanced analytics for enterprises. With the Power BI Quick Start Offering, you can swiftly create reports from your existing data, while our experts offer consulting. Integrating Power BI into apps like Teams enables easy decision-making from anywhere, using consistent and accurate data. </>}
                videoPlaceholder={"/supply-chain/video-placeholder.png"}
                iframe={<iframe width="100%" height="541px" src="https://www.youtube.com/embed/yKTSLffVGbk?si=1O2lpotQJ4b09fEJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>}
            />
        </div>

        <section className='tw-pt-[96px] tw-pb-[72px]' data-section id='section-3'>
            <div className="container mx-auto">
                <div className="text-center">
                    <h4 className='mir-p-overline-large mir-text-primary-500 tw-mb-[16px]'>BENEFITS</h4>
                    <h2 className='tw-mb-[24px] mir-h1-semibold mir-text-neutral-800'>Why should you choose <br /> Microsoft Power BI?</h2>
                    <p className='mir-p-md-regular mir-text-neutral-700 tw-mb-[64px]'>Microsoft Power BI should be your choice for several compelling reasons: </p>
                </div>
                <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 md:tw-divide-x md:tw-divide-y md:tw-divide-solid md:tw-divide-slate-200">
                    <div className='tw-p-[24px] md:tw-p-[48px]'>
                        <h5 className='tw-max-w-full md:tw-max-w-[277px] mir-h6-semibold mir-text-neutral-800 tw-mb-[28px]'>Create Amazing Data Experiences</h5>
                        <ul className='tw-p-0'>
                            {
                                [
                                    'Seamlessly integrate with Excel',
                                    'Broad range of data connectors',
                                    'Tailor reports to your business and KPIs',
                                    'Abundant visualizations and branding options',
                                    'Native Visio visualizations',
                                    'Simple template app installation',
                                ].map((item, index) => {
                                    return <li className='tw-mb-[12px] tw-flex tw-items-start tw-justify-start' key={`benefit-sub-item-${index}`}> 
                                        <svg className='tw-mr-[16px] tw-mt-[5px] tw-min-w-[16px]' width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                                            <path d="M5.46946 12.9695L5.46945 12.9695C5.39978 13.0392 5.34451 13.1219 5.30681 13.2129C5.2691 13.3039 5.24969 13.4015 5.24969 13.5C5.24969 13.5986 5.2691 13.6961 5.30681 13.7872C5.34451 13.8782 5.39978 13.9609 5.46945 14.0306C5.53912 14.1002 5.62183 14.1555 5.71285 14.1932C5.80388 14.2309 5.90144 14.2503 5.99997 14.2503C6.0985 14.2503 6.19607 14.2309 6.28709 14.1932C6.37812 14.1555 6.46083 14.1002 6.5305 14.0306L11.5304 9.03066C11.5304 9.03063 11.5305 9.03059 11.5305 9.03056C11.6002 8.96093 11.6555 8.87824 11.6932 8.78724C11.7309 8.69619 11.7504 8.59859 11.7504 8.50003C11.7504 8.40147 11.7309 8.30388 11.6932 8.21283C11.6555 8.12182 11.6002 8.03914 11.5305 7.96951C11.5305 7.96947 11.5304 7.96944 11.5304 7.96941L6.5305 2.96951C6.3898 2.8288 6.19896 2.74976 5.99997 2.74976C5.80099 2.74976 5.61015 2.8288 5.46945 2.96951C5.32874 3.11021 5.24969 3.30105 5.24969 3.50003C5.24969 3.69902 5.32874 3.88986 5.46945 4.03056L5.46946 4.03057L9.93952 8.50003L5.46946 12.9695Z" fill="#1D68F0" stroke="#1D68F0" stroke-width="0.5"/>
                                        </svg>
                                        <p className='tw-max-w-full md:tw-max-w-[245px] mir-text-neutral-700 mir-p-rg-regular tw-m-0'>{item}</p>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                    <div className='tw-p-[24px] md:tw-p-[48px] !tw-border-t-0'>
                        <h5 className='tw-max-w-full md:tw-max-w-[277px] mir-h6-semibold mir-text-neutral-800 tw-mb-[28px]'>Efficient Business Insights</h5>
                        <ul className='tw-p-0'>
                            {
                                [
                                    'Ask data questions simply',
                                    'Understandable jargon and acronyms',
                                    'Customize interactive narrative',
                                    'Identify anomalies personally',
                                ].map((item, index) => {
                                    return <li className='tw-mb-[12px] tw-flex tw-items-start tw-justify-start' key={`benefit-sub-item-${index}`}> 
                                        <svg className='tw-mr-[16px] tw-mt-[5px] tw-min-w-[16px]' width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                                            <path d="M5.46946 12.9695L5.46945 12.9695C5.39978 13.0392 5.34451 13.1219 5.30681 13.2129C5.2691 13.3039 5.24969 13.4015 5.24969 13.5C5.24969 13.5986 5.2691 13.6961 5.30681 13.7872C5.34451 13.8782 5.39978 13.9609 5.46945 14.0306C5.53912 14.1002 5.62183 14.1555 5.71285 14.1932C5.80388 14.2309 5.90144 14.2503 5.99997 14.2503C6.0985 14.2503 6.19607 14.2309 6.28709 14.1932C6.37812 14.1555 6.46083 14.1002 6.5305 14.0306L11.5304 9.03066C11.5304 9.03063 11.5305 9.03059 11.5305 9.03056C11.6002 8.96093 11.6555 8.87824 11.6932 8.78724C11.7309 8.69619 11.7504 8.59859 11.7504 8.50003C11.7504 8.40147 11.7309 8.30388 11.6932 8.21283C11.6555 8.12182 11.6002 8.03914 11.5305 7.96951C11.5305 7.96947 11.5304 7.96944 11.5304 7.96941L6.5305 2.96951C6.3898 2.8288 6.19896 2.74976 5.99997 2.74976C5.80099 2.74976 5.61015 2.8288 5.46945 2.96951C5.32874 3.11021 5.24969 3.30105 5.24969 3.50003C5.24969 3.69902 5.32874 3.88986 5.46945 4.03056L5.46946 4.03057L9.93952 8.50003L5.46946 12.9695Z" fill="#1D68F0" stroke="#1D68F0" stroke-width="0.5"/>
                                        </svg>
                                        <p className='tw-max-w-full md:tw-max-w-[245px] mir-text-neutral-700 mir-p-rg-regular tw-m-0'>{item}</p>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                    <div className='tw-p-[24px] md:tw-p-[48px] !tw-border-t-0'>
                        <h5 className='tw-max-w-full md:tw-max-w-[277px] mir-h6-semibold mir-text-neutral-800 tw-mb-[28px]'>Scaling and Security</h5>
                        <ul className='tw-p-0'>
                            {
                                [
                                    'Address demanding Business Intelligence needs',
                                    'Enterprise-level security and governance',
                                    'Unique data protection on export',
                                ].map((item, index) => {
                                    return <li className='tw-mb-[12px] tw-flex tw-items-start tw-justify-start' key={`benefit-sub-item-${index}`}> 
                                        <svg className='tw-mr-[16px] tw-mt-[5px] tw-min-w-[16px]' width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                                            <path d="M5.46946 12.9695L5.46945 12.9695C5.39978 13.0392 5.34451 13.1219 5.30681 13.2129C5.2691 13.3039 5.24969 13.4015 5.24969 13.5C5.24969 13.5986 5.2691 13.6961 5.30681 13.7872C5.34451 13.8782 5.39978 13.9609 5.46945 14.0306C5.53912 14.1002 5.62183 14.1555 5.71285 14.1932C5.80388 14.2309 5.90144 14.2503 5.99997 14.2503C6.0985 14.2503 6.19607 14.2309 6.28709 14.1932C6.37812 14.1555 6.46083 14.1002 6.5305 14.0306L11.5304 9.03066C11.5304 9.03063 11.5305 9.03059 11.5305 9.03056C11.6002 8.96093 11.6555 8.87824 11.6932 8.78724C11.7309 8.69619 11.7504 8.59859 11.7504 8.50003C11.7504 8.40147 11.7309 8.30388 11.6932 8.21283C11.6555 8.12182 11.6002 8.03914 11.5305 7.96951C11.5305 7.96947 11.5304 7.96944 11.5304 7.96941L6.5305 2.96951C6.3898 2.8288 6.19896 2.74976 5.99997 2.74976C5.80099 2.74976 5.61015 2.8288 5.46945 2.96951C5.32874 3.11021 5.24969 3.30105 5.24969 3.50003C5.24969 3.69902 5.32874 3.88986 5.46945 4.03056L5.46946 4.03057L9.93952 8.50003L5.46946 12.9695Z" fill="#1D68F0" stroke="#1D68F0" stroke-width="0.5"/>
                                        </svg>
                                        <p className='tw-max-w-full md:tw-max-w-[245px] mir-text-neutral-700 mir-p-rg-regular tw-m-0'>{item}</p>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                    <div className='tw-p-[24px] md:tw-p-[48px] !tw-border-l-0'>
                        <h5 className='tw-max-w-full md:tw-max-w-[277px] mir-h6-semibold mir-text-neutral-800 tw-mb-[28px]'>Data Integration and Confidence</h5>
                        <ul className='tw-p-0'>
                            {
                                [
                                    'Rich integration with Excel',
                                    'Swiftly locate and connect organizational data',
                                    'Familiar tools for smoother analytics',
                                ].map((item, index) => {
                                    return <li className='tw-mb-[12px] tw-flex tw-items-start tw-justify-start' key={`benefit-sub-item-${index}`}> 
                                        <svg className='tw-mr-[16px] tw-mt-[5px] tw-min-w-[16px]' width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                                            <path d="M5.46946 12.9695L5.46945 12.9695C5.39978 13.0392 5.34451 13.1219 5.30681 13.2129C5.2691 13.3039 5.24969 13.4015 5.24969 13.5C5.24969 13.5986 5.2691 13.6961 5.30681 13.7872C5.34451 13.8782 5.39978 13.9609 5.46945 14.0306C5.53912 14.1002 5.62183 14.1555 5.71285 14.1932C5.80388 14.2309 5.90144 14.2503 5.99997 14.2503C6.0985 14.2503 6.19607 14.2309 6.28709 14.1932C6.37812 14.1555 6.46083 14.1002 6.5305 14.0306L11.5304 9.03066C11.5304 9.03063 11.5305 9.03059 11.5305 9.03056C11.6002 8.96093 11.6555 8.87824 11.6932 8.78724C11.7309 8.69619 11.7504 8.59859 11.7504 8.50003C11.7504 8.40147 11.7309 8.30388 11.6932 8.21283C11.6555 8.12182 11.6002 8.03914 11.5305 7.96951C11.5305 7.96947 11.5304 7.96944 11.5304 7.96941L6.5305 2.96951C6.3898 2.8288 6.19896 2.74976 5.99997 2.74976C5.80099 2.74976 5.61015 2.8288 5.46945 2.96951C5.32874 3.11021 5.24969 3.30105 5.24969 3.50003C5.24969 3.69902 5.32874 3.88986 5.46945 4.03056L5.46946 4.03057L9.93952 8.50003L5.46946 12.9695Z" fill="#1D68F0" stroke="#1D68F0" stroke-width="0.5"/>
                                        </svg>
                                        <p className='tw-max-w-full md:tw-max-w-[245px] mir-text-neutral-700 mir-p-rg-regular tw-m-0'>{item}</p>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                    <div className='tw-p-[24px] md:tw-p-[48px]'>
                        <h5 className='tw-max-w-full md:tw-max-w-[277px] mir-h6-semibold mir-text-neutral-800 tw-mb-[28px]'>Empower Confident Decisions</h5>
                        <ul className='tw-p-0'>
                            {
                                [
                                    'Automate manual processes and access isolated data',
                                    'Seamlessly integrate with Office tools and Teams collaboration',
                                    'Customize interactive narrative',
                                    'Identify anomalies personally',
                                ].map((item, index) => {
                                    return <li className='tw-mb-[12px] tw-flex tw-items-start tw-justify-start' key={`benefit-sub-item-${index}`}> 
                                        <svg className='tw-mr-[16px] tw-mt-[5px] tw-min-w-[16px]' width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                                            <path d="M5.46946 12.9695L5.46945 12.9695C5.39978 13.0392 5.34451 13.1219 5.30681 13.2129C5.2691 13.3039 5.24969 13.4015 5.24969 13.5C5.24969 13.5986 5.2691 13.6961 5.30681 13.7872C5.34451 13.8782 5.39978 13.9609 5.46945 14.0306C5.53912 14.1002 5.62183 14.1555 5.71285 14.1932C5.80388 14.2309 5.90144 14.2503 5.99997 14.2503C6.0985 14.2503 6.19607 14.2309 6.28709 14.1932C6.37812 14.1555 6.46083 14.1002 6.5305 14.0306L11.5304 9.03066C11.5304 9.03063 11.5305 9.03059 11.5305 9.03056C11.6002 8.96093 11.6555 8.87824 11.6932 8.78724C11.7309 8.69619 11.7504 8.59859 11.7504 8.50003C11.7504 8.40147 11.7309 8.30388 11.6932 8.21283C11.6555 8.12182 11.6002 8.03914 11.5305 7.96951C11.5305 7.96947 11.5304 7.96944 11.5304 7.96941L6.5305 2.96951C6.3898 2.8288 6.19896 2.74976 5.99997 2.74976C5.80099 2.74976 5.61015 2.8288 5.46945 2.96951C5.32874 3.11021 5.24969 3.30105 5.24969 3.50003C5.24969 3.69902 5.32874 3.88986 5.46945 4.03056L5.46946 4.03057L9.93952 8.50003L5.46946 12.9695Z" fill="#1D68F0" stroke="#1D68F0" stroke-width="0.5"/>
                                        </svg>
                                        <p className='tw-max-w-full md:tw-max-w-[245px] mir-text-neutral-700 mir-p-rg-regular tw-m-0'>{item}</p>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                    <div className='tw-p-[24px] md:tw-p-[48px]'>
                        <h5 className='tw-max-w-full md:tw-max-w-[277px] mir-h6-semibold mir-text-neutral-800 tw-mb-[28px]'>Scalability and Compliance</h5>
                        <ul className='tw-p-0'>
                            {
                                [
                                    'Meeting demanding Business Intelligence requirements',
                                    'Maintaining data accuracy and compliance',
                                    'Secure, scalable for large BI deployments',
                                ].map((item, index) => {
                                    return <li className='tw-mb-[12px] tw-flex tw-items-start tw-justify-start' key={`benefit-sub-item-${index}`}> 
                                        <svg className='tw-mr-[16px] tw-mt-[5px] tw-min-w-[16px]' width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                                            <path d="M5.46946 12.9695L5.46945 12.9695C5.39978 13.0392 5.34451 13.1219 5.30681 13.2129C5.2691 13.3039 5.24969 13.4015 5.24969 13.5C5.24969 13.5986 5.2691 13.6961 5.30681 13.7872C5.34451 13.8782 5.39978 13.9609 5.46945 14.0306C5.53912 14.1002 5.62183 14.1555 5.71285 14.1932C5.80388 14.2309 5.90144 14.2503 5.99997 14.2503C6.0985 14.2503 6.19607 14.2309 6.28709 14.1932C6.37812 14.1555 6.46083 14.1002 6.5305 14.0306L11.5304 9.03066C11.5304 9.03063 11.5305 9.03059 11.5305 9.03056C11.6002 8.96093 11.6555 8.87824 11.6932 8.78724C11.7309 8.69619 11.7504 8.59859 11.7504 8.50003C11.7504 8.40147 11.7309 8.30388 11.6932 8.21283C11.6555 8.12182 11.6002 8.03914 11.5305 7.96951C11.5305 7.96947 11.5304 7.96944 11.5304 7.96941L6.5305 2.96951C6.3898 2.8288 6.19896 2.74976 5.99997 2.74976C5.80099 2.74976 5.61015 2.8288 5.46945 2.96951C5.32874 3.11021 5.24969 3.30105 5.24969 3.50003C5.24969 3.69902 5.32874 3.88986 5.46945 4.03056L5.46946 4.03057L9.93952 8.50003L5.46946 12.9695Z" fill="#1D68F0" stroke="#1D68F0" stroke-width="0.5"/>
                                        </svg>
                                        <p className='tw-max-w-full md:tw-max-w-[245px] mir-text-neutral-700 mir-p-rg-regular tw-m-0'>{item}</p>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                    <div className='tw-p-[24px] md:tw-p-[48px] !tw-border-l-0'>
                        <h5 className='tw-max-w-full md:tw-max-w-[277px] mir-h6-semibold mir-text-neutral-800 tw-mb-[28px]'>Enhanced Data Protection</h5>
                        <ul className='tw-p-0'>
                            {
                                [
                                    'Real-time session monitoring with Cloud App Security',
                                    'Admin-defined real-time remediation',
                                    'Retained data protection on export',
                                ].map((item, index) => {
                                    return <li className='tw-mb-[12px] tw-flex tw-items-start tw-justify-start' key={`benefit-sub-item-${index}`}> 
                                        <svg className='tw-mr-[16px] tw-mt-[5px] tw-min-w-[16px]' width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                                            <path d="M5.46946 12.9695L5.46945 12.9695C5.39978 13.0392 5.34451 13.1219 5.30681 13.2129C5.2691 13.3039 5.24969 13.4015 5.24969 13.5C5.24969 13.5986 5.2691 13.6961 5.30681 13.7872C5.34451 13.8782 5.39978 13.9609 5.46945 14.0306C5.53912 14.1002 5.62183 14.1555 5.71285 14.1932C5.80388 14.2309 5.90144 14.2503 5.99997 14.2503C6.0985 14.2503 6.19607 14.2309 6.28709 14.1932C6.37812 14.1555 6.46083 14.1002 6.5305 14.0306L11.5304 9.03066C11.5304 9.03063 11.5305 9.03059 11.5305 9.03056C11.6002 8.96093 11.6555 8.87824 11.6932 8.78724C11.7309 8.69619 11.7504 8.59859 11.7504 8.50003C11.7504 8.40147 11.7309 8.30388 11.6932 8.21283C11.6555 8.12182 11.6002 8.03914 11.5305 7.96951C11.5305 7.96947 11.5304 7.96944 11.5304 7.96941L6.5305 2.96951C6.3898 2.8288 6.19896 2.74976 5.99997 2.74976C5.80099 2.74976 5.61015 2.8288 5.46945 2.96951C5.32874 3.11021 5.24969 3.30105 5.24969 3.50003C5.24969 3.69902 5.32874 3.88986 5.46945 4.03056L5.46946 4.03057L9.93952 8.50003L5.46946 12.9695Z" fill="#1D68F0" stroke="#1D68F0" stroke-width="0.5"/>
                                        </svg>
                                        <p className='tw-max-w-full md:tw-max-w-[245px] mir-text-neutral-700 mir-p-rg-regular tw-m-0'>{item}</p>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                    <div className='tw-p-[24px] md:tw-p-[48px]'>
                        <h5 className='tw-max-w-full md:tw-max-w-[277px] mir-h6-semibold mir-text-neutral-800 tw-mb-[28px]'>Advanced Workloads</h5>
                        <ul className='tw-p-0'>
                            {
                                [
                                    'Unified self-service analytics and enterprise Business Intelligence',
                                    'Data source usage monitoring',
                                    'Usage-based optimization',
                                    'Enhanced content publishing efficiency and accuracy',
                                ].map((item, index) => {
                                    return <li className='tw-mb-[12px] tw-flex tw-items-start tw-justify-start' key={`benefit-sub-item-${index}`}> 
                                        <svg className='tw-mr-[16px] tw-mt-[5px] tw-min-w-[16px]' width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                                            <path d="M5.46946 12.9695L5.46945 12.9695C5.39978 13.0392 5.34451 13.1219 5.30681 13.2129C5.2691 13.3039 5.24969 13.4015 5.24969 13.5C5.24969 13.5986 5.2691 13.6961 5.30681 13.7872C5.34451 13.8782 5.39978 13.9609 5.46945 14.0306C5.53912 14.1002 5.62183 14.1555 5.71285 14.1932C5.80388 14.2309 5.90144 14.2503 5.99997 14.2503C6.0985 14.2503 6.19607 14.2309 6.28709 14.1932C6.37812 14.1555 6.46083 14.1002 6.5305 14.0306L11.5304 9.03066C11.5304 9.03063 11.5305 9.03059 11.5305 9.03056C11.6002 8.96093 11.6555 8.87824 11.6932 8.78724C11.7309 8.69619 11.7504 8.59859 11.7504 8.50003C11.7504 8.40147 11.7309 8.30388 11.6932 8.21283C11.6555 8.12182 11.6002 8.03914 11.5305 7.96951C11.5305 7.96947 11.5304 7.96944 11.5304 7.96941L6.5305 2.96951C6.3898 2.8288 6.19896 2.74976 5.99997 2.74976C5.80099 2.74976 5.61015 2.8288 5.46945 2.96951C5.32874 3.11021 5.24969 3.30105 5.24969 3.50003C5.24969 3.69902 5.32874 3.88986 5.46945 4.03056L5.46946 4.03057L9.93952 8.50003L5.46946 12.9695Z" fill="#1D68F0" stroke="#1D68F0" stroke-width="0.5"/>
                                        </svg>
                                        <p className='tw-max-w-full md:tw-max-w-[245px] mir-text-neutral-700 mir-p-rg-regular tw-m-0'>{item}</p>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                    <div className='tw-p-[24px] md:tw-p-[48px]'>
                        <h5 className='tw-max-w-full md:tw-max-w-[277px] mir-h6-semibold mir-text-neutral-800 tw-mb-[28px]'>Power BI Dashboards</h5>
                        <ul className='tw-p-0'>
                            {
                                [
                                    'Create comprehensive dashboards powered by Microsoft technology',
                                    'Offer real-time, consolidated metrics accessible across devices',
                                ].map((item, index) => {
                                    return <li className='tw-mb-[12px] tw-flex tw-items-start tw-justify-start' key={`benefit-sub-item-${index}`}> 
                                        <svg className='tw-mr-[16px] tw-mt-[5px] tw-min-w-[16px]' width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                                            <path d="M5.46946 12.9695L5.46945 12.9695C5.39978 13.0392 5.34451 13.1219 5.30681 13.2129C5.2691 13.3039 5.24969 13.4015 5.24969 13.5C5.24969 13.5986 5.2691 13.6961 5.30681 13.7872C5.34451 13.8782 5.39978 13.9609 5.46945 14.0306C5.53912 14.1002 5.62183 14.1555 5.71285 14.1932C5.80388 14.2309 5.90144 14.2503 5.99997 14.2503C6.0985 14.2503 6.19607 14.2309 6.28709 14.1932C6.37812 14.1555 6.46083 14.1002 6.5305 14.0306L11.5304 9.03066C11.5304 9.03063 11.5305 9.03059 11.5305 9.03056C11.6002 8.96093 11.6555 8.87824 11.6932 8.78724C11.7309 8.69619 11.7504 8.59859 11.7504 8.50003C11.7504 8.40147 11.7309 8.30388 11.6932 8.21283C11.6555 8.12182 11.6002 8.03914 11.5305 7.96951C11.5305 7.96947 11.5304 7.96944 11.5304 7.96941L6.5305 2.96951C6.3898 2.8288 6.19896 2.74976 5.99997 2.74976C5.80099 2.74976 5.61015 2.8288 5.46945 2.96951C5.32874 3.11021 5.24969 3.30105 5.24969 3.50003C5.24969 3.69902 5.32874 3.88986 5.46945 4.03056L5.46946 4.03057L9.93952 8.50003L5.46946 12.9695Z" fill="#1D68F0" stroke="#1D68F0" stroke-width="0.5"/>
                                        </svg>
                                        <p className='tw-max-w-full md:tw-max-w-[245px] mir-text-neutral-700 mir-p-rg-regular tw-m-0'>{item}</p>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className="container mx-auto tw-bg-[#001d6c] tw-relative md:tw-rounded-[12px]">
                <img src="/supply-chain/blue-banner-bg.png" className='tw-absolute tw-z-0 tw-inset-0 tw-w-full tw-h-full tw-rounded-[12px]'
                    alt="Streamline data analysis and reporting with Microsoft Power BI" />
                <div className="tw-py-[52px] tw-px-[30px] md:tw-py-[52px] md:tw-px-[64px] tw-rounded-[12px] tw-flex tw-items-center tw-flex-wrap tw-justify-between tw-relative tw-z-10">
                    <h3 className='tw-mb-[24px] md:tw-mb-0 tw-max-w-full md:tw-max-w-[545px] mir-h5-semibold mir-text-neutral-100'>Streamline data analysis and reporting with Microsoft Power BI</h3>
                    <button className='mir-btn-md mir-btn-primary mir-p-rg-medium' onClick={() => { setShowRegionPopup(true) }}>
                        <span className='tw-mr-[12px]'>Set up today</span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.3172 10.4423L11.6922 16.0673C11.5749 16.1846 11.4159 16.2505 11.25 16.2505C11.0841 16.2505 10.9251 16.1846 10.8078 16.0673C10.6905 15.95 10.6247 15.791 10.6247 15.6251C10.6247 15.4593 10.6905 15.3002 10.8078 15.1829L15.3664 10.6251H3.125C2.95924 10.6251 2.80027 10.5593 2.68306 10.442C2.56585 10.3248 2.5 10.1659 2.5 10.0001C2.5 9.83434 2.56585 9.67537 2.68306 9.55816C2.80027 9.44095 2.95924 9.3751 3.125 9.3751H15.3664L10.8078 4.81729C10.6905 4.70002 10.6247 4.54096 10.6247 4.3751C10.6247 4.20925 10.6905 4.05019 10.8078 3.93292C10.9251 3.81564 11.0841 3.74976 11.25 3.74976C11.4159 3.74976 11.5749 3.81564 11.6922 3.93292L17.3172 9.55792C17.3753 9.61596 17.4214 9.68489 17.4529 9.76077C17.4843 9.83664 17.5005 9.91797 17.5005 10.0001C17.5005 10.0822 17.4843 10.1636 17.4529 10.2394C17.4214 10.3153 17.3753 10.3842 17.3172 10.4423Z" fill="white"/>
                        </svg>
                    </button>
                </div>
            </div>
        </section>

        <section className='tw-py-[64px]'>
            <div className="container mx-auto">
                <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 md:tw-gap-x-[90px]">
                    <img src="/power-bi/advantage-image.png" alt="" className='tw-w-full tw-max-w-full md:tw-max-w-[526px]' />
                    <div>
                        <h2 className='mir-h3-semibold mir-text-primary-500 tw-mb-[24px]'>The advantages of Power BI</h2>
                        <p className='mir-text-neutral-800 mir-p-md-regular tw-mb-[36px]'>Power BI is designed specifically for organizations in the areas of manufacturing, distribution, retail trade and professional services.</p>
                        <ul className='tw-p-0'>
                            {
                                [
                                    'Quick and easy access to data ',
                                    '360º live view of the company',
                                    'Intuitive data exploration and modelling',
                                    'Insights from any device',
                                    'Collaboration across the entire organization ',
                                    'Data analysis for everyone in the company ',
                                ].map((item, index) => {
                                    return <li className='tw-flex tw-items-start tw-justify-start tw-mb-[24px]' key={`advantage-item-${index}`}>
                                        <svg className='tw-min-w-[24px] tw-mr-[16px]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="24" height="24" rx="12" fill="#D1E5FF"/>
                                            <path d="M18.6099 8.34847L10.1905 16.7678C10.1172 16.8414 10.03 16.8998 9.93408 16.9397C9.83814 16.9795 9.73528 17 9.63139 17C9.52751 17 9.42464 16.9795 9.3287 16.9397C9.23276 16.8998 9.14562 16.8414 9.07229 16.7678L5.38882 13.0844C5.31539 13.0109 5.25715 12.9238 5.21742 12.8279C5.17768 12.7319 5.15723 12.6291 5.15723 12.5253C5.15723 12.4214 5.17768 12.3186 5.21742 12.2227C5.25715 12.1268 5.31539 12.0396 5.38882 11.9662C5.46224 11.8927 5.5494 11.8345 5.64533 11.7948C5.74126 11.755 5.84408 11.7346 5.94792 11.7346C6.05175 11.7346 6.15457 11.755 6.2505 11.7948C6.34643 11.8345 6.43359 11.8927 6.50702 11.9662L9.63205 15.0912L17.493 7.23159C17.6413 7.0833 17.8424 7 18.0521 7C18.2618 7 18.4629 7.0833 18.6112 7.23159C18.7595 7.37987 18.8428 7.58098 18.8428 7.79069C18.8428 8.00039 18.7595 8.2015 18.6112 8.34978L18.6099 8.34847Z" fill="#1D68F0"/>
                                        </svg>
                                        <span>{item}</span>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section className='tw-py-[96px] mir-bg-neutral-100'>
            <div className="container mx-auto">
                <div className="text-center">
                    <h4 className='mir-p-overline-lg mir-text-primary-500 tw-mb-[16px]'>FEATURES</h4>
                    < h2 className='mir-h1-semibold mir-text-neutral-800 tw-mb-[80px]'>Features of Power BI</h2>
                </div>
                <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[64px]">
                    {
                        [
                            { img: '/power-bi/features/1.svg', heading: 'SaaS Content Packages', desc: 'Access pre-built dashboards and reports for popular SaaS solutions like Marketo, Salesforce, GitHub, ZenDesk, and more (over 120 connectors available)' },
                            { img: '/power-bi/features/2.svg', heading: 'Live Data Connection', desc: 'Establish a secure live connection to local data sources, gaining insights across your entire corporate information spectrum, including Analysis Services, Azure SQL Database, SQL Database Auditing, and Azure SQL Data Warehouse' },
                            { img: '/power-bi/features/3.svg', heading: 'Real-Time Live Dashboards', desc: 'Enjoy dashboards and visualizations that seamlessly update in real time' },
                            { img: '/power-bi/features/4.svg', heading: 'Customized Data Refresh', desc: 'Keep your Power BI data in sync with data sources through automated scheduled updates' },
                            { img: '/power-bi/features/5.svg', heading: 'Mobile Sharing & Collaboration', desc: 'Utilize native mobile apps for on-the-go access and collaboration.' },
                            { img: '/power-bi/features/6.svg', heading: 'Natural Language Query', desc: 'Explore data intuitively through text-based queries' },
                        ].map((item, index) => {
                            return <div key={`feature-item-${index}`}>
                                <img src={item.img} alt="feature-icon" className='tw-mb-[24px]' />
                                <h5 className='mir-h6-semibold mir-text-neutral-800 tw-mb-[12px]'>{item.heading}</h5>
                                <p className='mir-rg-regular mir-text-neutral-600'>{item.desc}</p>
                            </div>
                        })
                    }
                </div>
            </div>
        </section>

        <div data-section id='section-4' style={{ marginTop: '-24px' }}>
            <HowHelpSection 
                overline={'HOW WE HELP YOU'}
                heading={<>Why should you choose <span style={{ color: '#1D68F0' }}>MIR Digital Solutions</span> to get deeper data insights?</>}
                desc={<>MIR Digital Solutions has a proven track record of successful implementations and a strong commitment to Microsoft technologies, resulting in unrivaled product knowledge and process improvement expertise. Our experience have enabled us to develop efficient processes and approaches for cloud solutions, enabling innovation in a way other competitors simply cannot. </>}
                image={'url(/power-bi/help-bg.png) no-repeat'}
            />
        </div>

        <div className="delivering-wrapper py-5 mb-5">
            <div className="container">
                <h3 className='mir-h5-semibold mb-4'>As a Microsoft Partner, we deliver</h3>
                <div className="row">
                    {
                        [
                            'An end-to-end digital transformation platform that drives project-based operations from prospect to payment to profit',
                            'An extensive partner ecosystem and go-to-market channels, including AppSource Marketplace, partner-to partner network, and Microsoft sellers',
                            'Dynamics 365 extensibility to unite Finance, Human Resources, Sales, Supply Chain Management, Customer Service, Field Service, and more',
                            'Deep expertise and a rich history with CRM, ERP, HCM, collaboration, productivity, and project management applications',
                            'Complete cloud vision that encompasses Azure, Office 365, and Dynamics 365',
                            'Accelerated application development and flexibility with the Power Platform',
                        ].map((item) => {
                            return <div className="col-12 col-md-4">
                                <div className="deliver-item d-flex align-items-start justify-content-start">
                                    <img src="/project-operations/blue-check.svg" className='mt-3 me-3' alt="delivering-icon" />
                                    <h3 className='mir-p-rg-regular mt-3' style={{ maxWidth: '450px' }}>{item}</h3>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>

        <StepsSlider  
            subtitle={'Our specialist team offers custom-tailored implementation of Microsoft Power BI for advanced analytics, intelligent visualization and reporting.'}
        />

        <div className='mir-bg-primary-500 tw-relative tw-overflow-hidden'>
            <img src="/supply-chain/light-blue-banner-bg.png" alt="blue-banner-bg" className='tw-w-full tw-h-full tw-absolute tw-z-0 tw-inset-0' />
            <div className={`tw-py-[36px] md:tw-py-[72px] container mx-auto`}>
                <div className='tw-relative tw-z-10 tw-flex tw-justify-between tw-items-center tw-flex-wrap'>
                    <h3 className='tw-max-w-full md:tw-max-w-[672px] mir-h5-semibold text-left text-white'>Simplify your data analysis with our implementation of Microsoft Power BI</h3>
                    <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-secondary mir-btn-md mir-text-primary-500 mir-bg-white'>
                        <span className='tw-mr-[12px] mir-p-rg-medium'>Set up today</span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.3172 10.4422L11.6922 16.0672C11.5749 16.1844 11.4159 16.2503 11.25 16.2503C11.0841 16.2503 10.9251 16.1844 10.8078 16.0672C10.6905 15.9499 10.6247 15.7908 10.6247 15.625C10.6247 15.4591 10.6905 15.3001 10.8078 15.1828L15.3664 10.625H3.125C2.95924 10.625 2.80027 10.5591 2.68306 10.4419C2.56585 10.3247 2.5 10.1657 2.5 9.99998C2.5 9.83422 2.56585 9.67525 2.68306 9.55804C2.80027 9.44083 2.95924 9.37498 3.125 9.37498H15.3664L10.8078 4.81717C10.6905 4.69989 10.6247 4.54083 10.6247 4.37498C10.6247 4.20913 10.6905 4.05007 10.8078 3.93279C10.9251 3.81552 11.0841 3.74963 11.25 3.74963C11.4159 3.74963 11.5749 3.81552 11.6922 3.93279L17.3172 9.55779C17.3753 9.61584 17.4214 9.68477 17.4529 9.76064C17.4843 9.83652 17.5005 9.91785 17.5005 9.99998C17.5005 10.0821 17.4843 10.1634 17.4529 10.2393C17.4214 10.3152 17.3753 10.3841 17.3172 10.4422Z" fill="#1D68F0"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>

        <div data-section id='section-5'>
            <SuccessStoriesDynamic
                successStoriesContent={successStoriesContent}
            />
        </div>

        <TransformationSection
            heading={<>Empower your business with data and automation</>}
            desc={<>With our seasoned expert team, MIR can help you tailor and adapt any functionality to your business requirements.</>}
            items={[
                {
                    icon: '/transformation-icons/sharepoint.png',
                    heading: <>Microsoft SharePoint</>,
                    link: <Link><span className='mir-p-sm-medium me-2'>Learn More</span>
                    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0"/>
                    </svg></Link>
                },
                {
                    icon: '/transformation-icons/365-business.png',
                    heading: <>Microsoft 365  for <br />Business</>,
                    link: <Link to={MIRRoutes.BusinessCentral}><span className='mir-p-sm-medium me-2'>Learn More</span>
                    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0"/>
                    </svg></Link>
                },
                {
                    icon: '/transformation-icons/power-apps.svg',
                    heading: <>Microsoft <br /> PowerApps</>,
                    link: <Link to={MIRRoutes.PowerApps}><span className='mir-p-sm-medium me-2'>Learn More</span>
                    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0"/>
                    </svg></Link>
                },
            ]}
        />

        <div data-section id='section-6'>
            <ContactSection />
        </div>

        <Footer />

    </main>
  )
}
