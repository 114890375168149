import React, { useEffect, useRef, useState } from 'react'
import StickyBanner from '../../StickyBanner/StickyBanner'
import Footer from '../../Footer/Footer'
import './ProjectOperations.scss'
import StepsSlider from '../Elements/StepProcess/StepProcess';
import QuestionSlider from '../Elements/QuestionSlider';
import SuccessStories from '../../CommonElements/SuccessStories';
import HeroSection from '../Elements/Sections/HeroSection/HeroSection';
import SectionTabs from '../Elements/Sections/SectionTabs/SectionTabs';
import OverviewSection from '../Elements/Sections/OverviewSection';
import Challenges from '../Elements/Sections/Challenges';
import Dynamics from '../Elements/Sections/Dynamics';
import BenefitsSection from '../Elements/Sections/Benefits';
import BlueBanner from '../Elements/Sections/BlueBanner';
import HowHelpSection from '../Elements/Sections/HowHelpSection';
import BlueFluidBanner from '../Elements/Sections/BlueFluidBanner';
import TransformationSection from '../Elements/Sections/TransformationSection';
import ContactSection from '../Elements/Sections/ContactSection';
import BookNowModal from '../../CommonElements/BookNowModal';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import MIRRoutes from '../../../lib/routes';
import SuccessStoriesDynamic from '../../CommonElements/SuccessStoriesDynamic';

export default function ProjectOperations() {
    const ytVideoLink = 'https://youtu.be/nhU21sYCaiI';
    const [showVideo, setShowVideo] = useState(false);
    const [tabs, setTabs] = useState([
        { name: 'Overview', id: 'section-1' },
        { name: 'Introducing Project Operations', id: 'section-2' },
        { name: 'Benefits', id: 'section-3' },
        { name: 'How we help you', id: 'section-4' },
        { name: 'Case Studies', id: 'section-5' },
        { name: 'Contact Us', id: 'section-6' },
    ]);

    const [activeSection, setActiveSection] = useState('Overview');
    const [showRegionPopup, setShowRegionPopup] = useState(false);
    const sections = useRef([]);

    const questions = [
        'How is the continuity between initial price quotes and project delivery maintained?',
        'How are sales contacts harmonized with invoicing specifics?',
        'What strategies guide the selection of suitable talent for a project?',
        'What is the track record of on-time and on-budget project completions?'
    ];

    const successStoriesContent = [
        {
            image: "/success-stories-custom/4.png",
            timeToRead: "3",
            desc: "National Telecom Giant Achieves 65% Time Savings Through Automated Infrastructure Upgrade",
            linkToRead: "/about-us/case-studies/national-telecom-giant-automated-infrastructure-upgrade"
        },
        {
            image: "/success-stories-custom/5.png",
            timeToRead: "10",
            desc: "Construction & Mining Giant Achieves Procurement Automation & Vendor Management Excellence",
            linkToRead: "/about-us/case-studies/dynamics-365-procurement-automation"
        },
        {
            image: "/success-stories-custom/2.png",
            timeToRead: "5",
            desc: "A&A Pharmachem Inc Drives Operational Efficiencies With D365 Business Central and PowerBI",
            linkToRead: "/about-us/case-studies/aanda-pharmachem-business-central-power-bi"
        }
    ];

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Microsoft Dynamics 365 Project Operations Solution | MIR Digital Solutions</title>
                <meta name="description" content="Enhance project task execution, boost profitability with Microsoft Dynamics 365 Project Operations. Contact MIR to lead a profitable project-centric business!" />
                <link rel="canonical" href={`${window.location.origin}/cloud-solutions/dynamics-365-project-operations-solution`} />
            </Helmet>
            <main style={{ position: 'relative' }} className='project-operations-main-wrapper'>
                <StickyBanner />

                <HeroSection
                    icon={'/project-operations/hero-icon.png'}
                    overline={'Maximize productivity and deliver projects on time'}
                    heading={<span>Microsoft Dynamics 365 Project Operations</span>}
                />

                {/* section tabs */}
                <SectionTabs tabs={tabs} />

                {/* overview */}
                <div data-section id='section-1' className='!tw-p-0'>
                    <OverviewSection
                        overline={'OVERVIEW'}
                        bg={'url(/supply-chain/overview-bg.png)'}
                        heading={'Streamline processes, boost efficiency, and achieve business agility'}
                        desc={<>As the transition towards a project-focused economy gains momentum, businesses across industries encounter their share of obstacles.&nbsp;
                            <a target='_blank' href="https://hbr.org/2021/11/the-project-economy-has-arrived">A substantial number of projects struggle to generate profits, with only 35 percent achieving success worldwide, as reported by the Harvard Business Review. </a>
                            The root causes of this significant deficit is traced back to factors, including insufficient tools, disconnected systems, and limited transparency.</>}
                    />
                </div>

                <QuestionSlider questions={questions} />

                {/* challenges */}
                <Challenges
                    overline={'CHALLENGES'}
                    heading={<>Challenges hampering modern <br /> project-focused organizations</>}
                    challenges={[
                        'Inaccurate project estimates and scoping',
                        'Disconnected customer experiences with unclear outcomes',
                        'Fragmented information across sales, contracts, project management, time tracking, and billing',
                        'Visibility gaps during the shift from sales to project delivery',
                        'Inconsistent processes and communication in globally spread project teams',
                        'Underutilization of resources'
                    ]}
                    sideImage={'/project-operations/challenges-bg.png'}
                />

                {/* dynamics */}
                <div data-section id={'section-2'}>
                    <Dynamics
                        icon={'/project-operations/hero-icon.png'}
                        overline={'Introducing Microsoft dynamics 365 project operations'}
                        heading={<>What is Microsoft Dynamics 365 <br /> Project Operations? </>}
                        desc={<>Microsoft Dynamics 365 Project Operations facilitates seamless connections among sales, resourcing, project, and accounting teams, empowering organizations to secure greater deals, expedite project completion, and optimize profitability. <br /><br />  Microsoft Dynamics 365 Project Operations seamlessly integrates with Dynamics 365 Finance and Dynamics 365 Human Resources, enabling the alignment of a customer's accounting, billing, invoicing, and HR processes, thereby enhancing project task execution. </>}
                        videoPlaceholder={"/supply-chain/video-placeholder.png"}
                        iframe={<iframe width="100%" height="541px" src="https://www.youtube.com/embed/nhU21sYCaiI?si=9M6TIwz8selS35q4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>}
                    />
                </div>

                <div data-section id='section-3'>
                    <BenefitsSection
                        overline={'BENEFITS'}
                        heading={<>Why should you choose <span style={{ color: '#1D68F0' }}>Microsoft <br /> Dynamics 365 Project Operations</span> to <br /> execute project-based work?</>}
                        desc={<>Powered by the versatile Power Platform, Microsoft Dynamics 365 Project Operations seamlessly integrates with existing systems, tailoring itself to specific organizational needs. This application brings diverse teams together under a single platform, transforming the way businesses manage sales, planning, resource allocation, execution, and invoicing for services and projects.</>}
                        items={[
                            {
                                icon: <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_5691_40611)">
                                        <path d="M29.6877 19H25.3123C24.5879 19 24 18.5296 24 17.9499V13.0501C24 12.4704 24.5879 12 25.3123 12H29.6877C30.4121 12 31 12.4704 31 13.0501V17.9499C31 18.5296 30.4121 19 29.6877 19Z" fill="#8EB8F7" />
                                        <path d="M29.6877 31H25.3123C24.5879 31 24 30.3951 24 29.6499V23.3501C24 22.6049 24.5879 22 25.3123 22H29.6877C30.4121 22 31 22.6049 31 23.3501V29.6499C31 30.3951 30.4121 31 29.6877 31Z" fill="#8EB8F7" />
                                        <path d="M18 7H21V34H18V7Z" fill="#1D68F0" />
                                        <path d="M13.364 16.4532H7.18191C6.32657 16.4532 5.6358 15.7601 5.6358 14.9071C5.6358 14.054 6.32889 13.3633 7.18191 13.3633H13.364C14.2194 13.3633 14.9101 14.0564 14.9101 14.9094C14.9101 15.7624 14.2171 16.4532 13.364 16.4532Z" fill="#8EB8F7" />
                                        <path d="M13.364 22.6354H7.18191C6.32657 22.6354 5.6358 21.9423 5.6358 21.0893C5.6358 20.2363 6.32657 19.5432 7.18191 19.5432H13.364C14.2194 19.5432 14.9101 20.2363 14.9101 21.0893C14.9101 21.9423 14.2171 22.6354 13.364 22.6354Z" fill="#8EB8F7" />
                                        <path d="M13.364 28.8171H7.18191C6.32657 28.8171 5.6358 28.124 5.6358 27.271C5.6358 26.4179 6.32657 25.7249 7.18191 25.7249H13.364C14.2194 25.7249 14.9101 26.4179 14.9101 27.271C14.9101 28.124 14.2171 28.8171 13.364 28.8171Z" fill="#8EB8F7" />
                                        <path d="M31.5 1H4.5C2.01825 1 0 3.07936 0 5.63626V30.3637C0 32.9206 2.01825 35 4.5 35H31.5C33.9818 35 36 32.9206 36 30.3637V5.63626C36 3.07936 33.9818 1 31.5 1ZM31.5 31.9076H4.5C3.672 31.9076 2.99925 31.2145 2.99925 30.3614V8.72632H33.0007V30.3614C33.0007 31.2145 32.328 31.9076 31.5 31.9076Z" fill="#1D68F0" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5691_40611">
                                            <rect width="36" height="36" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>,
                                heading: 'Unified project teams', desc: 'Information consolidation from sales, time tracking, task management, and billing within a single app.'
                            },
                            {
                                icon: <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_5691_40599)">
                                        <path d="M6.42039 23.5732C7.41712 24.4641 8.54435 25.1706 9.75708 25.7016L16.1222 21.7057C17.4385 20.8754 19.2024 21.0712 20.3049 22.1804L22.4469 24.3224C25.6553 21.9532 27.6668 18.1687 27.6668 14.0626C27.6668 7.03142 21.9474 1.31201 14.9163 1.31201C7.88511 1.31201 2.16795 7.03142 2.16795 14.0626C2.16795 17.6918 3.71593 21.1589 6.42039 23.5732ZM15.4877 15.1875H14.347C12.5943 15.1875 11.1678 13.7611 11.1678 12.0083C11.1678 10.4311 12.304 9.13514 13.7935 8.86964V7.68841C13.7935 7.06742 14.2975 6.56343 14.9185 6.56343C15.5395 6.56343 16.0435 7.06742 16.0435 7.68841V8.81339H17.542C18.1629 8.81339 18.6669 9.31738 18.6669 9.93837C18.6669 10.5594 18.1629 11.0634 17.542 11.0634H14.347C13.834 11.0634 13.4178 11.4818 13.4178 11.9926C13.4178 12.5191 13.8363 12.9376 14.347 12.9376H15.4877C17.2405 12.9376 18.6669 14.3641 18.6669 16.1168C18.6669 17.694 17.5307 18.99 16.0412 19.2555V20.4367C16.0412 21.0577 15.5372 21.5617 14.9163 21.5617C14.2953 21.5617 13.7913 21.0577 13.7913 20.4367V19.3117H12.2905C11.6696 19.3117 11.1656 18.8077 11.1656 18.1867C11.1656 17.5658 11.6696 17.0618 12.2905 17.0618H15.4855C15.9985 17.0618 16.4147 16.6433 16.4147 16.1325C16.417 15.606 15.9985 15.1875 15.4877 15.1875Z" fill="#1D68F0" />
                                        <path d="M2.16795 35.813C1.66621 35.813 1.17572 35.561 0.88997 35.102C0.455727 34.3978 0.671723 33.473 1.37596 33.0366L17.1257 23.2875C17.7152 22.923 18.4847 23.0085 18.9752 23.5012L23.9139 28.4399L32.6032 19.7505C33.1882 19.1655 34.1377 19.1655 34.7249 19.7505C35.3099 20.3355 35.3099 21.285 34.7249 21.8722L24.9758 31.6213C24.3909 32.2063 23.4414 32.2063 22.8541 31.6213L17.6927 26.4622L2.95319 35.5858C2.71019 35.7388 2.43795 35.813 2.16795 35.813Z" fill="#8EB8F7" />
                                        <path d="M35.1659 26.8131C34.7767 26.8131 34.3919 26.6601 34.1062 26.3743L28.1055 20.3737C27.6758 19.9439 27.5475 19.3005 27.7793 18.738C28.011 18.1777 28.5578 17.8132 29.1653 17.8132H35.1659C35.9939 17.8132 36.6666 18.486 36.6666 19.314V25.3124C36.6666 25.9199 36.3021 26.4666 35.7419 26.6983C35.5552 26.7748 35.3594 26.8131 35.1659 26.8131Z" fill="#8EB8F7" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5691_40599">
                                            <rect width="36" height="36" fill="white" transform="translate(0.666656)" />
                                        </clipPath>
                                    </defs>
                                </svg>,
                                heading: 'Streamlined project financials', desc: 'Efficiency enhancement in accounting through project cost and revenue parameters.'
                            },
                            {
                                icon: <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_5691_40580)">
                                        <path d="M12.3326 13.5C10.6789 13.5 9.33337 12.1545 9.33337 10.5007C9.33337 8.84697 10.6789 7.50146 12.3326 7.50146C13.9864 7.50146 15.3319 8.84697 15.3319 10.5007C15.3319 12.1545 13.9886 13.5 12.3326 13.5Z" fill="#8EB8F7" />
                                        <path d="M21.3326 22.5C19.6789 22.5 18.3334 21.1545 18.3334 19.5007C18.3334 17.847 19.6789 16.5015 21.3326 16.5015C22.9864 16.5015 24.3319 17.847 24.3319 19.5007C24.3319 21.1545 22.9886 22.5 21.3326 22.5Z" fill="#8EB8F7" />
                                        <path d="M12.3326 22.5C12.0198 22.5 11.7048 22.4033 11.4348 22.2008C10.7711 21.7035 10.6383 20.763 11.1356 20.1015L20.1356 8.10226C20.6328 7.44076 21.5711 7.30576 22.2348 7.80301C22.8986 8.30026 23.0313 9.24076 22.5341 9.90226L13.5341 21.9015C13.2393 22.293 12.7871 22.5 12.3326 22.5Z" fill="#8EB8F7" />
                                        <path d="M0.333374 27.0473V31.4921C0.333374 33.9782 2.35162 36 4.83337 36H23.5826C26.4761 35.9977 28.8319 33.6401 28.8319 30.7415V9.01578H34.8326C35.6606 9.01578 36.3334 8.34185 36.3334 7.5124V5.25845C36.3334 2.88054 34.7381 0.888055 32.5691 0.241173C32.3486 0.101428 32.1079 0 31.8334 0H7.83262C5.35087 0 3.33262 2.02179 3.33262 4.50789V25.544H1.83412C1.00387 25.544 0.333374 26.2179 0.333374 27.0473ZM19.8341 25.544H6.33412V4.50789C6.33412 3.67844 7.00687 3.00451 7.83487 3.00451H26.3614C26.0329 3.68971 25.8349 4.44929 25.8349 5.25845V7.5124V30.7393C25.8349 31.9812 24.8246 32.9932 23.5849 32.9932C22.3451 32.9932 21.3349 31.9812 21.3349 30.7393V27.0451C21.3326 26.2179 20.6621 25.544 19.8341 25.544Z" fill="#1D68F0" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5691_40580">
                                            <rect width="36" height="36" fill="white" transform="translate(0.333374)" />
                                        </clipPath>
                                    </defs>
                                </svg>,
                                heading: 'Exceptional deal management', desc: 'Improved workflows for pricing, estimates, forecasting, and invoicing, facilitating smooth sales-to-delivery transitions. '
                            },
                            {
                                icon: <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_5691_40622)">
                                        <path d="M17.9959 35.7691C16.3634 35.7691 15.0352 34.4409 15.0352 32.8084C15.0352 31.1759 16.3634 29.8477 17.9959 29.8477C19.6284 29.8477 20.9566 31.1759 20.9566 32.8084C20.9566 34.4409 19.6284 35.7691 17.9959 35.7691Z" fill="#8EB8F7" />
                                        <path d="M17.9973 31.6973C17.3842 31.6973 16.8867 31.1998 16.8867 30.5867V16.1496C16.8867 15.5366 17.3842 15.0391 17.9973 15.0391C18.6103 15.0391 19.1078 15.5366 19.1078 16.1496V30.5867C19.1078 31.1998 18.6103 31.6973 17.9973 31.6973Z" fill="#8EB8F7" />
                                        <path d="M28.3651 31.6976C27.752 31.6976 27.2545 31.2 27.2545 30.587V26.5802L22.739 21.7182C22.548 21.5116 22.4414 21.2429 22.4414 20.9608V16.1477C22.4414 15.5346 22.9389 15.0371 23.552 15.0371C24.165 15.0371 24.6625 15.5346 24.6625 16.1477V20.5232L29.178 25.3852C29.369 25.5895 29.4734 25.8605 29.4734 26.1404V30.5826C29.4756 31.2 28.9781 31.6976 28.3651 31.6976Z" fill="#8EB8F7" />
                                        <path d="M28.3631 35.7691C26.7306 35.7691 25.4023 34.4409 25.4023 32.8084C25.4023 31.1759 26.7306 29.8477 28.3631 29.8477C29.9956 29.8477 31.3238 31.1759 31.3238 32.8084C31.3238 34.4409 29.9956 35.7691 28.3631 35.7691Z" fill="#8EB8F7" />
                                        <path d="M7.63008 31.6973C7.01706 31.6973 6.51953 31.1998 6.51953 30.5867V26.1446C6.51953 25.8647 6.62614 25.5937 6.81494 25.3894L11.3326 20.5252V16.1496C11.3326 15.5366 11.8302 15.0391 12.4432 15.0391C13.0562 15.0391 13.5537 15.5366 13.5537 16.1496V20.9627C13.5537 21.2426 13.4471 21.5136 13.2583 21.7179L8.74285 26.5799V30.5867C8.74063 31.1998 8.2431 31.6973 7.63008 31.6973Z" fill="#8EB8F7" />
                                        <path d="M7.6326 35.7691C6.00009 35.7691 4.67188 34.4409 4.67188 32.8084C4.67188 31.1759 6.00009 29.8477 7.6326 29.8477C9.2651 29.8477 10.5933 31.1759 10.5933 32.8084C10.5933 34.4409 9.26733 35.7691 7.6326 35.7691Z" fill="#8EB8F7" />
                                        <path d="M27.9031 5.87525C25.9064 2.42145 22.1083 0.231445 17.9992 0.231445C12.7841 0.231445 8.24641 3.75411 6.97372 8.60054C3.16676 9.13138 0.230469 12.3475 0.230469 16.2233C0.230469 19.3196 2.11618 21.9782 4.81037 23.1998L7.00482 20.8366C4.82592 20.359 3.19119 18.4755 3.19119 16.2233C3.19119 13.6113 5.38564 11.4857 8.19755 11.4857C8.93273 11.4857 9.55686 10.946 9.66347 10.2175C10.2432 6.21286 13.8258 3.19439 17.9992 3.19439C21.2665 3.19439 24.2605 5.04456 25.6287 7.90756C25.8619 8.3962 26.3439 8.71826 26.8836 8.74713C30.2042 8.92704 32.8051 11.6035 32.8051 14.8418C32.8051 17.4538 31.1015 19.6705 28.7227 20.5478L30.8594 22.8489C33.7779 21.3185 35.768 18.3045 35.768 14.8418C35.768 10.3152 32.3764 6.52826 27.9031 5.87525Z" fill="#1D68F0" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5691_40622">
                                            <rect width="36" height="36" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>,
                                heading: 'Collaborative project tools', desc: 'Integration with Microsoft Teams for cohesive engagement among global and diverse project delivery teams. '
                            },
                            {
                                icon: <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_5363_43700)">
                                        <path d="M36.1226 0.518359C29.2884 0.402526 21.5079 3.97587 16.5817 9.51837C11.8741 9.60579 7.2845 11.5312 3.90567 14.9144C3.71553 15.1046 3.64341 15.3953 3.73083 15.6575C3.81825 15.9198 4.0368 16.1099 4.31437 16.1536L9.93992 16.9557L9.24055 17.7425C8.97829 18.0201 8.99359 18.4725 9.26896 18.7348L15.7381 25.2105C16.1752 19.1566 21.2173 14.3724 27.3652 14.3724C29.1573 14.3724 30.862 14.7811 32.3788 15.5111C35.2484 11.1357 36.94 5.95596 36.8372 1.23084C36.8219 0.839631 36.516 0.533658 36.1226 0.518359ZM29.4043 13.1223C28.6896 13.8217 27.7585 14.1867 26.8253 14.1867C25.8921 14.1867 24.9611 13.8217 24.2464 13.1223C22.8324 11.693 22.8324 9.37413 24.2464 7.9579C25.6736 6.52857 27.9902 6.52857 29.4043 7.9579C30.8183 9.37194 30.8183 11.693 29.4043 13.1223Z" fill="#1D68F0" />
                                        <path d="M4.42059 25.1449C2.86012 26.7032 0.72049 33.7493 0.482267 34.5471C0.405773 34.8049 0.47571 35.0825 0.665851 35.2705C0.803539 35.4103 0.989309 35.4847 1.17945 35.4847C1.24939 35.4847 1.31932 35.4737 1.38926 35.4541C2.18698 35.2158 9.23094 33.074 10.7914 31.5157C12.5486 29.7586 12.5486 26.9021 10.7914 25.1471C9.03206 23.3877 6.17557 23.3899 4.42059 25.1449Z" fill="#8EB8F7" />
                                        <path d="M27.406 16.5996C22.1847 16.5996 17.9361 20.8352 17.9361 26.0433C17.9361 31.2514 22.1847 35.4848 27.406 35.4848C32.6272 35.4848 36.8759 31.2492 36.8759 26.0411C36.8759 20.833 32.6272 16.5996 27.406 16.5996ZM31.4863 24.2402L26.7503 29.3259C26.5361 29.5554 26.2433 29.6734 25.9482 29.6734C25.6969 29.6734 25.4433 29.586 25.2357 29.4112L22.6852 27.2322C22.2262 26.841 22.1738 26.1526 22.5672 25.6958C22.9606 25.239 23.649 25.1866 24.108 25.5778L25.8586 27.0749L29.88 22.7584C30.293 22.317 30.9837 22.2929 31.4251 22.7016C31.8688 23.1103 31.895 23.8009 31.4863 24.2402Z" fill="#8EB8F7" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5363_43700">
                                            <rect width="36" height="36" fill="white" transform="translate(0.666656)" />
                                        </clipPath>
                                    </defs>
                                </svg>,
                                heading: 'Optimized resource utilization', desc: 'Comprehensive resource management in one connected solution.'
                            },
                            {
                                icon: <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_5691_40588)">
                                        <path d="M15.8697 30.3747H12.8706C12.2496 30.3747 11.7456 29.8707 11.7456 29.2497C11.7456 28.6287 12.2496 28.1248 12.8706 28.1248H15.8697C16.4907 28.1248 16.9947 28.6287 16.9947 29.2497C16.9947 29.8707 16.4907 30.3747 15.8697 30.3747Z" fill="#8EB8F7" />
                                        <path d="M8.95124 28.8133C8.553 28.8133 8.16602 28.6018 7.96353 28.2283C7.63504 27.6231 7.45955 26.9414 7.45955 26.2506V24.75C7.45955 24.129 7.96353 23.625 8.5845 23.625C9.20548 23.625 9.70946 24.129 9.70946 24.75V26.2506C9.70946 26.5679 9.7882 26.8716 9.93895 27.1484C10.2359 27.6951 10.0357 28.3768 9.49122 28.6738C9.31797 28.7683 9.13348 28.8133 8.95124 28.8133Z" fill="#8EB8F7" />
                                        <path d="M8.5845 13.876C7.96353 13.876 7.45955 13.372 7.45955 12.751V9.75191C7.45955 9.13093 7.96353 8.62695 8.5845 8.62695C9.20548 8.62695 9.70946 9.13093 9.70946 9.75191V12.751C9.70946 13.372 9.20548 13.876 8.5845 13.876Z" fill="#8EB8F7" />
                                        <path d="M10.083 17.624H9.70726V17.2482C9.70726 16.6273 9.20328 16.1233 8.58231 16.1233C7.96133 16.1233 7.45735 16.6273 7.45735 17.2482V20.2496C7.45735 20.8706 7.96133 21.3746 8.58231 21.3746C9.20328 21.3746 9.70726 20.8706 9.70726 20.2496V19.8739H10.083C10.704 19.8739 11.2079 19.3699 11.2079 18.7489C11.2079 18.128 10.704 17.624 10.083 17.624Z" fill="#8EB8F7" />
                                        <path d="M16.0835 19.8742H14.5828C13.9618 19.8742 13.4578 19.3702 13.4578 18.7492C13.4578 18.1282 13.9618 17.6243 14.5828 17.6243H16.0835C16.7045 17.6243 17.2084 18.1282 17.2084 18.7492C17.2084 19.3702 16.7045 19.8742 16.0835 19.8742Z" fill="#8EB8F7" />
                                        <path d="M17.5842 2.99976H4.0847C2.01479 2.99976 0.334106 4.68269 0.334106 6.75035C0.334106 8.81802 2.01479 10.5009 4.0847 10.5009H17.5842C19.6518 10.5009 21.3348 8.81802 21.3348 6.75035C21.3348 4.68269 19.6518 2.99976 17.5842 2.99976ZM4.83392 8.25104C4.00596 8.25104 3.33323 7.57832 3.33323 6.75035C3.33323 5.92239 4.00371 5.24966 4.83392 5.24966C5.66189 5.24966 6.33461 5.92014 6.33461 6.75035C6.33461 7.57832 5.66189 8.25104 4.83392 8.25104ZM10.8344 8.25104C10.0042 8.25104 9.33374 7.57832 9.33374 6.75035C9.33374 5.92239 10.0042 5.24966 10.8344 5.24966C11.6624 5.24966 12.3351 5.92014 12.3351 6.75035C12.3329 7.57832 11.6624 8.25104 10.8344 8.25104Z" fill="#8EB8F7" />
                                        <path d="M32.5829 25H19.0839C17.0163 25 15.3334 26.7948 15.3334 29C15.3334 31.2052 17.014 33 19.0839 33H32.5829C34.6527 33 36.3334 31.2052 36.3334 29C36.3334 26.7948 34.6527 25 32.5829 25ZM19.8353 30.6005C19.0051 30.6005 18.3347 29.883 18.3347 29C18.3347 28.117 19.0051 27.3995 19.8353 27.3995C20.6655 27.3995 21.3359 28.1146 21.3359 29C21.3337 29.883 20.6632 30.6005 19.8353 30.6005ZM25.8334 30.6005C25.0054 30.6005 24.3327 29.883 24.3327 29C24.3327 28.117 25.0032 27.3995 25.8334 27.3995C26.6636 27.3995 27.334 28.117 27.334 29C27.334 29.883 26.6636 30.6005 25.8334 30.6005Z" fill="#1D68F0" />
                                        <path d="M32.5829 15H19.0839C17.0163 15 15.3334 16.7948 15.3334 19C15.3334 21.2052 17.014 23 19.0839 23H32.5829C34.6527 22.9976 36.3334 21.2028 36.3334 18.9976C36.3334 16.7924 34.6527 15 32.5829 15ZM19.8353 20.5981C19.0051 20.5981 18.3347 19.883 18.3347 18.9976C18.3347 18.1146 19.0051 17.3971 19.8353 17.3971C20.6655 17.3971 21.3359 18.1122 21.3359 18.9976C21.3337 19.883 20.6632 20.5981 19.8353 20.5981ZM25.8334 20.5981C25.0054 20.5981 24.3327 19.8806 24.3327 18.9976C24.3327 18.1146 25.0032 17.3971 25.8334 17.3971C26.6613 17.3971 27.334 18.1122 27.334 18.9976C27.334 19.883 26.6636 20.5981 25.8334 20.5981Z" fill="#1D68F0" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5691_40588">
                                            <rect width="36" height="36" fill="white" transform="translate(0.333374)" />
                                        </clipPath>
                                    </defs>
                                </svg>,
                                heading: 'Data-driven decision making', desc: 'Enhanced visibility and actionable insights for increased business agility via streamlined operational workflows.'
                            }
                        ]}
                    />
                </div>

                <div className="mb-5" style={{ marginTop: '-96px' }}>
                    <BlueBanner heading={<>Connect today to lead a profitable project-centric business</>} />
                </div>

                <div data-section id='section-4' style={{ marginTop: '-24px' }}>
                    <HowHelpSection
                        overline={'HOW WE HELP YOU'}
                        heading={<>Why should you choose <span style={{ color: '#1D68F0' }}>MIR Digital Solutions</span> to help streamline your business?</>}
                        desc={<>MIR Digital Solutions has a proven track record of successful implementations and a strong commitment to Microsoft technologies, resulting in unrivaled product knowledge and process improvement expertise. <br /><br />
                            Our experience and expertise have enabled us to develop efficient processes and approaches for cloud solutions, enabling innovation in a way other competitors simply cannot.</>}
                        image={'url(/project-operations/help-bg.png) no-repeat'}
                    />
                </div>

                <div className="delivering-wrapper py-5 mb-5">
                    <div className="container">
                        <h3 className='mir-h5-semibold mb-4'>As a Microsoft Partner, we deliver</h3>
                        <div className="row">
                            {
                                [
                                    'Deep expertise and a rich history with CRM, ERP, HCM, collaboration, productivity, and project management applications',
                                    'An end-to-end digital transformation platform that drives project-based operations from prospect to payment to profit',
                                    'An extensive partner ecosystem and go-to-market channels, including AppSource Marketplace, partner-to partner network, and Microsoft sellers',
                                    'Complete cloud vision that encompasses Azure, Office 365, and Dynamics 365',
                                    'Dynamics 365 extensibility to unite Finance, Human Resources, Sales, Supply Chain Management, Customer Service, Field Service, and more',
                                    'Accelerated application development and flexibility with the Power Platform'
                                ].map((item) => {
                                    return <div className="col-12 col-md-4">
                                        <div className="deliver-item d-flex align-items-start justify-content-start">
                                            <img src="/project-operations/blue-check.svg" className='mt-3 me-3' alt="delivering-icon" />
                                            <p className='mir-p-rg-regular mt-3' style={{ maxWidth: '450px' }}>{item}</p>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>

                <StepsSlider
                    subtitle={'Our specialist team offers custom-tailored implementation of Microsoft Power Apps for crafting personalized applications, bypassing intricate coding.'}
                />

                <section className="process-slider-features" style={{ background: '#FAFAFC', padding: '96px 0' }}>
                    <div className="container">
                        <div className="row features">
                            <div className="col-12 col-md-12 col-lg-4">
                                <div className="feature-item">
                                    <img src="/supply-chain/setting.svg" alt="feature-item-icon" className='mb-4' />
                                    <div style={{ maxWidth: '90%' }}>
                                        <h4 className='mir-h6-semibold'>An end-to-end implementation</h4>
                                        <p className='mir-p-rg-regular'>We provide full-length implementation framework, including assessment, strategy development, systems integration, data migration and training.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-4">
                                <div className="feature-item">
                                    <img src="/supply-chain/monitor.svg" alt="feature-item-icon" className='mb-4' />
                                    <div style={{ maxWidth: '90%' }}>
                                        <h4 className='mir-h6-semibold'>Industry-focused and technology <br /> best practices</h4>
                                        <p className='mir-p-rg-regular'>Our approach involves understanding specific needs of your organization's industry that can help solve the problem.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-4">
                                <div className="feature-item">
                                    <img src="/supply-chain/service.svg" alt="feature-item-icon" className='mb-4' />
                                    <div style={{ maxWidth: '90%' }}>
                                        <h4 className='mir-h6-semibold'>A dedicated 24x7 office support team</h4>
                                        <p className='mir-p-rg-regular'>In the Go-Live phase, our QA specialists will conduct in-depth training sessions for all users. We also provide 24x7 office support for post-implementation issues.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <BlueFluidBanner
                    button={<><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.25 2.5H14.375V1.875C14.375 1.70924 14.3092 1.55027 14.1919 1.43306C14.0747 1.31585 13.9158 1.25 13.75 1.25C13.5842 1.25 13.4253 1.31585 13.3081 1.43306C13.1908 1.55027 13.125 1.70924 13.125 1.875V2.5H6.875V1.875C6.875 1.70924 6.80915 1.55027 6.69194 1.43306C6.57473 1.31585 6.41576 1.25 6.25 1.25C6.08424 1.25 5.92527 1.31585 5.80806 1.43306C5.69085 1.55027 5.625 1.70924 5.625 1.875V2.5H3.75C3.41848 2.5 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41848 2.5 3.75V16.25C2.5 16.5815 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41848 17.5 3.75 17.5H16.25C16.5815 17.5 16.8995 17.3683 17.1339 17.1339C17.3683 16.8995 17.5 16.5815 17.5 16.25V3.75C17.5 3.41848 17.3683 3.10054 17.1339 2.86612C16.8995 2.6317 16.5815 2.5 16.25 2.5ZM5.625 3.75V4.375C5.625 4.54076 5.69085 4.69973 5.80806 4.81694C5.92527 4.93415 6.08424 5 6.25 5C6.41576 5 6.57473 4.93415 6.69194 4.81694C6.80915 4.69973 6.875 4.54076 6.875 4.375V3.75H13.125V4.375C13.125 4.54076 13.1908 4.69973 13.3081 4.81694C13.4253 4.93415 13.5842 5 13.75 5C13.9158 5 14.0747 4.93415 14.1919 4.81694C14.3092 4.69973 14.375 4.54076 14.375 4.375V3.75H16.25V6.25H3.75V3.75H5.625ZM16.25 16.25H3.75V7.5H16.25V16.25Z" fill="#1D68F0" />
                    </svg>
                        <span>Book a meeting</span></>}
                    title={<>Win contracts, optimize resources, and accelerate project delivery with powerful insights</>}
                />

                <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />

                <section className='get-in-touch-wrapper'>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-6">
                                <div className="left-content">
                                    <h2 className='mir-h3-semibold' style={{ maxWidth: '550px' }}>
                                        <span style={{ color: "#1D68F0" }}>Get in touch</span>
                                        &nbsp;to transform the way your organization operates with Microsoft Dynamics 365 Project Operations
                                    </h2>
                                    <button onClick={(e) => { setShowRegionPopup(true) }}
                                        className='mir-btn-lg mir-bg-primary-500 text-white' style={{ marginTop: '48px', marginBottom: '48px' }}>
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.3727 12.8797L13.6922 11.2304L13.682 11.2257C13.491 11.144 13.2825 11.1112 13.0756 11.1303C12.8687 11.1494 12.6698 11.2198 12.4969 11.3351C12.4765 11.3486 12.457 11.3632 12.4383 11.3789L10.5367 13C9.33204 12.4148 8.08829 11.1804 7.50313 9.99137L9.12657 8.0609C9.14219 8.04137 9.15704 8.02184 9.1711 8.00075C9.28394 7.82833 9.35239 7.63068 9.37039 7.42541C9.38838 7.22014 9.35534 7.0136 9.27423 6.82418V6.81481L7.62032 3.12809C7.51309 2.88064 7.3287 2.67451 7.09468 2.54046C6.86067 2.40642 6.58958 2.35165 6.32188 2.38434C5.26326 2.52364 4.29155 3.04353 3.58824 3.84691C2.88492 4.65029 2.49809 5.68222 2.50001 6.74996C2.50001 12.9531 7.54688 18 13.75 18C14.8177 18.0019 15.8497 17.6151 16.6531 16.9117C17.4564 16.2084 17.9763 15.2367 18.1156 14.1781C18.1484 13.9105 18.0937 13.6395 17.9598 13.4055C17.8259 13.1715 17.62 12.987 17.3727 12.8797ZM13.75 16.75C11.0987 16.7471 8.55687 15.6926 6.68214 13.8178C4.8074 11.9431 3.7529 9.40124 3.75001 6.74996C3.74707 5.98706 4.02192 5.24918 4.52324 4.67411C5.02456 4.09904 5.71806 3.72611 6.47423 3.62496C6.47392 3.62808 6.47392 3.63122 6.47423 3.63434L8.11485 7.30621L6.50001 9.23903C6.48362 9.25789 6.46873 9.278 6.45548 9.29918C6.33791 9.47959 6.26894 9.6873 6.25525 9.9022C6.24157 10.1171 6.28362 10.3319 6.37735 10.5257C7.08516 11.9734 8.54376 13.4211 10.007 14.1281C10.2023 14.2209 10.4184 14.2615 10.634 14.2459C10.8497 14.2303 11.0576 14.159 11.2375 14.039C11.2576 14.0255 11.2769 14.0109 11.2953 13.9953L13.1945 12.375L16.8664 14.0195C16.8664 14.0195 16.8727 14.0195 16.875 14.0195C16.7751 14.7767 16.4027 15.4716 15.8275 15.9742C15.2524 16.4767 14.5138 16.7525 13.75 16.75Z" fill="white" />
                                        </svg>
                                        <span className='ms-2'>Contact Us Now</span>
                                    </button>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-6">
                                <div className="right-content">
                                    <div className="deliver-item d-flex align-items-start justify-content-start mb-3">
                                        <img src="/project-operations/blue-check.svg" className='me-3' alt="delivering-icon" />
                                        <div>
                                            <h5 className='mir-h6-semibold'>Maximize Cloud Benefits</h5>
                                            <p className='mir-p-rg-regular mt-3' style={{ maxWidth: '480px' }}>Realize the benefits of this new cloud platform with our highly trained and certified consulting and project team, committed to understanding your unique business challenges.</p>
                                        </div>
                                    </div>
                                    <div className="deliver-item d-flex align-items-start justify-content-start mb-3">
                                        <img src="/project-operations/blue-check.svg" className='me-3' alt="delivering-icon" />
                                        <div>
                                            <h5 className='mir-h6-semibold'>Project Management and Expertise</h5>
                                            <p className='mir-p-rg-regular mt-3' style={{ maxWidth: '480px' }}>We offer exceptional project and change management experience, specialized industry and business process expertise, straightforward and honest communication, as well as one-on-one and group training.</p>
                                        </div>
                                    </div>
                                    <div className="deliver-item d-flex align-items-start justify-content-start mb-3">
                                        <img src="/project-operations/blue-check.svg" className='me-3' alt="delivering-icon" />
                                        <div>
                                            <h5 className='mir-h6-semibold'>Ongoing Support</h5>
                                            <p className='mir-p-rg-regular mt-3' style={{ maxWidth: '480px' }}>Our support extends beyond go-live to ensure continued success. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div style={{ background: '#FAFAFC' }} data-section id='section-5'>
                    <SuccessStoriesDynamic
                        successStoriesContent={successStoriesContent}
                    />
                </div>

                <TransformationSection
                    heading={<>Maximize your digital transformation with <br /> Dynamics 365 next-generation application</>}
                    desc={<>Unlike traditional systems, Microsoft Dynamics 365 is an intelligent and scalable ERP platform of business applications that can work seamlessly together to modernize business operation. With our seasoned expert team, MIR can help you tailor and adapt any functionality to your business requirements. </>}
                    items={[
                        {
                            icon: '/supply-chain/transformation-icon-3.png',
                            heading: <>Dynamics Sales</>,
                            link: <Link to={MIRRoutes.Sales}><span className='mir-p-sm-medium me-2'>Learn More</span>
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0" />
                                </svg></Link>
                        },
                        {
                            icon: '/supply-chain/hero-icon.png',
                            heading: <>Dynamics Supply <br />Chain Management</>,
                            link: <Link to={MIRRoutes.SupplyChain}><span className='mir-p-sm-medium me-2'>Learn More</span>
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0" />
                                </svg></Link>
                        },
                        {
                            icon: '/supply-chain/transformation-icon-1.png',
                            heading: <>Dynamics Business <br /> Central</>,
                            link: <Link to={MIRRoutes.BusinessCentral}><span className='mir-p-sm-medium me-2'>Learn More</span>
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0" />
                                </svg></Link>
                        },
                    ]}
                />

                <div data-section id='section-6'>
                    <ContactSection />
                </div>

                <Footer></Footer>
            </main>
        </>
    )
}

export const ChallengeIcon = () => (
    <svg width="35" height="35" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_5352_42016)">
            <path d="M22.75 28H5.25C2.35025 28 0 25.6497 0 22.75V5.25C0 2.35025 2.35025 0 5.25 0H22.75C25.6497 0 28 2.35025 28 5.25V22.75C28 25.6497 25.6497 28 22.75 28Z" fill="#FEEFEF" />
            <path d="M14 7C10.1386 7 7 10.1386 7 14C7 17.8614 10.1386 21 14 21C17.8614 21 21 17.8614 21 14C21 10.1386 17.8614 7 14 7ZM9.91638 12.2911C9.91638 11.6436 10.4414 11.1247 11.0828 11.1247C11.7241 11.1247 12.2491 11.6436 12.2491 12.2911C12.2491 12.9325 11.7241 13.4575 11.0828 13.4575C10.4414 13.4575 9.91638 12.9325 9.91638 12.2911ZM17.3294 17.9331C17.2156 18.0469 17.066 18.1038 16.9172 18.1038C16.7676 18.1038 16.6189 18.0469 16.5051 17.9331C15.8358 17.2638 14.9459 16.8962 14 16.8962C13.0541 16.8962 12.1642 17.2646 11.4957 17.934C11.2682 18.1615 10.899 18.1615 10.6706 17.934C10.4431 17.7065 10.4431 17.3372 10.6706 17.1089C11.5596 16.2199 12.7426 15.7299 14 15.7299C15.2574 15.7299 16.4395 16.2199 17.3294 17.1089C17.5569 17.3364 17.5569 17.7056 17.3294 17.9331ZM16.9164 13.4575C16.275 13.4575 15.75 12.9325 15.75 12.2911C15.75 11.6436 16.275 11.1247 16.9164 11.1247C17.5577 11.1247 18.0828 11.6436 18.0828 12.2911C18.0836 12.9325 17.5586 13.4575 16.9164 13.4575Z" fill="#E96767" />
        </g>
        <defs>
            <clipPath id="clip0_5352_42016">
                <rect width="28" height="28" fill="white" />
            </clipPath>
        </defs>
    </svg>
)