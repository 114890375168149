import React, { useState } from 'react'
import StickyBanner from '../../StickyBanner/StickyBanner'
import Footer from '../../Footer/Footer'
import HeroSection from '../Elements/Sections/HeroSection/HeroSection'
import SectionTabs from '../Elements/Sections/SectionTabs/SectionTabs'
import OverviewSection from '../Elements/Sections/OverviewSection'
import BlueBanner from '../Elements/Sections/BlueBanner'
import BlueFluidBanner from '../Elements/Sections/BlueFluidBanner'
import HowHelpSection from '../Elements/Sections/HowHelpSection'
import StepsSlider from '../Elements/StepProcess/StepProcess'
import SuccessStory from '../Elements/Sections/SuccessStory'
import ContactSection from '../Elements/Sections/ContactSection'
import TransformationSection from '../Elements/Sections/TransformationSection'
import BookNowModal from '../../CommonElements/BookNowModal'
import { Helmet } from 'react-helmet'
import CaseStudyUrls from '../../../lib/singleCaseStudyUrls'
import { Link } from 'react-router-dom'
import MIRRoutes from '../../../lib/routes'
import SuccessStoriesDynamic from '../../CommonElements/SuccessStoriesDynamic'

export default function PowerApps() {

    const [tabs, setTabs] = useState([
        { name: 'Overview', id: 'section-1' },
        { name: 'Introduction', id: 'section-2' },
        { name: 'Benefits', id: 'section-3' },
        { name: 'How can we help you', id: 'section-4' },
        { name: 'Success Story', id: 'section-5' },
        { name: 'Contact Us', id: 'section-6' },
    ]);
    const successStoriesContent = [
        {
            image: "/success-stories-custom/12.png",
            timeToRead: "5",
            desc: "Construction and Mining Leader Integrates Self-Serve Portal With Microsoft Dynamics 365",
            linkToRead: "/about-us/case-studies/self-serve-portal"
        },
        {
            image: "/success-stories-custom/2.png",
            timeToRead: "5",
            desc: "A&A Pharmachem Inc Drives Operational Efficiencies With D365 Business Central and PowerBI",
            linkToRead: "/about-us/case-studies/aanda-pharmachem-business-central-power-bi"
        },
        {
            image: "/success-stories-custom/8.png",
            timeToRead: "10",
            desc: "MIR & Compugen’s partnership transforms WoodGreen Tax Clinic services with Microsoft Power Platform",
            linkToRead: "/about-us/case-studies/woodgreen-tax-clinic-services-with-microsoft-power-platform"
        }
    ];

    const [showRegionPopup, setShowRegionPopup] = useState(false);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Microsoft PowerApps - Power Platform Services | MIR Digital Solutions</title>
                <meta name="description" content="Leverage Microsoft-certified professional experts to optimize, automate your business operations & drive innovation with our PowerApps consulting services." />
                <link rel="canonical" href={`${window.location.origin}/cloud-solutions/microsoft-power-apps-solution`} />
            </Helmet>
            <main>
                <StickyBanner />
                <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />

                <section className='tw-pt-[120px] tw-pb-[160px] tw-bg-cover tw-bg-center tw-bg-no-repeat' style={{ backgroundImage: 'url(/supply-chain/bg.svg)' }}>
                    <div className="container mx-auto text-center">
                        <img src="/power-apps/hero-icon.png" className='tw-mb-[42px]'
                            alt="MIR Digital Solutions is a Microsoft Solutions Partner for digital, app innovation, and Azure services."
                        />
                        <h4 className='tw-mb-[24px] mir-h6-medium mir-text-primary-500'>Simplify custom app creation</h4>
                        <h2 className='mir-display-semibold-sm mir-text-neutral-800 m-0'>Microsoft Power Apps</h2>
                    </div>
                </section>

                {/* section tabs */}
                <SectionTabs tabs={tabs} />

                <section data-section id="section-1" className='!tw-p-0'>
                    <OverviewSection
                        overline={'OVERVIEW'}
                        heading={<>Easy creation, speedy development, and seamless integration</>}
                        desc={<>
                            <span className='tw-mb-[36px]'>Modernization is driven by two key customer priorities: replacing aging infrastructure with agile platforms and accelerating innovation. The demand for apps and automation is soaring, as more clients adopt the cloud with 500 million new apps expected in five years. Mobile app demand is expected to outpace delivery by five fold.</span> <br /><br />
                            <span className='tw-mb-[36px] mir-h6-semibold'>5 key challenges for businesses still using aging infrastructure</span> <br /><br />
                            <div className='tw-mt-[36px]'>
                                {
                                    [
                                        { heading: 'Limited Automation', desc: 'Absence of a finance and customer engagement platform for the sales and accounting departments to support business expansion.' },
                                        { heading: 'Manual Workloads', desc: 'The absence of custom applications forces reliance on manual processes, reducing productivity and increasing human errors.' },
                                        { heading: 'Inflexible Solutions', desc: 'Businesses lack the ability to create tailored solutions for unique challenges, leading to reliance on generic tools that might not fully meet their needs.' },
                                        { heading: 'Slow Adaptation', desc: 'Without application development, adapting to changing market conditions or customer demands becomes slower and more challenging.' },
                                        { heading: 'Missed Opportunities', desc: 'Businesses forego the potential for innovation and competitive advantage that custom applications can provide, limiting their growth potential.' },
                                    ].map((item, index) => {
                                        return <div className='tw-flex tw-items-start tw-justify-start tw-mb-[32px]'>
                                            <img src="/power-apps/sad.png" alt="sadness-icon" className='tw-mr-[24px]' />
                                            <div className='tw-max-w-full md:tw-max-w-[583px]'>
                                                <h5 className='mir-p-md-semibold'>{item.heading}</h5>
                                                <h3 className='mir-p-rg-regular'>{item.desc}</h3>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                            <p className='mir-p-md-regular tw-mb-[36px]'>Business users have a strong need for simple, low-code apps that wouldn’t be addressed by professional developers. <b>This gap can be filled by Microsoft Power Apps that enables developers to do more with less.</b></p>
                            <h5 className='mir-p-md-medium mir-text-primary-500 tw-flex tw-items-center' onClick={() => { setShowRegionPopup(true) }}>
                                <span className='tw-mr-[12px]'>Learn more about how to build a Microsoft Power App</span>
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.1922 10.942L7.94218 17.192C7.88412 17.2501 7.81518 17.2962 7.73931 17.3276C7.66344 17.359 7.58212 17.3752 7.5 17.3752C7.41787 17.3752 7.33656 17.359 7.26069 17.3276C7.18482 17.2962 7.11588 17.2501 7.05781 17.192C6.99974 17.134 6.95368 17.065 6.92225 16.9892C6.89082 16.9133 6.87465 16.832 6.87465 16.7499C6.87465 16.6677 6.89082 16.5864 6.92225 16.5105C6.95368 16.4347 6.99974 16.3657 7.05781 16.3077L12.8664 10.4999L7.05781 4.69205C6.94053 4.57477 6.87465 4.41571 6.87465 4.24986C6.87465 4.08401 6.94053 3.92495 7.05781 3.80767C7.17508 3.6904 7.33414 3.62451 7.5 3.62451C7.66585 3.62451 7.82491 3.6904 7.94218 3.80767L14.1922 10.0577C14.2503 10.1157 14.2964 10.1846 14.3278 10.2605C14.3593 10.3364 14.3755 10.4177 14.3755 10.4999C14.3755 10.582 14.3593 10.6633 14.3278 10.7392C14.2964 10.8151 14.2503 10.884 14.1922 10.942Z" fill="#1D68F0" />
                                </svg>
                            </h5>
                        </>}
                        bg={'url(/business/overview-bg.png)'}
                    />
                </section>

                <section className='mir-bg-neutral-100 tw-pt-[64px] tw-pb-[96px]' data-section id='section-2'>
                    <div className="container mx-auto text-center">
                        <img src="/power-apps/logo.svg" alt="what is microsoft power apps" className='tw-mb-[52px]' />
                        <h4 className='mir-p-overline-lg mir-text-primary-500 tw-text-uppercase'>Introduction</h4>
                        <h2 className='mir-text-neutral-800 mir-h1-semibold tw-mb-[32px]'>What is Microsoft Power Apps?</h2>
                        <p className='tw-max-w-full md:tw-max-w-[802px] mx-auto mir-p-md-regular'>
                            <a className='mir-p-md-medium' target='_blank' href="https://powerapps.microsoft.com/en-ca/landing/developer-plan/?ef_id=_k_CjwKCAjwrranBhAEEiwAzbhNtXWgXBK51v2jbAohcsg0f4wJvF4CbshDX6d6uiJuezMPnY551ixHNhoCtBIQAvD_BwE_k_&OCID=AIDcmmtzw9mw5t_SEM__k_CjwKCAjwrranBhAEEiwAzbhNtXWgXBK51v2jbAohcsg0f4wJvF4CbshDX6d6uiJuezMPnY551ixHNhoCtBIQAvD_BwE_k_&gad=1&gclid=CjwKCAjwrranBhAEEiwAzbhNtXWgXBK51v2jbAohcsg0f4wJvF4CbshDX6d6uiJuezMPnY551ixHNhoCtBIQAvD_BwE">Microsoft Power Apps</a>&nbsp;offers an accessible approach to crafting personalized applications, bypassing intricate coding. With a visual interface and pre-designed templates, app development is expedited. Microsoft Power Apps effortlessly connects to diverse data sources, supporting mobile, web, and workflow automation.</p>
                    </div>
                </section>
                <section className='mir-bg-neutral-100 tw-pb-[120px] text-center '>
                    <img src="/power-apps/introduction.png" alt="Power Apps screen demo dashboards including multiple charts, data analysis and actionable insights that can be accessed in real time. Craft applications without heavy coding and scale with confidence" className='tw-max-w-full md:tw-max-w-[802px] mx-auto ' />
                </section>
                <section className='mir-bg-neutral-100 tw-pb-[96px]'>
                    <div className="container mx-auto">
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-x-[64px] tw-gap-y-[56px]">
                            {
                                [
                                    { icon: '/power-apps/intro-features/1.svg', heading: 'Use any data source', desc: 'Connect to data sources—such as Adobe, MailChimp, and Microsoft 365—with more than 500 connectors. Or create your own. ' },
                                    { icon: '/power-apps/intro-features/2.svg', heading: 'Scale with confidence ', desc: 'Drive innovation and save time with Microsoft Dataverse. Use common tables or build your own data schema. ' },
                                    { icon: '/power-apps/intro-features/3.svg', heading: 'Build without coding ', desc: 'Quickly create apps in days—without having to write code—using prebuilt templates and drag-and-drop functionality. ' },
                                    { icon: '/power-apps/intro-features/4.svg', heading: 'Learn easily ', desc: 'Accelerate adoption and training with free online learning resources and tutorials. ' },
                                    { icon: '/power-apps/intro-features/5.svg', heading: 'Save money ', desc: 'Use a single, cost-effective platform that’s robust and scales to your needs.' },
                                ].map((item, index) => {
                                    return <div key={`intro-feature-index-${index}`}>
                                        <img src={item.icon} alt="feature-icon" className='tw-mb-[24px]' />
                                        <h5 className='mir-h6-semibold tw-mb-[12px]'>{item.heading}</h5>
                                        <p className='mir-p-rg-regular mir-text-neutral-600'>{item.desc}</p>
                                    </div>
                                })
                            }
                        </div>
                        <BlueBanner heading={<div className='tw-max-w-[545px]'>Choose Microsoft Power Apps for easy, speedy, and customizable app creation</div>} />
                    </div>
                </section>

                <section className='tw-pt-[120px] tw-pb-[120px]'>
                    <div className="container mx-auto">
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[32px]">
                            <div>
                                <h2 className='mir-text-primary-500 mir-h2-semibold tw-max-w-full md:tw-max-w-[330px]'>Key Microsoft<br />Power Apps Features</h2>
                            </div>
                            {
                                [
                                    { icon: '/power-apps/key-features/1.svg', heading: 'Integration and Collaboration', desc: 'Seamlessly integrates with Microsoft services, fostering collaboration for addressing business challenges.' },
                                    { icon: '/power-apps/key-features/2.svg', heading: 'Workflow Automation', desc: 'Automates workflows using Microsoft Power Automate, triggering actions based on events.' },
                                    { icon: '/power-apps/key-features/3.svg', heading: 'Low-Code Development', desc: 'Visual drag-and-drop interface for swift app creation using pre-built components and custom logic.' },
                                    { icon: '/power-apps/key-features/4.svg', heading: 'Integration', desc: 'Seamlessly connects to Microsoft 365, Dynamics 365, SharePoint, Azure, and more for data-driven apps.' },
                                    { icon: '/power-apps/key-features/5.svg', heading: 'Mobile and Web Apps', desc: 'Supports both mobile and web app development for widespread user access.' },
                                    { icon: '/power-apps/key-features/6.svg', heading: 'Templates and Customization', desc: 'Offers customizable templates and components to save time and effort in app design.' },
                                    { icon: '/power-apps/key-features/7.svg', heading: 'Data Visualization', desc: 'Enables interactive data visualization and dashboards for effective analysis and presentation.' },
                                ].map((item, index) => {
                                    return <div key={`key-feature-index-${index}`} className='tw-p-[36px] mir-bg-neutral-100 tw-rounded-[8px]'>
                                        <img src={item.icon} alt="feature-icon" className='tw-mb-[24px]' />
                                        <h5 className='mir-h6-semibold tw-mb-[12px]'>{item.heading}</h5>
                                        <p className='mir-p-rg-regular mir-text-neutral-600'>{item.desc}</p>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </section>

                <div className='mir-bg-neutral-100'>
                    <div className={`tw-px-[36px] tw-py-[36px] md:tw-px-[64px] md:tw-py-[72px] tw-relative mir-bg-primary-500 tw-overflow-hidden`}>
                        <img src="/supply-chain/light-blue-banner-bg.png" alt="blue-banner-bg" className='tw-w-full tw-h-full tw-absolute tw-z-0 tw-inset-0' />
                        <div className='tw-relative tw-z-10 tw-flex tw-flex-col tw-items-center tw-flex-wrap'>
                            <h2 className='tw-max-w-full tw-mb-[36px] md:tw-mb-[36px] md:tw-max-w-[928px] mir-h5-semibold text-center text-white'>Experience the power of Microsoft Power Apps and turn your unique concepts into fully functional applications effortlessly</h2>
                            <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-secondary mir-btn-md mir-text-primary-500 mir-bg-white'>
                                <span className='tw-mr-[12px] mir-p-rg-medium'>Contact a Microsoft Power Apps expert</span>
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.3172 10.9423L11.6922 16.5673C11.5749 16.6846 11.4159 16.7505 11.25 16.7505C11.0841 16.7505 10.9251 16.6846 10.8078 16.5673C10.6905 16.45 10.6247 16.291 10.6247 16.1251C10.6247 15.9593 10.6905 15.8002 10.8078 15.6829L15.3664 11.1251H3.125C2.95924 11.1251 2.80027 11.0593 2.68306 10.942C2.56585 10.8248 2.5 10.6659 2.5 10.5001C2.5 10.3343 2.56585 10.1754 2.68306 10.0582C2.80027 9.94095 2.95924 9.8751 3.125 9.8751H15.3664L10.8078 5.31729C10.6905 5.20002 10.6247 5.04096 10.6247 4.8751C10.6247 4.70925 10.6905 4.55019 10.8078 4.43292C10.9251 4.31564 11.0841 4.24976 11.25 4.24976C11.4159 4.24976 11.5749 4.31564 11.6922 4.43292L17.3172 10.0579C17.3753 10.116 17.4214 10.1849 17.4529 10.2608C17.4843 10.3366 17.5005 10.418 17.5005 10.5001C17.5005 10.5822 17.4843 10.6636 17.4529 10.7394C17.4214 10.8153 17.3753 10.8842 17.3172 10.9423Z" fill="#1D68F0" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <section data-section id='section-3' className='mir-bg-neutral-800 tw-bg-bottom tw-bg-cover tw-bg-no-repeat' style={{ backgroundImage: 'url(/business/service-bundles-bg.png)' }}>
                    <div className='container tw-mx-auto tw-text-center tw-pt-[120px] tw-pb-[120px]'>
                        <h4 className='mir-p-overline-lg text-primary-500 tw-mb-[24px]'>BENEFITS</h4>
                        <h2 className='mir-h1-semibold mir-text-neutral-100 tw-mb-[36px] tw-max-w-[100%] md:tw-max-w-[802px] tw-mx-auto'>Why should you choose <br /> Microsoft Power Apps?</h2>
                        <h3 className='mir-text-neutral-100 mir-p-lg-regular tw-max-w-[100%] md:tw-max-w-[802px] tw-mx-auto'>Microsoft Power Apps should be your choice for several compelling reasons:</h3>
                    </div>
                </section>
                <section className='tw-py-[96px] mir-bg-neutral-100'>
                    <div className="container mx-auto">
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-[52px]">
                            {
                                [
                                    { heading: 'Easy Creation', desc: 'No extensive coding needed, thanks to a user-friendly interface and pre-built templates.' },
                                    { heading: 'Data Connectivity', desc: 'Seamlessly connect to diverse data sources like Microsoft 365, Dynamics 365, and more.' },
                                    { heading: 'Automation', desc: 'Automate workflows using Power Automate for improved efficiency.' },
                                    { heading: 'Customization', desc: 'Customize templates and components, saving time in design.' },
                                    { heading: 'Data Visualization', desc: 'Craft interactive data visuals and dashboards.' },
                                    { heading: 'Integration', desc: 'Seamlessly integrate with other Microsoft services.' },
                                    { heading: 'Mobile and Web Reach', desc: 'Create apps for both mobile and web platforms.' },
                                    { heading: 'Speedy Development', desc: 'Build apps faster compared to traditional coding methods.' },
                                    { heading: 'Collaboration', desc: 'Foster teamwork on app development.' },
                                    { heading: 'Scalability', desc: 'Suitable for both small-scale and enterprise applications.' },
                                    { heading: 'Security', desc: 'Enjoy enterprise-grade security features.' },
                                    { heading: 'Low-Code Advantage', desc: 'Accessible to various users, regardless of coding skills.' },
                                ].map((item, index) => {
                                    return <div key={`benefit-feature-index-${index}`}>
                                        <h4 className='mir-text-primary-500 mir-h6-semibold tw-mb-[12px]'>{item.heading}</h4>
                                        <h5 className='mir-text-neutral-600 mir-p-rg-regular'>{item.desc}</h5>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </section>

                <div data-section id='section-4' style={{ marginTop: '-24px' }}>
                    <HowHelpSection
                        overline={'HOW WE HELP YOU'}
                        heading={<>Why should you choose <span style={{ color: '#1D68F0' }}>MIR Digital Solutions</span> to build best low-code platform capabilities?</>}
                        desc={<>MIR Digital Solutions has a proven track record of successful implementations and a strong commitment to Microsoft technologies, resulting in unrivaled product knowledge and process improvement expertise. Our experience has enabled us to develop efficient processes and approaches for cloud solutions, enabling innovation in a way other competitors simply cannot.</>}
                        image={'url(/power-apps/help-bg.png) no-repeat'}
                    />
                </div>

                <div className="delivering-wrapper py-5 mb-5">
                    <div className="container">
                        <h3 className='mir-h5-semibold mb-4'>As a Microsoft Partner, we deliver</h3>
                        <div className="row">
                            {
                                [
                                    'An end-to-end digital transformation platform that drives project-based operations from prospect to payment to profit',
                                    'An extensive partner ecosystem and go-to-market channels, including AppSource Marketplace, partner-to partner network, and Microsoft sellers',
                                    'Dynamics 365 extensibility to unite Finance, Human Resources, Sales, Supply Chain Management, Customer Service, Field Service, and more',
                                    'Deep expertise and a rich history with CRM, ERP, HCM, collaboration, productivity, and project management applications',
                                    'Complete cloud vision that encompasses Azure, Office 365, and Dynamics 365',
                                    'Accelerated application development and flexibility with the Power Platform',
                                ].map((item) => {
                                    return <div className="col-12 col-md-4">
                                        <div className="deliver-item d-flex align-items-start justify-content-start">
                                            <img src="/project-operations/blue-check.svg" className='mt-3 me-3' alt="delivering-icon" />
                                            <h3 className='mir-p-rg-regular mt-3' style={{ maxWidth: '450px' }}>{item}</h3>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>

                <StepsSlider
                    subtitle={'Our specialist team offers custom-tailored implementation of Microsoft Power Apps for crafting personalized applications, bypassing intricate coding.'}
                />

                <section className='tw-pt-[64px] tw-pb-[120px]'>
                    <div className="container mx-auto text-center">
                        <h3 className='tw-mb-[36px] tw-max-w-full md:tw-max-w-[732px] mir-h4-semibold mir-text-neutral-800 mx-auto text-center'>Connect here to embark on your app-building journey with Microsoft Power Apps and experience simplified application development today</h3>
                        <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-md mir-btn-tertiary mx-auto'>
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.75 3H14.875V2.375C14.875 2.20924 14.8092 2.05027 14.6919 1.93306C14.5747 1.81585 14.4158 1.75 14.25 1.75C14.0842 1.75 13.9253 1.81585 13.8081 1.93306C13.6908 2.05027 13.625 2.20924 13.625 2.375V3H7.375V2.375C7.375 2.20924 7.30915 2.05027 7.19194 1.93306C7.07473 1.81585 6.91576 1.75 6.75 1.75C6.58424 1.75 6.42527 1.81585 6.30806 1.93306C6.19085 2.05027 6.125 2.20924 6.125 2.375V3H4.25C3.91848 3 3.60054 3.1317 3.36612 3.36612C3.1317 3.60054 3 3.91848 3 4.25V16.75C3 17.0815 3.1317 17.3995 3.36612 17.6339C3.60054 17.8683 3.91848 18 4.25 18H16.75C17.0815 18 17.3995 17.8683 17.6339 17.6339C17.8683 17.3995 18 17.0815 18 16.75V4.25C18 3.91848 17.8683 3.60054 17.6339 3.36612C17.3995 3.1317 17.0815 3 16.75 3ZM6.125 4.25V4.875C6.125 5.04076 6.19085 5.19973 6.30806 5.31694C6.42527 5.43415 6.58424 5.5 6.75 5.5C6.91576 5.5 7.07473 5.43415 7.19194 5.31694C7.30915 5.19973 7.375 5.04076 7.375 4.875V4.25H13.625V4.875C13.625 5.04076 13.6908 5.19973 13.8081 5.31694C13.9253 5.43415 14.0842 5.5 14.25 5.5C14.4158 5.5 14.5747 5.43415 14.6919 5.31694C14.8092 5.19973 14.875 5.04076 14.875 4.875V4.25H16.75V6.75H4.25V4.25H6.125ZM16.75 16.75H4.25V8H16.75V16.75Z" fill="white" />
                            </svg>
                            <span className='tw-ml-2 mir-p-rg-medium'>Book a meeting</span>
                        </button>
                    </div>
                </section>

                <div data-section id='section-5'>
                    <SuccessStoriesDynamic
                        successStoriesContent={successStoriesContent}
                    />
                </div>

                <TransformationSection
                    heading={<>Empower your ideas and <br /> build your business with Power Apps</>}
                    desc={<> With our seasoned expert team, MIR can help you tailor and adapt any functionality to your business requirements.</>}
                    items={[
                        {
                            icon: '/transformation-icons/power-bi.png',
                            heading: <>Microsoft Power BI</>,
                            link: <Link to={MIRRoutes.PowerBI}><span className='mir-p-sm-medium me-2'>Learn More</span>
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0" />
                                </svg></Link>
                        },
                        {
                            icon: '/transformation-icons/365-business.png',
                            heading: <>Microsoft 365  for <br />Business</>,
                            link: <Link to={MIRRoutes.BusinessCentral}><span className='mir-p-sm-medium me-2'>Learn More</span>
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0" />
                                </svg></Link>
                        },
                        {
                            icon: '/transformation-icons/sharepoint.png',
                            heading: <>Microsoft <br /> SharePoint</>,
                            link: <Link to={MIRRoutes.SharePoint}><span className='mir-p-sm-medium me-2'>Learn More</span>
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0" />
                                </svg></Link>
                        },
                    ]}
                />

                <div data-section id='section-6'>
                    <ContactSection />
                </div>

                <Footer />
            </main>
        </>
    )
}
