import Slider from "react-slick";
import React from "react";
import {ReactComponent as TestimonialQuoteSvg} from './../svg/testimonial_quote.svg';

const EmployeeTestimonials = () => {

    const centerPaddingValue = window.innerWidth < 768 ? '0%' : '20%';

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: centerPaddingValue,
        slidesToShow: 1,
        speed: 1500,
        dots: true,
        dotsClass: 'slick-dots mir-slider-dots',
        autoplay: true,
        arrows: false
    };

    const empTestimonial = [{
        content: "The company culture values authenticity, and I feel encouraged to be myself every day. What sets MIR apart is the equal opportunities it provides for everyone to flourish. I've been empowered to shape my own path, and the support for growth and leadership is unparalleled. It's not just a workplace; it's a place where individuality and culture diversity are celebrated.",
        employee: {
            imageUrl: "/mir-culture/images/testimonial/testimonial_1.png",
            name: "Prasanna Jeyakumar",
            designation: "Director of Product & Sales Engineering"
        }

    }, {
        content: "MIR is more than just a workplace; it's a community where individuality is celebrated. The company's commitment to equal opportunities has empowered me to grow and lead. I appreciate being part of an organization that values diversity and fosters an environment where women can thrive. Working here is not just a job; it's a journey of empowerment and self-discovery.",
        employee: {
            imageUrl: "/mir-culture/images/testimonial/testimonial_2.png",
            name: "Shraddha Kachwal",
            designation: "Financial Analyst"
        }

    }, {
        content: "Working at MIR has been a fantastic experience, accelerating my growth through early opportunities and responsibilities. The supportive team, including leaders, is always ready to help with work-related matters or personal challenges. I've gained valuable technical and soft skills, from negotiations with clients to effectively presenting ideas and fostering teamwork.",
        employee: {
            imageUrl: "",
            name: "Irshad Mohammad",
            designation: "Cloud DevOps Engineer"
        }

    }, {
        content: "Working at MIR has been an incredible journey. The supportive environment and collaborative culture make every day fulfilling. At MIR, I’ve never felt more valued and empowered to contribute my best. I’m so grateful for the opportunities to learn and grow professionally while being part of such an amazing team.",
        employee: {
            imageUrl: "",
            name: "Hailey Vu",
            designation: "UX/UI Designer"
        }

    }]

    return (
        <section className="testimonial-section">
            <div className="testimonial-container row">
                <div className="col-12 text-center">
                    <h2 className="mir-h2-semibold text-center">What our employees say</h2>
                </div>
                <div className="col-12 slider-container">
                    <Slider {...settings}>
                        {empTestimonial.map((et, index) => {
                            const initials = et.employee.name.split(" ").map((n) => n[0]).join("");
                            return (
                                <div key={index} className="slider-card">
                                    <div className='row testimonial-top'>
                                        <div className='text-center'>
                                            <TestimonialQuoteSvg/>
                                        </div>
                                        <h4 className="testimonial-content mir-p-lg-medium mir-text-neutral-700">{et.content}</h4>
                                    </div>
                                    <div className='row justify-content-center'>
                                        <div className="testimonial-profile d-flex align-items-center">
                                            <img
                                                src={et.employee.imageUrl || `https://placehold.co/600x400?text=${initials}`}
                                                className="profile-img rounded-circle mr-3"
                                                alt={`${et.employee.name}'s profile`}/>
                                            <div className="profile-info d-flex flex-column">
                                                <div
                                                    className="mir-p-rg-semibold mir-text-neutral-800">{et.employee.name}</div>
                                                <div
                                                    className="mir-p-rg-regular mir-text-neutral-500">{et.employee.designation}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </div>
        </section>
    );
}

export default EmployeeTestimonials;