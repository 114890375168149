import React, { useEffect, useRef, useState } from 'react'
import StickyBanner from '../../StickyBanner/StickyBanner'
import Footer from '../../Footer/Footer'
import './ProjectRescue.scss'
import StepsSlider from '../Elements/StepProcess/StepProcess';
import SectionTabs from '../Elements/Sections/SectionTabs/SectionTabs';
import OverviewSection from '../Elements/Sections/OverviewSection';
import BlueFluidBanner from '../Elements/Sections/BlueFluidBanner';
import HowHelpSection from '../Elements/Sections/HowHelpSection';
import SuccessStory from '../Elements/Sections/SuccessStory';
import ContactSection from '../Elements/Sections/ContactSection';
import QuestionSlider from '../Elements/QuestionSlider';

import blakes from './images/blakes.png';
import DMCMining2 from './images/DMCMining2.svg';
import metrolinx from './images/metrolinx.svg';
import Rogers from './images/Rogers_logo.svg';
import transunion from './images/transunion_logo.svg';
import { Banner } from '../../HomeComponent/Banner';
import BookNowModal from '../../CommonElements/BookNowModal';
import { Helmet } from 'react-helmet';
import CaseStudyUrls from '../../../lib/singleCaseStudyUrls';
import SuccessStoriesDynamic from '../../CommonElements/SuccessStoriesDynamic';

const images = [
    blakes,
    DMCMining2,
    metrolinx,
    Rogers,
    transunion,
    blakes,
    DMCMining2,
    metrolinx,
    Rogers,
    transunion,
    blakes,
    DMCMining2,
    metrolinx,
    Rogers,
    transunion
].map((image) => ({
    id: crypto.randomUUID(),
    image
}));


export default function ProjectRescue() {
    const [showRegionPopup, setShowRegionPopup] = useState(false);
    const [tabs, setTabs] = useState([
        { name: 'Overview', id: 'section-1' },
        { name: 'Introduction', id: 'section-2' },
        { name: 'Benefits', id: 'section-4' },
        { name: 'How can we help you', id: 'section-5' },
        { name: 'Success Story', id: 'section-6' },
        { name: 'Contact Us', id: 'section-7' },
        { name: '', id: 'section-8' },
    ]);
    const questions = [
        'Have you lost trust in your Microsoft Technology partner due to unresponsiveness, lack of transparency, or insufficient resources?',
        'Are you dealing with expensive but ineffective applications and unhappy employees due to incorrect software implementation?',
        'Is your productivity suffering due to the inability to share mission-critical data with users, management, customers, and partners?',
        'Are your reporting tools inadequate and frustrating for staff and management?'
    ];

    const successStoriesContent = [
        {
            image: "/success-stories-custom/1.png",
            timeToRead: "3",
            desc: "Construction and mining giant drives operational efficiency with Dynamics 365 Inventory and Warehouse Management",
            linkToRead: "/about-us/case-studies/construction-mining-inventory-warehouse-management"
        },
        {
            image: "/success-stories-custom/2.png",
            timeToRead: "10",
            desc: "A&A Pharmachem Inc drives operational efficiencies with D365 Business Central and Power BI",
            linkToRead: "/about-us/case-studies/aanda-pharmachem-business-central-power-bi"
        },
        {
            image: "/success-stories-custom/9.png",
            timeToRead: "5",
            desc: "MIR finds nearly a 50% saving in Microsoft D365 Licensing Costs for a Top-tier Organization",
            linkToRead: "/about-us/case-studies/saving-in-microsoft-d365-licensing-costs"
        }
    ];
    const childRef = useRef();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dynamics & ERP Project Rescue | MIR Digital Solutions</title>
                <meta name="description" content="Dynamics & ERP Project Rescue | MIR Digital Solutions" />
                <link rel="canonical" href={`${window.location.origin}/business-solutions/project-rescue`} />
            </Helmet>
            <main style={{ position: 'relative' }} className='project-rescue-main-wrapper '>
                <StickyBanner />
                <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />
                <div className="bi-wrapper">
                    <div className='hero-wrapper text-left'>
                        <div className='container'>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="content text-left">
                                        <h1 className='mir-display-semibold-sm'>Project Rescue</h1>
                                        <p className='mir-paragraph-lg-l4-l5 text-secondary-600 mt-4'>Regain control of your Microsoft Dynamics 365 implementation with MIR's Project Rescue solutions </p>
                                        <button className='mir-btn-md-primary mt-4' onClick={(e) => { setShowRegionPopup(true) }}>Contact us today</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* section tabs */}
                <SectionTabs tabs={tabs} />

                {/* overview */}
                <div data-section id='section-1'>
                    <div className="section-1-pr">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-5">
                                    <h4 className='overline mir-paragraph-sm-l2-l7 text-primary-500'>Overview</h4>
                                    <h1 className='mir-heading-l6-l2 text-secondary-800'>
                                        Boost your system and drive your business toward success
                                    </h1>
                                    <p className='mir-paragraph-md-l4-l7 text-secondary-800'>
                                        Are you worried that your Microsoft Dynamics 365 isn't working the way it should? If so, a Microsoft Dynamics 365 Project Rescue can help.
                                    </p>
                                </div>
                                <div className="col-lg-6 offset-lg-1">
                                    <div className='mir-paragraph-md-l4-l7 sec-4-img-desc'>
                                        <div className="row">
                                            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1">
                                                <img className='checkmark-images' src={'../../../../business-intelligence/Check.png'}></img>
                                            </div>
                                            <div className="col-lg-11 col-md-11 col-sm-11 col-xs-11 col-11 pl-3">
                                                <h6 className='mir-heading-l1-l2 text-secondary-800'>Customization best practices</h6>
                                                <p className='mir-paragraph-rg-l4-l7 text-secondary-700'>
                                                    Microsoft designed Dynamics 365 to meet the needs of businesses like yours. When customized properly, it should match your business needs and provide the necessary tools at the right time.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mir-paragraph-md-l4-l7 sec-4-img-desc'>
                                        <div className="row">
                                            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1">
                                                <img className='checkmark-images' src={'../../../../business-intelligence/Check.png'}></img>
                                            </div>
                                            <div className="col-lg-11 col-md-11 col-sm-11 col-xs-11 col-11 pl-3">
                                                <h6 className='mir-heading-l1-l2 text-secondary-800'>Achieve business growth</h6>
                                                <p className='mir-paragraph-rg-l4-l7 text-secondary-700'>
                                                    Microsoft Dynamics 365 is built to grow with you. If it's not helping your business grow, it's not built correctly.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mir-paragraph-md-l4-l7 sec-4-img-desc'>
                                        <div className="row">
                                            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1">
                                                <img className='checkmark-images' src={'../../../../business-intelligence/Check.png'}></img>
                                            </div>
                                            <div className="col-lg-11 col-md-11 col-sm-11 col-xs-11 col-11 pl-3">
                                                <h6 className='mir-heading-l1-l2 text-secondary-800'>Overcoming user adoption challenges</h6>
                                                <p className='mir-paragraph-rg-l4-l7 text-secondary-700'>
                                                    Even with perfect configuration, some team members may struggle to use the software. User adoption is a common challenge, but with training and intelligent system configuration, everyone can realize the benefits of Microsoft Dynamics 365.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mir-paragraph-md-l4-l7 sec-4-img-desc'>
                                        <div className="row">
                                            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1">
                                                <img className='checkmark-images' src={'../../../../business-intelligence/Check.png'}></img>
                                            </div>
                                            <div className="col-lg-11 col-md-11 col-sm-11 col-xs-11 col-11 pl-3">
                                                <h6 className='mir-heading-l1-l2 text-secondary-800'>Drive business success</h6>
                                                <p className='mir-paragraph-rg-l4-l7 text-secondary-700'>
                                                    With a Microsoft Dynamics 365 Project Rescue, we can ensure your system is working correctly and helping your business thrive.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <img className='bi-sec-4-img d-block d-lg-none d-md-none' src={'../../../../project-rescue/overview-bg.png'}></img>
                                </div>
                            </div>
                        </div>

                    </div>
                    <QuestionSlider questions={questions} />
                </div>
                <section data-section id='section-2' className=''>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <img className='benefits-img bi-sec-4-img d-block d-lg-none d-md-none' src={'../../../../project-rescue/benefits.png'} alt="" />
                            </div>
                        </div>
                        <div className="row">
                            <h4 className='col-lg-7 overline mir-paragraph-sm-l2-l7 text-primary-500'>Introduction</h4>
                            <h2 className='col-lg-6 mir-heading-l6-l2 text-secondary-800'>
                                What is Microsoft Dynamics 365 Project Rescue?
                            </h2>
                            <p className='col-lg-8 mir-paragraph-md-l4-l7 text-secondary-700 text-justify intro-desc'>
                                MIR Digital's Microsoft Dynamics Project Rescue aids struggling businesses with their implementation. It pinpoints issues, delivers solutions, and offers a roadmap for progress. This service encompasses an in-depth project assessment, remedial suggestions, and a forward plan.
                                <br />
                                <br />
                                Ideal for businesses facing user adoption hurdles, technical glitches, or project delays. Our rescue service rejuvenates Microsoft Dynamics projects, maximizing returns on investment.
                            </p>
                        </div>
                    </div>

                </section>
                <section data-section id='section-4' className='section-4'>
                    <div className='benefits-1-bg' title='MIR Dynamics 365 Rescue, Microsoft business and project management dashboard'>
                        <div className="container">
                            <div className="benefits-4">
                                <div className="row">
                                    <div className="col-lg-6 d-none d-lg-block d-md-block benefits-bg">
                                        {/* <img className='bi-sec-4-img' src={'../../../../project-rescue/benefits.png'}></img> */}
                                    </div>
                                    <div className="col-lg-6">
                                        <h4 className='overline mir-paragraph-sm-l2-l7 text-primary-500'>benefits</h4>
                                        <h2 className="mir-heading-l4-l2">
                                            Why companies benefit from using Business Intelligence tools
                                        </h2>
                                        <div className='mir-paragraph-md-l4-l7 sec-4-img-desc'>
                                            <p className='mb-2'>
                                                According to current statistics, <a href='https://www.linkedin.com/pulse/project-failure-rates-facts-reasons-frank-faeth/' target='_blank'>the software project failure rate is 66%</a>. The leading causes of failed Microsoft project implementation and software project failure are:
                                            </p>
                                            <ul>
                                                <li className='checkmark-bullets'>Budget overruns </li>
                                                <li className='checkmark-bullets'>Time delays </li>
                                                <li className='checkmark-bullets'>Lack of communication and feedback </li>
                                                <li className='checkmark-bullets'>Poorly defined project scope and lack of clear objectives </li>
                                                <li className='checkmark-bullets'>Flaws in project management </li>
                                                <li className='checkmark-bullets'>Data migration challenges </li>
                                                <li className='checkmark-bullets'>Insufficient testing and inadequate training </li>
                                                <li className='checkmark-bullets'>Inadequate post-implementation support </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <BlueFluidBanner
                        title={<>Is your Dynamics 365 implementation failing or not delivering to its promises?</>}
                        button={<><span>Contact us now</span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.3172 10.442L11.6922 16.067C11.5749 16.1843 11.4159 16.2502 11.25 16.2502C11.0841 16.2502 10.9251 16.1843 10.8078 16.067C10.6905 15.9498 10.6247 15.7907 10.6247 15.6249C10.6247 15.459 10.6905 15.2999 10.8078 15.1827L15.3664 10.6249H3.125C2.95924 10.6249 2.80027 10.559 2.68306 10.4418C2.56585 10.3246 2.5 10.1656 2.5 9.99986C2.5 9.8341 2.56585 9.67513 2.68306 9.55792C2.80027 9.44071 2.95924 9.37486 3.125 9.37486H15.3664L10.8078 4.81705C10.6905 4.69977 10.6247 4.54071 10.6247 4.37486C10.6247 4.20901 10.6905 4.04995 10.8078 3.93267C10.9251 3.8154 11.0841 3.74951 11.25 3.74951C11.4159 3.74951 11.5749 3.8154 11.6922 3.93267L17.3172 9.55767C17.3753 9.61572 17.4214 9.68465 17.4529 9.76052C17.4843 9.8364 17.5005 9.91772 17.5005 9.99986C17.5005 10.082 17.4843 10.1633 17.4529 10.2392C17.4214 10.3151 17.3753 10.384 17.3172 10.442Z" fill="#1D68F0" />
                            </svg></>}
                    />
                </section>
                <section data-section id='section-5'>
                    <HowHelpSection
                        overline={'How can we help you'}
                        heading={<>Why should you choose <span style={{ color: '#1D68F0' }}>MIR Digital Solutions</span> for your project rescue operation?</>}
                        desc={<>MIR assists businesses with Microsoft Project issues. We guide you through challenges, mitigate damage, and ensure a successful outcome. Identifying common problems, we provide the essential aid to realign your project.<br /><br />If necessary, we assemble an expert team to optimize progress and minimize costs and delays. Count on us to tirelessly ensure your project aligns with your business goals. </>}
                        image={'url(/project-rescue/how-we-help.png) no-repeat'}
                    />
                    <div className="container">
                        <div className="advantage">
                            <div className="row">
                                <h2 className="mir-heading-l5-l2 text-secondary-800 col-lg-12 c-text-center">
                                    Advantages of working with us
                                </h2>
                            </div>
                            <div className="advatage-cards">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="advatage-card">
                                            <h5 className='text-secondary-700 mir-heading-l1-l2'>Customization & Flexibility</h5>
                                            <p className='advatage-card-desc'>
                                                Customization and Flexibility
                                            </p>
                                            <p className='advatage-card-desc'>
                                                Tailored solutions for specific needs
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="advatage-card">
                                            <h5 className='text-secondary-700 mir-heading-l1-l2'>Structured Delivery Process</h5>
                                            <p className='advatage-card-desc'>
                                                Transparent fixed-fund structure
                                            </p>
                                            <p className='advatage-card-desc'>
                                                Adaptive strategies for challenges
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="advatage-card">
                                            <h5 className='text-secondary-700 mir-heading-l1-l2'>Value-Centered Approach</h5>
                                            <p className='advatage-card-desc'>
                                                Prioritize your concerns and goals
                                            </p>
                                            <p className='advatage-card-desc'>
                                                Swiftly address issues with adaptability
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="advatage-card">
                                            <h5 className='text-secondary-700 mir-heading-l1-l2'>Optimized Licensing & Validation </h5>
                                            <p className='advatage-card-desc'>
                                                Assess and optimize licenses
                                            </p>
                                            <p className='advatage-card-desc'>
                                                Audit for effective license usage
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="advatage-card">
                                            <h5 className='text-secondary-700 mir-heading-l1-l2'>Agility & Efficiency</h5>
                                            <p className='advatage-card-desc'>
                                                Rapid solutions with agility
                                            </p>
                                            <p className='advatage-card-desc'>
                                                Cost-effective, global time zone coverage
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="advatage-card">
                                            <h5 className='text-secondary-700 mir-heading-l1-l2'>Scalability & Compliance</h5>
                                            <p className='advatage-card-desc'>
                                                Seasoned team to drive results
                                            </p>
                                            <p className='advatage-card-desc'>
                                                Leverage industry best practices
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="advatage-card">
                                            <h5 className='text-secondary-700 mir-heading-l1-l2'>Competitive Pricing</h5>
                                            <p className='advatage-card-desc'>
                                                Prioritize your concerns and goals
                                            </p>
                                            <p className='advatage-card-desc'>
                                                High quality at competitive rates
                                            </p>
                                            <p className='advatage-card-desc'>
                                                Swiftly address issues with adaptability
                                            </p>
                                            <p className='advatage-card-desc'>
                                                Extra value through enhanced services
                                            </p>
                                            <p className='advatage-card-desc'>
                                                Optimized licensing and validation
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="advatage-card">
                                            <h5 className='text-secondary-700 mir-heading-l1-l2'>Advanced Workloads</h5>
                                            <p className='advatage-card-desc'>
                                                Assess and optimize licenses
                                            </p>
                                            <p className='advatage-card-desc'>
                                                Single point of contact
                                            </p>
                                            <p className='advatage-card-desc'>
                                                Audit for effective license usage
                                            </p>
                                            <p className='advatage-card-desc'>
                                                Adapt to changes, streamline feedback
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container text-center">
                        <h3 className='mir-paragraph-sm-l2-l7 text-secondary-500 trusted-text-pr'>Trusted by businesses of all sizes, across North America, Middle East and Africa</h3>
                    </div>
                    <Banner images={images} speed={25000} />
                    <div className="container">
                        <div className="sec-5-contents">
                            <div className="row">
                                <h2 className='simplify-header col-lg-6 mir-heading-l5-l2 sec-5-1-heading'>
                                    Let us simplify the process and help you achieve a favorable outcome
                                </h2>
                            </div>

                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="sec-5-1-card">
                                        <div className="row">
                                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2">
                                                <img src={'../../../../business-intelligence/Check.png'} className='sec-5-1-card-img'></img>
                                            </div>
                                            <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 col-10">
                                                <p className='mir-paragraph-rg-l4-l7'>
                                                    An end-to-end digital transformation platform that drives project-based operations from prospect to payment to profit
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="sec-5-1-card">
                                        <div className="row">
                                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2">
                                                <img src={'../../../../business-intelligence/Check.png'} className='sec-5-1-card-img'></img>
                                            </div>
                                            <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 col-10">
                                                <p className='mir-paragraph-rg-l4-l7'>
                                                    An extensive partner ecosystem and go-to-market channels, including AppSource Marketplace, partner-to partner network, and Microsoft sellers
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="sec-5-1-card">
                                        <div className="row">
                                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2">
                                                <img src={'../../../../business-intelligence/Check.png'} className='sec-5-1-card-img'></img>
                                            </div>
                                            <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 col-10">
                                                <p className='mir-paragraph-rg-l4-l7'>
                                                    Dynamics 365 extensibility to unite Finance, Human Resources, Sales, Supply Chain Management, Customer Service, Field Service, and more
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="sec-5-1-card">
                                        <div className="row">
                                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2">
                                                <img src={'../../../../business-intelligence/Check.png'} className='sec-5-1-card-img'></img>
                                            </div>
                                            <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 col-10">
                                                <p className='mir-paragraph-rg-l4-l7'>
                                                    Deep expertise and a rich history with CRM, ERP, HCM, collaboration, productivity, and project management applications
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="sec-5-1-card">
                                        <div className="row">
                                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2">
                                                <img src={'../../../../business-intelligence/Check.png'} className='sec-5-1-card-img'></img>
                                            </div>
                                            <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 col-10">
                                                <p className='mir-paragraph-rg-l4-l7'>
                                                    Complete cloud vision that encompasses Azure, Office 365, and Dynamics 365
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="sec-5-1-card">
                                        <div className="row">
                                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2">
                                                <img src={'../../../../business-intelligence/Check.png'} className='sec-5-1-card-img'></img>
                                            </div>
                                            <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 col-10">
                                                <p className='mir-paragraph-rg-l4-l7'>
                                                    Accelerated application development and flexibility with the Power Platform
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='steps'>
                        <StepsSlider subtitle={<>Our specialist team offers custom-tailored implementation of Microsoft Power Apps for crafting personalized applications, bypassing intricate coding.</>} />
                    </div>
                    <div className="container sec-2-sub">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-11">
                                        <h2 className='mir-heading-l5-l2 text-secondary-800 sec-2-heading'>How we can help with your project implementation</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-8'>
                                <p className='sec-2-sub-desc mir-paragraph-md-l4-l7 text-secondary-700'>At MIR, we follow a successful Microsoft Dynamics 365 Project Rescue follows a comprehensive approach. This process guarantees an effective solution, aligning with your goals and delivering value to your organization. </p>
                                <button className="mir-btn-lg-primary" onClick={(e) => { setShowRegionPopup(true) }}>
                                    Contact us today
                                </button>
                            </div>
                            <div className="col-lg-12 sec-2-sub-breakers"></div>
                            <div className="col-lg-12">
                                <div className="sec-2-sub-cards">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <h5 className='mir-heading-l2-l2'>
                                                <div className="row">
                                                    <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1">
                                                        <sup className='sec-2-sub-numbers mir-paragraph-rg-l3-l7 text-primary-500'>01</sup>
                                                    </div>
                                                    <h5 className="col-lg-11 col-md-11 col-sm-11 col-xs-11 col-11 mir-heading-l2-l2">
                                                        Discovery
                                                    </h5>
                                                </div>
                                            </h5>
                                        </div>
                                        <div className="col-lg-7">
                                            <p className='mir-heading-l2-l3 text-secondary-400'>
                                                Assess challenges, gather information, and define scope.
                                            </p>
                                        </div>
                                        <div className="col-lg-12 sec-2-sub-breakers"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="sec-2-sub-cards">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <h5 className='mir-heading-l2-l2'>
                                                <div className="row">
                                                    <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1">
                                                        <sup className='sec-2-sub-numbers mir-paragraph-rg-l3-l7 text-primary-500'>02</sup>
                                                    </div>
                                                    <h5 className="col-lg-11 col-md-11 col-sm-11 col-xs-11 col-11 mir-heading-l2-l2">
                                                        Deep Dive
                                                    </h5>
                                                </div>
                                            </h5>
                                        </div>
                                        <div className="col-lg-7">
                                            <p className='mir-heading-l2-l3 text-secondary-400'>
                                                Analyze complex technical and functional aspects.
                                            </p>
                                        </div>
                                        <div className="col-lg-12 sec-2-sub-breakers"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="sec-2-sub-cards">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <h5 className='mir-heading-l2-l2'>
                                                <div className="row">
                                                    <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1">
                                                        <sup className='sec-2-sub-numbers mir-paragraph-rg-l3-l7 text-primary-500'>03</sup>
                                                    </div>
                                                    <h5 className="col-lg-11 col-md-11 col-sm-11 col-xs-11 col-11 mir-heading-l2-l2">
                                                        Project Plan
                                                    </h5>
                                                </div>
                                            </h5>
                                        </div>
                                        <div className="col-lg-7">
                                            <p className='mir-heading-l2-l3 text-secondary-400'>
                                                Create detailed phases and effort estimates.
                                            </p>
                                        </div>
                                        <div className="col-lg-12 sec-2-sub-breakers"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="sec-2-sub-cards">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <h5 className='mir-heading-l2-l2'>
                                                <div className="row">
                                                    <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1">
                                                        <sup className='sec-2-sub-numbers mir-paragraph-rg-l3-l7 text-primary-500'>04</sup>
                                                    </div>
                                                    <h5 className="col-lg-11 col-md-11 col-sm-11 col-xs-11 col-11 mir-heading-l2-l2">
                                                        Analysis & Design
                                                    </h5>
                                                </div>
                                            </h5>
                                        </div>
                                        <div className="col-lg-7">
                                            <p className='mir-heading-l2-l3 text-secondary-400'>
                                                Validate design, align with goals.
                                            </p>
                                        </div>
                                        <div className="col-lg-12 sec-2-sub-breakers"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="sec-2-sub-cards">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <h5 className='mir-heading-l2-l2'>
                                                <div className="row">
                                                    <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1">
                                                        <sup className='sec-2-sub-numbers mir-paragraph-rg-l3-l7 text-primary-500'>05</sup>
                                                    </div>
                                                    <h5 className="col-lg-11 col-md-11 col-sm-11 col-xs-11 col-11 mir-heading-l2-l2">
                                                        Development & Testing
                                                    </h5>
                                                </div>
                                            </h5>
                                        </div>
                                        <div className="col-lg-7">
                                            <p className='mir-heading-l2-l3 text-secondary-400'>
                                                Agile development, user testing.
                                            </p>
                                        </div>
                                        <div className="col-lg-12 sec-2-sub-breakers"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="sec-2-sub-cards">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <h5 className='mir-heading-l2-l2'>
                                                <div className="row">
                                                    <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1">
                                                        <sup className='sec-2-sub-numbers mir-paragraph-rg-l3-l7 text-primary-500'>06</sup>
                                                    </div>
                                                    <h5 className="col-lg-11 col-md-11 col-sm-11 col-xs-11 col-11 mir-heading-l2-l2">
                                                        Adoption
                                                    </h5>
                                                </div>
                                            </h5>
                                        </div>
                                        <div className="col-lg-7">
                                            <p className='mir-heading-l2-l3 text-secondary-400'>
                                                Roll out, train users, implement feedback.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* success story */}
                <div data-section id='section-6'>
                    <SuccessStoriesDynamic
                        successStoriesContent={successStoriesContent}
                    />
                </div>
                <div data-section id='section-7'>
                    <ContactSection />
                </div>
                <div data-section id='section-8'>
                    {/* <ContactSection /> */}
                </div>
                <Footer />
            </main>
        </>
    )
}

export const ChallengeIcon = () => (
    <svg width="35" height="35" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_5352_42016)">
            <path d="M22.75 28H5.25C2.35025 28 0 25.6497 0 22.75V5.25C0 2.35025 2.35025 0 5.25 0H22.75C25.6497 0 28 2.35025 28 5.25V22.75C28 25.6497 25.6497 28 22.75 28Z" fill="#FEEFEF" />
            <path d="M14 7C10.1386 7 7 10.1386 7 14C7 17.8614 10.1386 21 14 21C17.8614 21 21 17.8614 21 14C21 10.1386 17.8614 7 14 7ZM9.91638 12.2911C9.91638 11.6436 10.4414 11.1247 11.0828 11.1247C11.7241 11.1247 12.2491 11.6436 12.2491 12.2911C12.2491 12.9325 11.7241 13.4575 11.0828 13.4575C10.4414 13.4575 9.91638 12.9325 9.91638 12.2911ZM17.3294 17.9331C17.2156 18.0469 17.066 18.1038 16.9172 18.1038C16.7676 18.1038 16.6189 18.0469 16.5051 17.9331C15.8358 17.2638 14.9459 16.8962 14 16.8962C13.0541 16.8962 12.1642 17.2646 11.4957 17.934C11.2682 18.1615 10.899 18.1615 10.6706 17.934C10.4431 17.7065 10.4431 17.3372 10.6706 17.1089C11.5596 16.2199 12.7426 15.7299 14 15.7299C15.2574 15.7299 16.4395 16.2199 17.3294 17.1089C17.5569 17.3364 17.5569 17.7056 17.3294 17.9331ZM16.9164 13.4575C16.275 13.4575 15.75 12.9325 15.75 12.2911C15.75 11.6436 16.275 11.1247 16.9164 11.1247C17.5577 11.1247 18.0828 11.6436 18.0828 12.2911C18.0836 12.9325 17.5586 13.4575 16.9164 13.4575Z" fill="#E96767" />
        </g>
        <defs>
            <clipPath id="clip0_5352_42016">
                <rect width="28" height="28" fill="white" />
            </clipPath>
        </defs>
    </svg>
)