import React from 'react';

const BannerSection = () => (
    <section className="section-with-bg">
        <div className="banner-text row align-items-center justify-content-start text-left">
            <div className="col-12 col-md-8">
                <h4 className="mir-text-primary-500 mir-p-overline-lg text-uppercase">MIR Culture</h4>
                <h1 className="banner-subtitle mir-text-neutral-700 mir-display-semibold-lg">
                    A place where tradition <br/> meets <span className='mir-text-primary-500'>innovation</span>
                </h1>
            </div>
        </div>
        <div className="banner-img-container">
            <img src="/mir-culture/culture_banner_bg.png" alt="MIR Culture" className="section-image"/>
            <div className="bottom-rect mir-bg-primary-800 p-0"></div>
        </div>
    </section>);


export default BannerSection;
