import React, { useState } from 'react'
import StickyBanner from '../StickyBanner/StickyBanner'
import BookNowModal from '../CommonElements/BookNowModal'
import { Helmet } from 'react-helmet';
import SectionTabs from '../CloudSolutions/Elements/Sections/SectionTabs/SectionTabs';
import ContactSection from '../CloudSolutions/Elements/Sections/ContactSection';
import Footer from '../Footer/Footer';
import OverviewSection from '../CloudSolutions/Elements/Sections/OverviewSection';
import HowHelpSection from '../CloudSolutions/Elements/Sections/HowHelpSection';
import StepsSlider from '../CloudSolutions/Elements/StepProcess/StepProcess';
import SuccessStory from '../CloudSolutions/Elements/Sections/SuccessStory';
import TransformationSection from '../CloudSolutions/Elements/Sections/TransformationSection';
import CaseStudyUrls from '../../lib/singleCaseStudyUrls';
import MIRRoutes from '../../lib/routes';
import { Link } from 'react-router-dom';

export default function SharePoint() {

    const [tabs, setTabs] = useState([
        { name: 'Overview', id: 'section-1' },
        { name: 'Introduction', id: 'section-2' },
        { name: 'Benefits', id: 'section-3' },
        { name: 'How can we help you', id: 'section-4' },
        { name: 'Success Story', id: 'section-5' },
        { name: 'Contact Us', id: 'section-6' },
    ]);

    const [showRegionPopup, setShowRegionPopup] = useState(false);

    return (
        <main>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Microsoft SharePoint Collaboration Services | MIR Digital Solutions</title>
                <meta name="description" content="Empower your workforce with Microsoft SharePoint. The platform streamlines content management, facilities teamwork, and enhances information discovery." />
                <link rel="canonical" href={`${window.location.origin}/cloud-solutions/microsoft-sharepoint-solution`} />
            </Helmet>
            <StickyBanner />
            <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />

            <section className='tw-pt-[120px] tw-pb-[160px] tw-bg-cover tw-bg-center tw-bg-no-repeat' style={{ backgroundImage: 'url(/supply-chain/bg.svg)'  }}>
                <div className="container mx-auto text-center">
                    <img src="/sharepoint/hero-icon.png" className='tw-mb-[42px]'
                        alt="MIR Digital Solutions is a Microsoft Solutions Partner for digital, app innovation, and Azure services." 
                    />
                    <h4 className='tw-mb-[24px] mir-h6-medium mir-text-primary-500'>Streamline workflows, secure collaboration, and efficient document management</h4>
                    <h2 className='mir-display-semibold-sm mir-text-neutral-800 m-0'>Microsoft SharePoint</h2>
                </div>
            </section>

            {/* section tabs */}
            <SectionTabs tabs={tabs} />

            <section className='!tw-p-0 tw-relative'>
                <span data-section id="section-1" className='tw-absolute tw-top-[-100px]'></span>
                <OverviewSection
                    overline={'OVERVIEW'}
                    heading={<>Experience productivity at its best with Microsoft SharePoint</>}
                    desc={<>
                        <span className='tw-mb-[36px]'>Businesses worldwide faced critical moments where they stood at a crossroads, confronting looming challenges that posed a threat to their growth and progress. They struggled with managing vast amounts of information, navigating complex workflows, and addressing the persistent issues of security and compliance. These challenges included:</span> <br /><br />
                        <div className='tw-mt-[36px] tw-mb-[52px] tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[32px]'>
                            {
                                [
                                    { heading: 'Disconnected Systems', desc: 'Data scattered across servers, file shares, and databases' },
                                    { heading: "Collaboration Issues", desc: "Lack of centralized tools led to version control problems" },
                                    { heading: "Knowledge Challenges", desc: "Managing growing document repositories and knowledge was a challenge" },
                                    { heading: "Manual Workflows", desc: "Inefficient processes caused delays and errors" },
                                    { heading: "Security and Compliance", desc: "Increasing digital threats" },
                                    { heading: "Remote Work Needs", desc: "The rise in remote work demanded access from anywhere" },
                                    { heading: "Microsoft Integration", desc: "Desire for a unified experience with Office, Outlook, and Windows" },
                                    { heading: "Scalability", desc: "Businesses sought adaptable solutions, regardless of size" }
                                ].map((item, index) => {
                                    return <div className='tw-flex tw-items-start tw-justify-start'>
                                        <img src="/power-apps/sad.png" alt="sadness-icon" className='tw-mr-[24px]' />
                                        <div className='tw-max-w-full md:tw-max-w-[583px]'>
                                            <h5 className='mir-p-md-semibold'>{item.heading}</h5>
                                            <h3 className='mir-p-rg-regular'>{item.desc}</h3>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <h3 className='mir-p-md-regular mir-text-neutral-800'>
                            If your business is contemplating the transition of its technology services to the cloud, you may consider <span className='mir-p-md-semibold'>Microsoft SharePoint</span> to swiftly enhance efficiency and mitigate data security risks.
                        </h3>
                    </>}
                    bg={'url(/business/overview-bg.png)'}
                />
            </section>

            <section className='tw-relative mir-bg-neutral-100'>
                <span data-section id="section-2" className='tw-absolute tw-top-[-100px]'></span>
                <img className='tw-hidden md:tw-block tw-absolute tw-left-0 tw-bottom-0 tw-right-0' src="/sharepoint/intro-image.png" alt="Microsoft SharePoint is hosted in the cloud, allowing you and your teams to collaborate, discuss, and meet in real time from any device or location." />
                <div className="container mx-auto">
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-[110px]">
                        <div>
                            
                        </div>
                        <div className='tw-pt-[120px] tw-pb-[32px] md:tw-pb-[120px]'>
                            <img className='tw-mb-[52px]' src="/sharepoint/icon.svg" alt="sharepoint-icon" />
                            <h4 className='tw-uppercase mir-p-overline-lg mir-text-primary-500 tw-mb-[24px]'>Introduction</h4>
                            <h2 className='mir-text-neutral-800 tw-mb-[52px] mir-h1-semibold'>What is Microsoft SharePoint?</h2>
                            <p className='mir-p-md-regular mir-text-neutral-800'>Microsoft SharePoint is a web-based platform and content management system that enables organizations to create, share, and manage documents, information, and resources. It offers features such as document management, collaboration tools, workflow automation, intranet portals, and integration with Microsoft products like Office 365. SharePoint facilitates team collaboration, document organization, and secure information access across devices and locations. </p>
                        </div>
                    </div>
                </div>
                <img className='tw-block md:tw-hidden tw-w-[95%]' src="/sharepoint/intro-image.png" alt="Microsoft SharePoint is hosted in the cloud, allowing you and your teams to collaborate, discuss, and meet in real time from any device or location." />
            </section>

            <div className='mir-bg-neutral-100'>
                <div className={`tw-px-[36px] tw-py-[36px] md:tw-px-[64px] md:tw-py-[72px] tw-relative mir-bg-primary-500 tw-overflow-hidden`}>
                    <img src="/supply-chain/light-blue-banner-bg.png" alt="blue-banner-bg" className='tw-w-full tw-h-full tw-absolute tw-z-0 tw-inset-0' />
                    <div className='tw-relative tw-z-10 tw-flex tw-justify-between tw-items-center tw-flex-wrap'>
                        <h2 className='tw-max-w-full tw-mb-[36px] md:tw-mb-[0px] md:tw-max-w-[672px] mir-h5-semibold text-white'>Experience streamlined workflows, secure collaboration, and efficient document management with Microsoft SharePoint</h2>
                        <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-secondary mir-btn-md mir-text-primary-500 mir-bg-white'>
                            <span className='tw-mr-[12px] mir-p-rg-medium'>Unlock efficiency today</span>
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.3172 10.9423L11.6922 16.5673C11.5749 16.6846 11.4159 16.7505 11.25 16.7505C11.0841 16.7505 10.9251 16.6846 10.8078 16.5673C10.6905 16.45 10.6247 16.291 10.6247 16.1251C10.6247 15.9593 10.6905 15.8002 10.8078 15.6829L15.3664 11.1251H3.125C2.95924 11.1251 2.80027 11.0593 2.68306 10.942C2.56585 10.8248 2.5 10.6659 2.5 10.5001C2.5 10.3343 2.56585 10.1754 2.68306 10.0582C2.80027 9.94095 2.95924 9.8751 3.125 9.8751H15.3664L10.8078 5.31729C10.6905 5.20002 10.6247 5.04096 10.6247 4.8751C10.6247 4.70925 10.6905 4.55019 10.8078 4.43292C10.9251 4.31564 11.0841 4.24976 11.25 4.24976C11.4159 4.24976 11.5749 4.31564 11.6922 4.43292L17.3172 10.0579C17.3753 10.116 17.4214 10.1849 17.4529 10.2608C17.4843 10.3366 17.5005 10.418 17.5005 10.5001C17.5005 10.5822 17.4843 10.6636 17.4529 10.7394C17.4214 10.8153 17.3753 10.8842 17.3172 10.9423Z" fill="#1D68F0"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <section className='tw-relative mir-bg-neutral-800 tw-bg-bottom tw-bg-cover tw-bg-no-repeat' style={{ backgroundImage: 'url(/business/service-bundles-bg.png)' }}>
                <span data-section id="section-3" className='tw-absolute tw-top-[-100px]'></span>
                <div className='container tw-mx-auto tw-text-center tw-pt-[120px] tw-pb-[120px]'>
                    <h4 className='mir-p-overline-lg text-primary-500 tw-mb-[24px]'>BENEFITS</h4>
                    <h2 className='mir-h1-semibold mir-text-neutral-100 tw-mb-[36px] tw-max-w-[100%] md:tw-max-w-[734px] tw-mx-auto'>How can Microsoft SharePoint help in your business?</h2>
                    <h3 className='mir-text-neutral-100 mir-p-lg-regular tw-max-w-[100%] md:tw-max-w-[734px] tw-mx-auto'>Microsoft SharePoint is a versatile platform with a wide range of key features that cater to various business needs. It meets diverse business needs, making organizations more efficient and productive. </h3>
                </div>
            </section>
            <section className='tw-py-[96px] mir-bg-neutral-100'>
                <div className="container mx-auto">
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-[52px]">
                        {
                            [
                                {heading: 'Document Management', desc: 'Centralized document creation, storage, and management.'},
                                {heading: 'Collaboration', desc: 'Real-time editing, discussion boards, and calendars for teamwork. '},
                                {heading: 'Intranet and Extranet', desc: 'Hub for internal communication and resources. '},
                                {heading: 'Workflow Automation', desc: 'Streamlined processes through automation. '},
                                {heading: 'Data Integration', desc: 'Seamless integration with Microsoft and third-party tools.'},
                                {heading: 'Security and Compliance', desc: 'Robust access control and compliance tools. '},
                                {heading: 'Customization', desc: 'Tailored solutions through customization and extensibility.'},
                                {heading: 'Knowledge Management', desc: 'Quick information retrieval with metadata tagging.'},
                                {heading: 'Mobile Accessibility', desc: 'Collaboration and access on various devices. '},
                                {heading: 'Business Intelligence', desc: 'Data analysis and interactive dashboards.'},
                                {heading: 'Project Management', desc: 'Task lists, Gantt charts, and project workspaces.'},
                                {heading: 'Scalability', desc: 'Adaptable for businesses of all sizes.'},
                            ].map((item, index) => {
                                return <div key={`benefit-feature-index-${index}`}>
                                    <h4 className='mir-text-primary-500 mir-h6-semibold tw-mb-[12px]'>{item.heading}</h4>
                                    <h5 className='mir-text-neutral-600 mir-p-rg-regular'>{item.desc}</h5>
                                </div>
                            })
                        }
                    </div>
                </div>
            </section>

            <div className='mir-bg-neutral-100 tw-pb-[96px]'>
                <div className="container mx-auto">
                    <div className={`tw-rounded-[12px] tw-px-[36px] tw-py-[36px] md:tw-px-[64px] md:tw-py-[52px] tw-relative mir-bg-primary-500 tw-overflow-hidden`}>
                        <img src="/sales/blue-banner-bg.png" alt="blue-banner-bg" className='tw-absolute tw-z-0 tw-right-0 tw-bottom-0' />
                        <div className='tw-relative tw-z-10 tw-flex tw-justify-between tw-items-center tw-flex-wrap'>
                            <h3 className='tw-max-w-full tw-mb-[32px] md:tw-mb-0 md:tw-max-w-[650px] mir-h5-semibold text-white'>Whether you're a small business or a large enterprise, SharePoint scales with you. Discover scalability at its best</h3>
                            <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-secondary mir-btn-md mir-text-primary-500 mir-bg-white'>
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.3727 12.8795L13.6922 11.2303L13.682 11.2256C13.491 11.1439 13.2825 11.1111 13.0756 11.1302C12.8687 11.1493 12.6698 11.2197 12.4969 11.335C12.4765 11.3484 12.457 11.3631 12.4383 11.3787L10.5367 12.9998C9.33203 12.4147 8.08828 11.1803 7.50312 9.99125L9.12656 8.06078C9.14219 8.04125 9.15703 8.02172 9.17109 8.00062C9.28393 7.8282 9.35239 7.63056 9.37038 7.42529C9.38837 7.22002 9.35534 7.01348 9.27422 6.82406V6.81469L7.62031 3.12797C7.51308 2.88052 7.32869 2.67438 7.09468 2.54034C6.86066 2.4063 6.58957 2.35153 6.32187 2.38422C5.26326 2.52352 4.29155 3.04341 3.58823 3.84679C2.88491 4.65017 2.49809 5.6821 2.5 6.74984C2.5 12.953 7.54687 17.9998 13.75 17.9998C14.8177 18.0018 15.8497 17.6149 16.653 16.9116C17.4564 16.2083 17.9763 15.2366 18.1156 14.178C18.1484 13.9104 18.0937 13.6393 17.9598 13.4053C17.8259 13.1713 17.62 12.9869 17.3727 12.8795ZM13.75 16.7498C11.0987 16.7469 8.55687 15.6924 6.68213 13.8177C4.80739 11.943 3.75289 9.40112 3.75 6.74984C3.74706 5.98694 4.02191 5.24906 4.52323 4.67399C5.02455 4.09892 5.71805 3.72599 6.47422 3.62484C6.47391 3.62796 6.47391 3.6311 6.47422 3.63422L8.11484 7.30609L6.5 9.2389C6.48361 9.25776 6.46872 9.27788 6.45547 9.29906C6.3379 9.47947 6.26893 9.68718 6.25525 9.90208C6.24156 10.117 6.28362 10.3318 6.37734 10.5256C7.08516 11.9733 8.54375 13.4209 10.007 14.128C10.2023 14.2208 10.4183 14.2614 10.634 14.2458C10.8497 14.2302 11.0576 14.1589 11.2375 14.0389C11.2576 14.0254 11.2769 14.0108 11.2953 13.9952L13.1945 12.3748L16.8664 14.0194C16.8664 14.0194 16.8727 14.0194 16.875 14.0194C16.7751 14.7766 16.4027 15.4715 15.8275 15.9741C15.2524 16.4766 14.5138 16.7524 13.75 16.7498Z" fill="#1D68F0"/>
                                </svg>
                                <span className='tw-ml-[12px] mir-p-rg-regular'>Grow with confidence</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='tw-relative' style={{ marginTop: '-24px' }}>
                <span data-section id="section-4" className='tw-absolute tw-top-[-100px]'></span>
                <HowHelpSection 
                    overline={'HOW WE HELP YOU'}
                    heading={<>Why should you choose <span style={{ color: '#1D68F0' }}>MIR Digital Solutions</span> to excel in Microsoft SharePoint development? </>}
                    desc={<>MIR Digital Solutions has a proven track record of successful implementations and a strong commitment to Microsoft technologies, resulting in unrivaled product knowledge and process improvement expertise. Our experience has enabled us to develop efficient processes and approaches for cloud solutions, enabling innovation in a way other competitors simply cannot.</>}
                    image={'url(/sharepoint/help-bg.png) no-repeat'}
                />
            </div>

            <div className="delivering-wrapper py-5 mb-5">
                <div className="container">
                    <h3 className='mir-h5-semibold mb-4'>As a Microsoft Partner, we deliver</h3>
                    <div className="row">
                        {
                            [
                                'An end-to-end digital transformation platform that drives project-based operations from prospect to payment to profit',
                                'An extensive partner ecosystem and go-to-market channels, including AppSource Marketplace, partner-to partner network, and Microsoft sellers',
                                'Dynamics 365 extensibility to unite Finance, Human Resources, Sales, Supply Chain Management, Customer Service, Field Service, and more',
                                'Complete cloud vision that encompasses Azure, Office 365, and Dynamics 365',
                                'Deep expertise and a rich history with CRM, ERP, HCM, collaboration, productivity, and project management applications',
                                'Accelerated application development and flexibility with the Power Platform',
                            ].map((item) => {
                                return <div className="col-12 col-md-4">
                                    <div className="deliver-item d-flex align-items-start justify-content-start">
                                        <img src="/project-operations/blue-check.svg" className='mt-3 me-3' alt="delivering-icon" />
                                        <h3 className='mir-p-rg-regular mt-3' style={{ maxWidth: '450px' }}>{item}</h3>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>

            <StepsSlider  
                subtitle={'Our specialist team offers custom-tailored implementation of Microsoft SharePoint for crafting personalized applications, bypassing intricate coding.'}
            />

            <section className='tw-py-[96px] mir-bg-primary-800 tw-relative tw-overflow-hidden'>
                <img src="/sharepoint/blue-banner-bg.png" className='tw-absolute tw-inset-0 tw-w-full tw-h-full tw-bottom-0 tw-z-0' alt="blue-banner-bg" />
                <div className="container mx-auto tw-relative tw-z-10">
                    <div className='tw-flex tw-items-end tw-justify-between tw-flex-wrap'>
                        <div>
                            <h3 className='tw-max-w-full md:tw-max-w-[544px] mir-h3-semibold mir-text-neutral-100 tw-mb-[16px]'>Ready to unlock the full potential of Microsoft SharePoint?</h3>
                            <h4 className='tw-max-w-full md:tw-max-w-[448px] mir-p-md-regular mir-text-neutral-100'>Speak to our expert today and discover how this powerful platform can transform your business.</h4>
                        </div>
                        <button onClick={() => { setShowRegionPopup(true) }} className='tw-mt-[24px] md:tw-mt-[0px] mir-btn-lg mir-btn-lg-primary'>
                            <svg className='' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.25 2.5H14.375V1.875C14.375 1.70924 14.3092 1.55027 14.1919 1.43306C14.0747 1.31585 13.9158 1.25 13.75 1.25C13.5842 1.25 13.4253 1.31585 13.3081 1.43306C13.1908 1.55027 13.125 1.70924 13.125 1.875V2.5H6.875V1.875C6.875 1.70924 6.80915 1.55027 6.69194 1.43306C6.57473 1.31585 6.41576 1.25 6.25 1.25C6.08424 1.25 5.92527 1.31585 5.80806 1.43306C5.69085 1.55027 5.625 1.70924 5.625 1.875V2.5H3.75C3.41848 2.5 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41848 2.5 3.75V16.25C2.5 16.5815 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41848 17.5 3.75 17.5H16.25C16.5815 17.5 16.8995 17.3683 17.1339 17.1339C17.3683 16.8995 17.5 16.5815 17.5 16.25V3.75C17.5 3.41848 17.3683 3.10054 17.1339 2.86612C16.8995 2.6317 16.5815 2.5 16.25 2.5ZM5.625 3.75V4.375C5.625 4.54076 5.69085 4.69973 5.80806 4.81694C5.92527 4.93415 6.08424 5 6.25 5C6.41576 5 6.57473 4.93415 6.69194 4.81694C6.80915 4.69973 6.875 4.54076 6.875 4.375V3.75H13.125V4.375C13.125 4.54076 13.1908 4.69973 13.3081 4.81694C13.4253 4.93415 13.5842 5 13.75 5C13.9158 5 14.0747 4.93415 14.1919 4.81694C14.3092 4.69973 14.375 4.54076 14.375 4.375V3.75H16.25V6.25H3.75V3.75H5.625ZM16.25 16.25H3.75V7.5H16.25V16.25Z" fill="white"/>
                            </svg>
                            <span className='mir-p-rg-semibold'>Book a meeting</span>
                        </button>
                    </div>
                </div>
            </section>

            <div className='tw-relative'>
                <span data-section id="section-5" className='tw-absolute tw-top-[-100px]'></span>
                <SuccessStory 
                    image={'url(/sharepoint/success-bg.png)'}
                    overline={'SUCCESS STORY'}
                    url={CaseStudyUrls.SharePoint}
                    heading={'6Harmonics drives agility at workplace with Cloud and Office 365 Migration'}
                    // desc={<>Find out how we streamlined our partner’s operations with Microsoft Dynamics 365 Business Central</>}
                    button={<><span>Read Now</span>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.3172 10.4425L11.6922 16.0675C11.5749 16.1848 11.4159 16.2507 11.25 16.2507C11.0841 16.2507 10.9251 16.1848 10.8078 16.0675C10.6905 15.9503 10.6247 15.7912 10.6247 15.6253C10.6247 15.4595 10.6905 15.3004 10.8078 15.1832L15.3664 10.6253H3.125C2.95924 10.6253 2.80027 10.5595 2.68306 10.4423C2.56585 10.3251 2.5 10.1661 2.5 10.0003C2.5 9.83459 2.56585 9.67562 2.68306 9.55841C2.80027 9.4412 2.95924 9.37535 3.125 9.37535H15.3664L10.8078 4.81753C10.6905 4.70026 10.6247 4.5412 10.6247 4.37535C10.6247 4.2095 10.6905 4.05044 10.8078 3.93316C10.9251 3.81588 11.0841 3.75 11.25 3.75C11.4159 3.75 11.5749 3.81588 11.6922 3.93316L17.3172 9.55816C17.3753 9.61621 17.4214 9.68514 17.4529 9.76101C17.4843 9.83688 17.5005 9.91821 17.5005 10.0003C17.5005 10.0825 17.4843 10.1638 17.4529 10.2397C17.4214 10.3156 17.3753 10.3845 17.3172 10.4425Z" fill="white"/>
                    </svg></>}
                />
            </div>

            <TransformationSection
                heading={<>Transforming workflows and transforming business with SharePoint</>}
                desc={<>With our seasoned expert team, MIR can help you tailor and adapt any functionality to your business requirements.</>}
                items={[
                    {
                        icon: '/transformation-icons/power-apps.svg',
                        heading: <>Microsoft Power Apps</>,
                        link: <Link to={MIRRoutes.PowerApps}><span className='mir-p-sm-medium me-2'>Learn More</span>
                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0"/>
                        </svg></Link>
                    },
                    {
                        icon: '/transformation-icons/power-bi.png',
                        heading: <>Microsoft <br /> Power BI</>,
                        link: <Link to={MIRRoutes.PowerBI}><span className='mir-p-sm-medium me-2'>Learn More</span>
                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0"/>
                        </svg></Link>
                    },
                    {
                        icon: '/transformation-icons/365-business.png',
                        heading: <>Microsoft 365  for <br />Business</>,
                        link: <Link to={MIRRoutes.BusinessCentral}><span className='mir-p-sm-medium me-2'>Learn More</span>
                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0"/>
                        </svg></Link>
                    },
                ]}
            />

            <div className='tw-relative'>
                <span data-section id="section-6" className='tw-absolute tw-top-[-100px]'></span>
                <ContactSection />
            </div>

            <Footer />

        </main>
    )
}
